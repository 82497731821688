/*
 * Index is the entry point for the app.
 * Initial routes are here and secondary routes are in TwoPanelLayout
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'custom-event-polyfill';
import 'url-search-params-polyfill';
// import project css
import 'react-toggle/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-datetime/css/react-datetime.css';
import './index.css';
import 'moment/min/locales'; // can improve performance by importing the specific locales we want to support
import './i18n';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './registerServiceWorker';

import {
    faBars,
    faBuilding,
    faCalendarCheck,
    faCheck,
    faClipboardList,
    faCog,
    faCogs,
    faCommentLines,
    faEdit,
    faExchange,
    faHospital,
    faListAlt,
    faMapMarkedAlt,
    faMinus,
    faPlus,
    faAngleDoubleRight,
    faAngleDoubleDown,
    faSignOut,
    faTh,
    faTimes,
    faSignalAltSlash,
    faSignal,
    faShieldCheck
} from '@fortawesome/pro-regular-svg-icons';

import {
    faCaretDown,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faEnvelope,
    faFilter,
    faSearch,
    faShoppingCart,
    faUser,
    faUsers,
    faTools,
    faDownload
} from '@fortawesome/free-solid-svg-icons';

import App from './App';
import { Provider } from 'react-redux';
import { TrackJS } from 'trackjs';
import axios from 'axios';
import configureStore, { persistConfig } from './store/configureStore';
import { constants } from './constants/constants';
import { library } from '@fortawesome/fontawesome-svg-core';
import { purgeStoredState } from 'redux-persist';
import { MsalProvider } from '@azure/msal-react';
import msalInstance from './components/auth/MsalInstance';

library.add(
    faCaretDown,
    faFilter,
    faTools,
    faCog,
    faCogs,
    faUsers,
    faCalendarCheck,
    faTh,
    faCheck,
    faTimes,
    faSearch,
    faHospital,
    faUser,
    faSignOut,
    faShoppingCart,
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faEnvelope,
    faListAlt,
    faPlus,
    faMinus,
    faBuilding,
    faExchange,
    faMapMarkedAlt,
    faBars,
    faEdit,
    faClipboardList,
    faCommentLines,
    faAngleDoubleRight,
    faAngleDoubleDown,
    faSignalAltSlash,
    faSignal,
    faShieldCheck,
    faDownload
);

const promiseFinally = require('promise.prototype.finally');
promiseFinally.shim(); // fix finally for Edge browsers
export let store: any;

if (!(window !== window.parent && !window.opener)) {
    class IndexClass extends React.Component<{}, { isRehydrated: boolean }> {
        constructor(props: any) {
            super(props);
            this.state = { isRehydrated: false };
            store = configureStore(() => {
                this.setState({ isRehydrated: true });
            });
        }
        render() {
            if (!this.state.isRehydrated) {
                return null;
            }
            return (
                <React.Suspense fallback="Loading...">
                    <Provider store={store}>
                        <MsalProvider instance={msalInstance.msalApp}>
                            <App />
                        </MsalProvider>
                    </Provider>
                </React.Suspense>
            );
        }
    }
    /*
* flush writes the current state to storage - this will be right after USER_LOGOUT_SUCCESS is triggered
in userActions.  then we pause the persistor in order to prevent anything else from being persisted while we logout.
*/
    const handleLogout = () => {
        // persistor.flush().then(() => {
        //   persistor.pause();
        // });
        purgeStoredState(persistConfig);
    };
    document.addEventListener('userLogout', handleLogout, false);

    // set Axios default header for accepting JSON
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.timeout = constants.httpTimeout;

    // Trackjs

    TrackJS.install({
        token: '7acefdd92da44ad595db60cb7c09af8c',
        application: 'mymedgasmobile',
        version: process.env.REACT_APP_VERSION,
        enabled: !(window.location.host.indexOf('localhost') >= 0)
        // for more configuration options, see https://docs.trackjs.com
    });

    ReactDOM.render(<IndexClass />, document.getElementById('root') as HTMLElement);

    serviceWorker.register({
        onUpdate: registration => {
            document.dispatchEvent(new CustomEvent('newVersionAvailable'));
            if (registration && registration.waiting) {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
        }
    });
}
