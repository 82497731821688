import { FieldConfig } from 'react-reactive-form';
import {
    IWorkOrder,
    Iconfig,
    IdeficiencyCode,
    IinstallBase,
    IinstallBasePopulated,
    IjobPopulated,
    IjobWorkOrder,
    ImeasurementPointList,
    ImeasurementPointResult,
    Ipart,
    IpreventativeMaintenanceChecklist,
    Iproduct,
    Isubcategory,
    ItableFiltersReducer,
    IjobHour,
    ImeasurementPoint,
    Itile,
    IsimpleMeasurementPointResult,
    Iuser,
    IjobDefault,
    IinstallBasePart,
    IinstallBasePartPopulated,
    Iphoto,
    IpartStock,
    Ijob,
    IsuggestedPart,
    WorkOrderSource,
    IworkOrderPart,
    ICode
} from '../models';

import {
    workOrderVendorsEnum,
    workOrderTypesEnum,
    workOrderStatusEnum,
    workOrderPrioritiesEnum,
    productCommunicationMethodEnum,
    partTypeEnum
} from '../models-enums';

export const initialOption = { value: '', label: '' };
export const initialTableFilters: ItableFiltersReducer = {
    search: '',
    page: 0
};
export const initialTableFiltersFrontEndPaging = { search: '', page: 0 };
export const initialFacility = {
    id: '',
    name: '',
    name2: '',
    countryID: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    buildings: [],
    isDeleted: false,
    standardID: ''
};

const initialMainCategory = {
    id: '',
    name: '',
    createDate: '',
    updateDate: '',
    creatorID: '',
    updaterID: '',
    isDeleted: false
};

export const initialSubcategory: Isubcategory = {
    mainCategoryID: '',
    id: '',
    name: '',
    mainCategory: initialMainCategory,
    isDeleted: false
};

export const initialProduct: Iproduct = {
    id: '',
    name: '',
    sku: '',
    description: '',
    imagePath: '',
    subcategoryID: '',
    brandID: '',
    productTypeID: '',
    powerID: '',
    systemSizeID: '',
    isDeleted: false,
    isApproved: false,
    mergedProductID: '',
    isCompliant: true,
    hasSpares: true,
    isActive: true,
    communicationMethod: productCommunicationMethodEnum.none,
    links: [],
    origin: '',
    isFinalProduct: true,
    productStandards: [],
    createDate: undefined,
    updateDate: undefined
};
export const initialMeasurmentPointResult: ImeasurementPointResult = {
    id: '',
    status: 0,
    createDate: '',
    updateDate: '',
    jobID: '',
    notes: '',
    installBaseID: '',
    measurementPointListID: '',
    measurementPointAnswers: [],
    manualOverride: false,
    isDeleted: false,
    DamageCode: null
};
export const initialSimpleMeasurementPointResult: IsimpleMeasurementPointResult = {
    id: '',
    status: 0,
    createDate: '',
    updateDate: '',
    jobID: '',
    compiledNotes: undefined,
    measurementPointListID: '',
    measurementPointAnswers: [],
    isDeleted: false
};

export const initialInstallBase: IinstallBase = {
    id: '',
    productID: '',
    facilityID: '',
    nickname: '',
    measurementPointListResultStatus: 0,
    workOrderResultStatus: 1,
    isDeleted: false,
    latestMeasurementPointListResultID: '',
    latestAGSMeasurementPointListResultID: '',
    latestCommissioningMeasurementPointListResultID: '',
    latestAuditMeasurementPointListResultID: '',
    lastQuarterlyMaintenanceDate: '',
    lastSixMonthMaintenanceDate: '',
    latestVerificationMeasurementPointListResultID: '',
    lastYearlyMaintenanceDate: '',
    lastTwoYearMaintenanceDate: '',
    lastThreeYearMaintenanceDate: '',
    lastFiveYearMaintenanceDate: '',
    quantity: 1,
    objectPartGroup: ''
};
export const initialInstallBasePopulated: IinstallBasePopulated = {
    ...initialInstallBase,
    product: initialProduct,
    latestMeasurementPointListResult: initialMeasurmentPointResult,
    locationString: '',
    productNameString: '',
    woNumberString: '',
    workOrderID: '',
    activityDescriptionString: '',
    originalMeasurementPointListResultStatus: 0,
    isVirtual: false
};

export const initialLoc = {
    id: '',
    name: '',
    rooms: [],
    floorID: '',
    isDeleted: false
};
export const initialBuilding = {
    id: '',
    name: '',
    floors: [],
    facilityID: '',
    isDeleted: false
};
export const initialFloor = {
    id: '',
    name: '',
    locations: [],
    buildingID: '',
    isDeleted: false
};

export const initialRoom = {
    id: '',
    name: '',
    locationID: '',
    isDeleted: false
};
export const initialMeasurementPointResultAnswer = {
    measurementPointID: '',
    showInReport: true
};

export const initialInstallBasePart: IinstallBasePart = {
    id: '',
    jobID: '',
    isDeleted: false,
    installBaseID: ''
};

export const initialMeasurementPointList: ImeasurementPointList = {
    id: '',
    measurementPointTabs: [],
    mainCategoryID: '',
    standardID: '',
    type: 1,
    testProcedures: '',
    isDeleted: false
};
export const initialMeasurementPointTab = {
    id: '',
    name: 'Default Tab',
    measurementPoints: {},
    order: 0,
    isDeleted: false
};
export const initialMeasurementPoint: ImeasurementPoint = {
    id: '',
    type: 1,
    label: '',
    order: 0,
    isDeleted: false,
    isRequired: false,
    showInReport: false,
    measurementPointTabID: '',
    defaultText: ''
};

export const initialJobComment = {
    id: '',
    jobID: '',
    userID: '',
    isDeficiency: false,
    isInternal: false,
    isDeleted: false,
    text: '',
    createDate: '',
    codeID: '',
    isResolved: true
};

export const initialJob: Ijob = {
    id: '',
    facilityID: '',
    assignedUserID: '',
    jobTypeID: '80eedbac-ec22-45ef-9ac7-f2eb4be2db4c',
    startDate: '',
    endDate: '',
    status: 'New',
    isDeleted: false,
    jobNumber: '',
    jobHours: [],
    fseNotes: '',
    source: 0,
    isSAPClosed: false
};
export const initialJobPopulated: IjobPopulated = {
    ...initialJob,
    facility: initialFacility
};

export const initialJobDefaults: IjobDefault = {
    id: '',
    jobID: '',
    mpType: 3,
    mpDefaultType: 0,
    productTypeID: '',
    defaultMpAnswer: {
        showInReport: true
    }
};

export const initialJobWorkOrder: IjobWorkOrder = {
    id: '',
    jobID: '',
    workOrderID: '',
    isDeleted: false
};

export const initialDeficiencyCode: IdeficiencyCode = {
    description: '',
    name: '',
    id: '',
    isDeleted: false,
    createDate: '',
    updateDate: '',
    standardID: ''
};

export const initialCode: ICode = {
    description: '',
    name: '',
    id: '',
    isDeleted: false,
    createDate: '',
    updateDate: ''
};

export const initialPart: Ipart = {
    id: '',
    isDeleted: false,
    description: '',
    number: '',
    installationTime: 0,
    isActive: false,
    measurement: '',
    origin: '',
    type: partTypeEnum.default
};

export const initialJobPart = {
    jobID: '',
    isDeleted: false,
    estimated: 0,
    used: 0,
    lot: '',
    id: '',
    partID: ''
};

export const initialWorkOrderPart: IworkOrderPart = {
    partID: '',
    workOrderID: '',
    id: '',
    estimated: 0,
    used: 0,
    isDeleted: false
};

export const initialPartStock: IpartStock = {
    partID: '',
    userID: '',
    id: '',
    quantity: 0,
    tLot: '',
    isDeleted: false
};

export const initialSuggestedPart: IsuggestedPart = {
    id: '',
    isDeleted: false,
    partID: '',
    productID: '',
    productQuantity: 0,
    installBaseID: '',
    InstallBaseQuantity: 0,
    adminComments: ''
};

export const initialInstallBasePartPopulated: IinstallBasePartPopulated = {
    ...initialInstallBasePart,
    part: initialPart
};

export const initialJobHour: IjobHour = {
    id: '',
    description: '',
    number: '',
    quantity: 1,
    jobID: '',
    isDeleted: false,
    date: '',
    engineerVanID: '',
    SAPEngineerID: ''
};

export const initialWorkOrder: IWorkOrder = {
    id: '',
    activityDescription: '',
    closingNotes: '',
    closingNotesList: null,
    activeClosingNotes: null,
    installBaseID: '',
    notes: '',
    priority: workOrderPrioritiesEnum.lowRisk,
    status: workOrderStatusEnum.new,
    type: workOrderTypesEnum.repair,
    vendor: workOrderVendorsEnum.beacon,
    jobTypeID: '',
    number: '',
    isDeleted: false,
    productName: '',
    source: WorkOrderSource.MyMedGas,
    facility: initialFacility,
    sapBusinessIndicator: ''
};

export const initialPreventativeMaintenanceChecklist: IpreventativeMaintenanceChecklist = {
    id: '',
    name: '',
    isDeleted: false,
    preventativeMaintenancePlanID: '',
    scheduleInterval: 0,
    preventativeMaintenancePoints: []
};
export const initialToastrState = {
    toastrs: []
};

export const initialOfflineFacilityStatus = {
    locations: false,
    installBases: false,
    workOrders: false,
    photos: false,
    measurementPointResults: false
};
export const initialOfflineJobStatus = {
    simpleMeasurementPointResults: false
};

export const initialCartReducer = {
    addedIDs: [],
    itemsByID: {},
    message: ''
};

export const initialQuotePart = {
    id: '',
    quantity: 1,
    partID: '',
    isDeleted: false
};

export const initialQuotePartCart = {
    id: '',
    isDeleted: false,
    message: '',
    quoteItems: [],
    jobID: ''
};

export const initialUser: Iuser = {
    email: '',
    securityFunctions: [],
    first: '',
    last: '',
    position: '',
    phone: '',
    facilities: [],
    hasTeamMembers: false,
    isActive: true,
    countryID: '',
    userCountries: [],
    password: '',
    username: 'robert',
    isAuthenticated: false,
    isLoggingOut: false,
    token: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    id: '',
    nonProductiveHours: [],
    userStandards: []
};

export const initialConfig: Iconfig = {
    isMobile: false,
    workOrderLoadedKey: ''
};

/* initialState */
const initialState = {
    user: initialUser,
    contactsByFacility: {},
    ajaxCallsInProgress: 0,
    syncManager: {
        isSyncRunning: false,
        downloadingJob: false,
        downloadingJobFailed: false
    },
    location: {
        pathname: ''
    },
    countries: [],
    offlineStatusOverride: false,
    cart: initialCartReducer,
    config: initialConfig,
    manageInventory: {
        showEditProductModal: false,
        showEditInstallModal: false,
        showSearchNewProductsModal: false,
        showAddHoursModal: false,
        showSignatureModal: false,
        showNoteModal: false,
        showModalAddFSE: false,
        showModalEditFacilityContacts: false,
        installBasesByID: {},
        filterConfig: {} as FieldConfig,
        commissioningDataByID: {},
        productsByID: {},
        tableFilters: initialTableFilters,
        selectedProduct: initialProduct,
        newProducts: {},
        recentProducts: [],
        productSearchFormValues: {},
        selection: [],
        enableBulkInstallBaseMode: false,
        selectedProductID: '',
        bulkInstallBaseCategory: '',
        installFormValues: {},
        showEditJobDefaultsModal: false,
        showCommissioningDataFormModal: false,
        editJobDefaultsFormValues: {},
        damageCodes: {},
        refreshInventory: '', // if this value changes, the inventory will be refreshed, part of bulk actions on manageInventory flow
        // _persist: {version: 0, rehydrated: false},
        showManageInventoryLoading: false
    },
    manageCode: {
        totalPages: 1,
        codesByID: {},
        showEditCodeModal: false,
        tableFilters: initialTableFiltersFrontEndPaging,
        selectedCodeID: '',
        codeFormValues: {}
    },
    manageLocation: {
        visibleLocations: [],
        showEditLocationModal: false,
        tableFilters: initialTableFilters
    },
    manageJob: {
        jobsByID: {},
        jobSignaturesByID: {},
        defaultReportsByID: {},
        jobTypes: [],
        fseUsers: [],
        openedInspectionJobIDs: [],
        openedMaintenanceJobIDs: [],
        showEditJobModal: false,
        showViewJobModal: false,
        showRiskAssessmentModal: false,
        tableFilters: initialTableFilters,
        selectedJob: initialJob,
        showAddFacilityModal: false,
        showSearchFacilityModal: false,
        searchedFacilities: {},
        defaultFacilityID: '',
        jobFormValues: {},
        hourFormValues: {},
        selectedJobIDForWorkOrder: '',
        jobWorkOrdersByID: {},
        tempJobHours: {},
        selectedJobHourID: '',
        offlineStatusByID: {},
        jobFinalCheckModal: false,
        nextJobNumber: ''
    },
    manageJobComment: {
        jobCommentsByID: {},
        selectedJobComment: initialJobComment,
        showEditJobCommentModal: false,
        tableFilters: initialTableFilters,
        deficiencyCodesByID: {}
    },
    measurementPointLists: {
        measurementPointListsByID: {},
        measurementPointsByID: {},
        jobDefaultsByID: {}
    },
    measurementPointResults: {
        measurementPointResultsByID: {},
        selectedResult: initialMeasurmentPointResult,
        historicalResultID: '',
        historicalMPToCopy: initialMeasurmentPointResult as any
    },
    sapJobMappings: {
        sapJobMappingsByID: {}
    },
    simpleMeasurementPointResults: {
        simpleMeasurementPointResultsByID: {},
        selectedSimpleResult: initialSimpleMeasurementPointResult
    },
    showEditProfileModal: false,
    offline: {
        busy: false,
        online: true,
        retryCount: 0,
        retryScheduled: false,
        outbox: [],
        lastTransaction: 0
    },
    facilities: {},
    parts: {
        selectedInstallBasePartID: '',
        installBasePartsByID: {},
        jobPartsByID: {},
        partsByID: {},
        partStockByID: {},
        partFormValues: {},
        suggestedPartsByID: {},
        quoteCartsByID: {},
        workOrderPartsByID: {}
    },
    syncStatus: {
        allProductsUpdated: 0,
        productInfoUpdated: 0,
        measurementPointsUpdated: 0,
        lastSync: 0,
        FSEUsersUpdated: 0,
        measurementPointResultsUpdated: 0,
        simpleMeasurementPointResultsUpdated: 0,
        isInitialSyncComplete: false,
        allPartsUpdated: 0,
        initialAppSyncList: [],
        downloadSpeed: undefined
    },
    productInfo: {
        brands: {},
        productTypes: {},
        mainCategories: {},
        powers: {},
        standards: {},
        subcategories: {},
        systemSizes: {},
        brandOptions: [],
        productTypeOptions: [],
        mainCategoryOptions: [],
        powerOptions: [],
        standardOptions: [],
        subcategoryOptions: [],
        systemSizeOptions: [],
        originOptions: []
    },
    workOrder: {
        workOrdersByID: {},
        unlinkedSapWorkOrdersByID: {},
        preventativeMaintenanceChecklistsByID: {},
        showWorkOrderCloseModal: false,
        selectedWorkOrderID: '',
        historicalWorkOrderID: '',
        workOrderFormValues: {},
        showAddRepairWorkOrderModal: false,
        showConfirmSelectJobModal: false,
        isJobClosingWithSignature: false,
        isCollectingSignatures: false,
        showJobSignatureModal: false,
        partFormValues: {},
        selection: [],
        tableFilters: initialTableFilters
    },
    photos: {
        photos: {}
    },
    initialSyncActive: false,
    offlineStatusByFacilityID: {},
    offlineStatusByJobID: {},
    _persist: {}
};

export const emptyTile: Itile = {
    icon: '',
    title: '',
    src: '',
    srcBanner: '',
    color: 'blue',
    width: 359,
    height: 136,
    url: '',
    securityFunction: '',
    description: ''
};

export const initialPhoto: Iphoto = {
    name: '',
    comments: '',
    file: '',
    fileType: 0,
    id: '',
    installBaseID: '',
    isDeleted: false,
    isInReport: false,
    jobID: '',
    lightboxUrl: '',
    thumbnailUrl: '',
    createDate: '',
    updateDate: '',
    installBase: initialInstallBase,
    product: initialProduct,
    productID: ''
};

export default initialState;
