export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const END_AJAX_CALL = 'END_AJAX_CALL';

export const APP_VERSION_UPDATE = 'APP_VERSION_UPDATE';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';
export const USER_LOGOUT_SESSION_ONLY = 'USER_LOGOUT_SESSION_ONLY';

export const AAD_LOGIN_ = 'AAD_LOGIN';
export const AAD_LOGOUT_SUCCESS = 'AAD_LOGOUT_SUCCESS';
export const AAD_LOGIN = 'AAD_LOGIN';
export const AAD_LOGOUT_FAILED = 'AAD_LOGOUT_FAILED';

export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAILED = 'USER_UPDATE_PROFILE_FAILED';

export const GET_FACILITIES_SUCCESS = 'GET_FACILITIES_SUCCESS';
export const GET_FACILITIES_FAILED = 'GET_FACILITIES_FAILED';

export const GET_FACILIY_LOCATIONS = 'GET_FACILIY_LOCATIONS';
export const GET_FACILITY_SUCCESS = 'GET_FACILITY_SUCCESS';
export const GET_FACILITY_FAILED = 'GET_FACILITY_FAILED';

export const FACILITY_UPDATE_SUCCESS = 'FACILITY_UPDATE_SUCCESS';
export const FACILITY_UPDATE_FAILED = 'FACILITY_UPDATE_FAILED';
export const SET_CONTACT_FOR_FACILITY = 'SET_CONTACT_FOR_FACILITY';

export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';
export const TOGGLE_MODAL_EDIT_QUEUE_USER = 'TOGGLE_MODAL_EDIT_QUEUE_USER';
export const TOGGLE_MODAL_EDIT_USER = 'TOGGLE_MODAL_EDIT_USER';
export const TOGGLE_MODAL_EDIT_FACILITY = 'TOGGLE_MODAL_EDIT_FACILITY';
export const TOGGLE_MODAL_EDIT_PROFILE = 'TOGGLE_MODAL_EDIT_PROFILE';
export const TOGGLE_MODAL_SECURITY_FUNCTIONS = 'TOGGLE_MODAL_SECURITY_FUNCTIONS';

// shopping cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const DECREASE_FROM_CART = 'DECREASE_FROM_CART';
export const UPDATE_QUANTITY_CART = 'UPDATE_QUANTITY_CART';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const TOGGLE_MODAL_EDIT_QUOTE = 'TOGGLE_MODAL_EDIT_QUOTE';
export const ADD_TO_CART_QUOTE_PARTS = 'ADD_TO_CART_QUOTE_PARTS';
export const DELETE_FROM_CART_QUOTE_PARTS = 'DELETE_FROM_CART_QUOTE_PARTS';
export const DELETE_QUOTE_ITEM_FROM_QUOTE_CART = 'DELETE_QUOTE_ITEM_FROM_QUOTE_CART';
export const CLEAR_CART = 'CLEAR_CART';

export const SET_CART_QUOTE_PARTS = 'SET_CART_QUOTE_PARTS';
export const CHECKOUT_QUOTE_PARTS_SUCCESS = 'CHECKOUT_QUOTE_PARTS_SUCCESS';
export const CHECKOUT_QUOTE_PARTS_FAILED = 'CHECKOUT_QUOTE_PARTS_FAILED';

// inventory
export const ADD_FACILITY_CONTACT = 'ADD_FACILITY_CONTACT';
export const UPDATE_FACILITY_CONTACT = 'UPDATE_FACILITY_CONTACT';
export const DELETE_FACILITY_CONTACT = 'DELETE_FACILITY_CONTACT';
export const GET_INVENTORY = 'GET_INVENTORY';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';
export const GET_INVENTORY_FAILED = 'GET_INVENTORY_FAILED';
export const INVENTORY_INCREMENT = 'INVENTORY_INCREMENT';
export const INVENTORY_DECREMENT = 'INVENTORY_DECREMENT';
export const INVENTORY_TOTAL_PAGES = 'INVENTORY_TOTAL_PAGES';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATES = 'PRODUCT_UPDATES';
export const PRODUCT_UPDATE_FAILED = 'PRODUCT_UPDATE_FAILED';
export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS';
export const PRODUCT_ADD_FAILED = 'PRODUCT_ADD_FAILED';
export const TOGGLE_MODAL_EDIT_PRODUCT = 'TOGGLE_MODAL_EDIT_PRODUCT';
export const TOGGLE_MODAL_EDIT_JOB_DEFAULTS = 'TOGGLE_MODAL_EDIT_JOB_DEFAULTS';
export const SHOW_MODAL_EDIT_JOB_DEFAULTS = 'SHOW_MODAL_EDIT_JOB_DEFAULTS';
export const TOGGLE_MODAL_COMMISSIONING_DATA_FORM = 'TOGGLE_MODAL_COMMISSIONING_DATA_FORM';
export const GET_PRODUCT_INFO_SUCCESS = 'GET_PRODUCT_INFO_SUCCESS';
export const GET_PRODUCT_INFO_FAILED = 'GET_PRODUCT_INFO_FAILED';
export const GET_COMMISSIONING_INSTALL_BASES = 'GET_COMMISSIONING_INSTALL_BASES';
export const GET_COMMISSIONING_INSTALL_BASES_SUCCESS = 'GET_COMMISSIONING_INSTALL_BASES_SUCCESS';
export const GET_COMMISSIONING_INSTALL_BASES_FAILED = 'GET_COMMISSIONING_INSTALL_BASES_FAILED';
export const SAVE_COMMISSIONING_DATA = 'SAVE_COMMISSIONING_DATA';
export const ADD_COMMISSIONING_INSTALL_BASES = 'ADD_COMMISSIONING_INSTALL_BASES';
export const REMOVE_COMMISSIONING_INSTALL_BASES = 'REMOVE_COMMISSIONING_INSTALL_BASES';
export const ADD_COMMISSIONING_INSTALL_BASES_FAILED = 'ADD_COMMISSIONING_INSTALL_BASES_FAILED';
export const UPDATE_COMMISSIONING_INSTALL_BASES = 'UPDATE_COMMISSIONING_INSTALL_BASES';
export const INSTALL_UPDATE_SUCCESS = 'INSTALL_UPDATE_SUCCESS';
export const INSTALL_UPDATE_FAILED = 'INSTALL_UPDATE_FAILED';
export const INSTALL_ADD_SUCCESS = 'INSTALL_ADD_SUCCESS';
export const INSTALL_ADD_FAILED = 'INSTALL_ADD_FAILED';
export const INSTALL_DELETE = 'INSTALL_DELETE';
export const TOGGLE_MODAL_EDIT_INSTALL = 'TOGGLE_MODAL_EDIT_INSTALL';
export const SET_TABLE_FILTER_MANAGE_INVENTORY = 'SET_TABLE_FILTER_MANAGE_INVENTORY';
export const SET_FILTER_CONFIG = 'SET_FILTER_CONFIG';
export const CLEAR_TABLE_FILTER_MANAGE_INVENTORY = 'CLEAR_TABLE_FILTER_MANAGE_INVENTORY';
export const INSTALL_CONTACT = 'INSTALL_CONTACT';
export const INSTALL_CONTACT_FAILED = 'INSTALL_CONTACT_FAILED';
export const TOGGLE_MODAL_SEARCH_NEW_PRODUCTS = 'TOGGLE_MODAL_SEARCH_NEW_PRODUCTS';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';
export const NEW_PRODUCTS_RESET = 'NEW_PRODUCTS_RESET';
export const SET_FORM_VALUES_MANAGE_INVENTORY_PRODUCT_SEARCH = 'SET_FORM_VALUES_MANAGE_INVENTORY_PRODUCT_SEARCH';
export const UPDATE_FORM_VALUES_MANAGE_INVENTORY_PRODUCT_SEARCH = 'UPDATE_FORM_VALUES_MANAGE_INVENTORY_PRODUCT_SEARCH';
export const UPDATE_FORM_VALUES_EDIT_JOB_DEFAULTS = 'UPDATE_FORM_VALUES_EDIT_JOB_DEFAULTS';
export const SET_FORM_VALUES_EDIT_JOB_DEFAULTS = 'SET_FORM_VALUES_EDIT_JOB_DEFAULTS';
export const CLEAR_SELECTED_MANAGE_INVENTORY_PRODUCT_ID = 'CLEAR_SELECTED_MANAGE_INVENTORY_PRODUCT_ID';
export const INSTALL_UPDATE_SELECTION = 'INSTALL_UPDATE_SELECTION';
export const SET_INSTALL_BATCH_MODE = 'SET_INSTALL_BATCH_MODE';
export const SET_ADD_WORKORDER_MODE = 'SET_ADD_WORKORDER_MODE';
export const INSTALL_UPDATE_BULK = 'INSTALL_UPDATE_BULK';
export const SET_INSTALL_BATCH_CATEGORY = 'SET_INSTALL_BATCH_CATEGORY';
export const TOGGLE_MODAL_NOTE_PAD = 'TOGGLE_MODAL_NOTE_PAD';
export const UPDATE_FORM_VALUES_MANAGE_INVENTORY_INSTALL = 'UPDATE_FORM_VALUES_MANAGE_INVENTORY_INSTALL';
export const SET_FORM_VALUES_MANAGE_INVENTORY_INSTALL = 'SET_FORM_VALUES_MANAGE_INVENTORY_INSTALL';
export const TOGGLE_MODAL_ADD_HOURS = 'TOGGLE_MODAL_ADD_HOURS';
export const TOGGLE_MODAL_ADD_COMMISSIONING_DATA = 'TOGGLE_MODAL_ADD_COMMISSIONING_DATA';
export const SAVE_JOB_DEFAULTS = 'SAVE_JOB_DEFAULTS';
export const ADD_FILTER_CONFIG = 'ADD_FILTER_CONFIG';
export const TOGGLE_MODAL_ADD_FSE = 'TOGGLE_MODAL_ADD_FSE';
export const TOGGLE_MODAL_EDIT_FACILITY_CONTACTS = 'TOGGLE_MODAL_EDIT_FACILITY_CONTACTS';
export const UPDATE_MANAGE_INVENTORY_LOADING = 'UPDATE_MANAGE_INVENTORY_LOADING';

// manage jobs
export const DOWNLOAD_JOB = 'DOWNLOAD_JOB';
export const JOB_GET_ASSIGNED_SUCCESS = 'JOB_GET_ASSIGNED_SUCCESS';
export const JOB_GET_ASSIGNED_FAILED = 'JOB_GET_ASSIGNED_FAILED';
export const OPEN_JOB_SUCCESS = 'OPEN_JOB_SUCCESS';
export const OPEN_JOB_FAILED = 'OPEN_JOB_FAILED';
export const JOB_UPDATE = 'JOB_UPDATE';
export const JOB_UPDATE_FAILED = 'JOB_UPDATE_FAILED';
export const JOB_ADD_SUCCESS = 'JOB_ADD_SUCCESS';
export const JOB_ADD_FAILED = 'JOB_ADD_FAILED';
export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS';
export const JOB_DELETE_FAILED = 'JOB_DELETE_FAILED';
export const GET_JOBTYPES_SUCCESS = 'GET_JOBTYPES_SUCCESS';
export const GET_JOBTYPES_FAILED = 'GET_JOBTYPES_FAILED';
export const GET_FSE_USERS = 'GET_FSE_USERS';
export const GET_FSE_SUCCESS = 'GET_FSE_SUCCESS';
export const GET_FSE_FAILED = 'GET_FSE_FAILED';
export const JOB_MANAGE_TOTAL_PAGES = 'JOB_MANAGE_TOTAL_PAGES';
export const SET_TABLE_FILTER_MANAGE_JOB = 'SET_TABLE_FILTER_MANAGE_JOB';
export const TOGGLE_MODAL_EDIT_JOB = 'TOGGLE_MODAL_EDIT_JOB';
export const TOGGLE_MODAL_VIEW_JOB = 'TOGGLE_MODAL_VIEW_JOB';
export const TOGGLE_MODAL_RISK_ASSESSMENT = 'TOGGLE_MODAL_RISK_ASSESSMENT';
export const UPDATE_FORM_VALUES_MANAGE_JOB = 'UPDATE_FORM_VALUES_MANAGE_JOB';
export const SET_FORM_VALUES_MANAGE_JOB = 'SET_FORM_VALUES_MANAGE_JOB';
export const SET_FORM_VALUES_HOURS = 'SET_FORM_VALUES_HOURS';
export const UPDATE_FORM_VALUES_HOURS = 'UPDATE_FORM_VALUES_HOURS';
export const CLEAR_SELECTED_JOB_MANAGE_JOB = 'CLEAR_SELECTED_JOB_MANAGE_JOB';
export const SET_SELECTED_JOB_ID = 'SET_SELECTED_JOB_ID';
export const SET_TEMP_JOB_HOURS = 'SET_TEMP_JOB_HOURS';
export const SET_SELECTED_JOB_HOUR_ID = 'SET_SELECTED_JOB_HOUR_ID';
export const JOB_SAVE_HOUR = 'JOB_SAVE_HOUR';
export const JOB_VALIDATE_PARTS = 'JOB_VALIDATE_PARTS';
export const TOGGLE_MODAL_SIGNATURE_PAD = 'TOGGLE_MODAL_SIGNATURE_PAD';
export const TOGGLE_MODAL_VERIFICATION_SIGNATURE_PAD = 'TOGGLE_MODAL_VERIFICATION_SIGNATURE_PAD';
export const TOGGLE_MODAL_ADD_FACILITY = 'TOGGLE_MODAL_ADD_FACILITY';
export const TOGGLE_MODAL_SEARCH_FACILITY = 'TOGGLE_MODAL_SEARCH_FACILITY';
export const EMPTY_SEARCHED_FACILITIES = 'EMPTY_SEARCHED_FACILITIES';
export const SET_JOB_COVER_LETTER = 'SET_JOB_COVER_LETTER';
export const SET_JOB_FINAL_CHECK_MODAL = 'SET_JOB_FINAL_CHECK_MODAL';
export const CLEAR_SELECTED_JOB = 'CLEAR_SELECTED_JOB';
export const UPDATE_JOB = 'UPDATE_JOB';
export const JOB_UPDATE_FSE_SUCCESS = 'JOB_UPDATE_FSE_SUCCESS';
export const JOB_UPDATE_FSE_FAILED = 'JOB_UPDATE_FSE_FAILED';

export const SAVE_NON_PRODUCTIVE_HOUR = 'SAVE_NON_PRODUCTIVE_HOUR';
export const UPDATE_NON_PRODUCTIVE_HOURS = 'UPDATE_NON_PRODUCTIVE_HOURS';
export const UPDATE_NON_PRODUCTIVE_HOURS_FAILED = 'UPDATE_NON_PRODUCTIVE_HOURS_FAILED';

export const SAVE_HOURS_CHECKED = 'SAVE_HOURS_CHECKED';
export const SAVE_HOURS_CHECKED_FAILED = 'SAVE_HOURS_CHECKED_FAILED';

export const UPDATE_FORM_VALUES_FACILITIES_ADD_FACILITY = 'UPDATE_FORM_VALUES_FACILITIES_ADD_FACILITY';
export const SET_FORM_VALUES_FACILITIES_ADD_FACILITY = 'SET_FORM_VALUES_FACILITIES_ADD_FACILITY';
export const FACILITY_ADD_SUCCESS = 'FACILITY_ADD_SUCCESS';
export const FACILITY_ADD_FAILED = 'FACILITY_ADD_FAILED';

export const SET_DEFAULT_FACILITY_ID = 'SET_DEFAULT_FACILITY_ID';
export const ADD_OPENED_INSPECTION_JOB = 'ADD_OPENED_INSPECTION_JOB';
export const ADD_OPENED_MAINTENANCE_JOB = 'ADD_OPENED_MAINTENANCE_JOB';

export const GET_NEXT_JOB_NUMBER_SUCCESS = 'GET_NEXT_JOB_NUMBER_SUCCESS';
export const GET_NEXT_JOB_NUMBER_FAILED = 'GET_NEXT_JOB_NUMBER_FAILED';

// parts partStock
export const GET_ALL_PARTS = 'GET_ALL_PARTS';
export const GET_ALL_PARTS_SUCCESS = 'GET_ALL_PARTS_SUCCESS';
export const GET_ALL_PARTS_FAILED = 'GET_ALL_PARTS_FAILED';

// Install base parts for facility
export const GET_ALL_INSTALL_BASE_PARTS_SUCCESS = 'GET_ALL_INSTALL_BASE_PARTS_SUCCESS';
export const GET_ALL_INSTALL_BASE_PARTS_FAILED = 'GET_ALL_INSTALL_BASE_PARTS_FAILED';

export const GET_JOB_PARTS_SUCCESS = 'GET_JOB_PARTS_SUCCESS';
export const GET_JOB_PARTS_FAILED = 'GET_JOB_PARTS_FAILED';
export const GET_PART_STOCK_SUCCESS = 'GET_PART_STOCK_SUCCESS';
export const GET_PART_STOCK_FAILED = 'GET_PART_STOCK_FAILED';
export const GET_WORK_ORDER_PART_SUCCESS = 'GET_WORK_ORDER_PART_SUCCESS';
export const GET_WORK_ORDER_PART_FAILED = 'GET_WORK_ORDER_PART_FAILED';
export const SAVE_INSTALL_BASE_PART = 'SAVE_INSTALL_BASE_PART';
export const SET_SELECTED_INSTALL_BASE_PART_ID = 'SET_SELECTED_INSTALL_BASE_PART_ID';
export const CLEAR_SELECTED_INSTALL_BASE_PART_ID = 'CLEAR_SELECTED_INSTALL_BASE_PART_ID';

// manage locations
export const LOCATION_MANAGE_FAILED = 'LOCATION_MANAGE_FAILED';
export const LOCATION_UPDATE = 'LOCATION_UPDATE';
export const LOCATION_ADD = 'LOCATION_ADD';
export const LOCATION_DELETE_SUCCESS = 'LOCATION_DELETE_SUCCESS';
export const LOCATION_DELETE_FAILED = 'LOCATION_DELETE_FAILED';
export const LOCATION_MANAGE_TOTAL_PAGES = 'LOCATION_MANAGE_TOTAL_PAGES';
export const SET_TABLE_FILTER_MANAGE_LOCATION = 'SET_TABLE_FILTER_MANAGE_LOCATION';
export const TOGGLE_MODAL_EDIT_LOCATION = 'TOGGLE_MODAL_EDIT_LOCATION';

export const SET_SELECTED_BUILDING = 'SET_SELECTED_BUILDING';
export const SET_SELECTED_FLOOR = 'SET_SELECTED_FLOOR';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const SET_SELECTED_ROOM = 'SET_SELECTED_ROOM';
export const SET_SELECTED_FACILITY = 'SET_SELECTED_FACILITY';
export const SET_VISIBLE_LOCATIONS = 'SET_VISIBLE_LOCATIONS';

/*
 * MANAGE MEASUREMENT POINT ACTIONS
 */
export const MANAGE_MEASUREMENT_POINT_LISTS_SUCCESS = 'MANAGE_MEASUREMENT_POINT_LISTS_SUCCESS';
export const MANAGE_MEASUREMENT_POINT_LISTS_FAILED = 'MANAGE_MEASUREMENT_POINT_LISTS_FAILED';

// MEASUREMENT POINT RESULTS
export const ADD_MEASUREMENT_POINT_RESULT = 'ADD_MEASUREMENT_POINT_RESULT';
export const UPDATE_MEASUREMENT_POINT_RESULT = 'UPDATE_MEASUREMENT_POINT_RESULT';
export const UPDATE_MEASUREMENT_POINT_RESULT_STATUS = 'UPDATE_MEASUREMENT_POINT_RESULT_STATUS';
export const GET_MEASUREMENT_POINT_FACILITY_RESULTS = 'GET_MEASUREMENT_POINT_FACILITY_RESULTS';
export const GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS = 'GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS';
export const GET_MEASUREMENT_POINT_FACILITY_RESULTS_FAILED = 'GET_MEASUREMENT_POINT_FACILITY_RESULTS_FAILED';
export const REMOVE_NOT_TESTED = 'REMOVE_NOT_TESTED';
export const SET_NOT_TESTED = 'SET_NOT_TESTED';
export const SET_HISTORICAL_RESULT_ID = 'SET_HISTORICAL_RESULT_ID';
export const CLEAR_HISTORICAL_RESULT_ID = 'CLEAR_HISTORICAL_RESULT_ID';
export const SET_PREVIOUS_RESULT = 'SET_PREVIOUS_RESULT';
export const RESET_PREVIOUS_RESULT = 'RESET_PREVIOUS_RESULT';
export const UPDATE_MEASUREMENT_POINT_RESULT_BULK = 'UPDATE_MEASUREMENT_POINT_RESULT_BULK';
export const GET_MEASUREMENT_POINT_RESULT_HISTORY = 'GET_MEASUREMENT_POINT_RESULT_HISTORY';
export const SET_MEASUREMENT_POINT_RESULT_HISTORY = 'SET_MEASUREMENT_POINT_RESULT_HISTORY';
export const SET_HISTORICAL_MP_RESULT = 'SET_HISTORICAL_MP_RESULT';
export const CLEAR_HISTORICAL_MP_RESULT = 'CLEAR_HISTORICAL_MP_RESULT';
export const UPDATE_MEASUREMENT_POINT_RESULT_BULK_SUCCESS = 'UPDATE_MEASUREMENT_POINT_RESULT_BULK_SUCCESS';

export const ADD_SIMPLE_MEASUREMENT_POINT_RESULT = 'ADD_SIMPLE_MEASUREMENT_POINT_RESULT';
export const UPDATE_SIMPLE_MEASUREMENT_POINT_RESULT = 'UPDATE_SIMPLE_MEASUREMENT_POINT_RESULT';
export const RESET_SIMPLE_MEASUREMENT_POINT_RESULT = 'RESET_SIMPLE_MEASUREMENT_POINT_RESULT';
export const GET_SIMPLE_MEASUREMENT_POINT_JOB_RESULTS_SUCCESS = 'GET_SIMPLE_MEASUREMENT_POINT_JOB_RESULTS_SUCCESS';
export const GET_SIMPLE_MEASUREMENT_POINT_JOB_RESULTS_FAILED = 'GET_SIMPLE_MEASUREMENT_POINT_JOB_RESULTS_FAILED';

// mobile only action types
export const FILTER_VISIBLE_INSTALLS = 'FILTER_VISIBLE_INSTALLS';
export const SET_SELECTED_JOB = 'SET_SELECTED_JOB';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const GET_ALL_PRODUCTS_FAILED = 'GET_ALL_PRODUCTS_FAILED';
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export const UPDATE_SYNC_STATUS_SUCCESS = 'UPDATE_SYNC_STATUS_SUCCESS';
export const UPDATE_SYNC_STATUS_FAILED = 'UPDATE_SYNC_STATUS_FAILED';
export const INITIAL_SYNC_START = 'INITIAL_SYNC_START';
export const INITIAL_SYNC_END = 'INITIAL_SYNC_END';
export const OVERRIDE_OFFLINE_STATUS = 'OVERRIDE_OFFLINE_STATUS';
export const OVERRIDE_OFFLINE_STATUS_RESET = 'OVERRIDE_OFFLINE_STATUS_RESET';
export const RESET_USER_LOGOUT = 'RESET_USER_LOGOUT';

// Location actions
export const SET_LOCATION_PATHNAME = 'SET_LOCATION_PATHNAME';

// Sync Manager actions
export const SET_SYNC_STATUS = 'SET_SYNC_STATUS';
export const SET_DOWNLOADING_JOB = 'SET_DOWNLOADING_JOB';
export const SET_DOWNLOADING_JOB_FAILED = 'SET_DOWNLOADING_JOB_FAILED';
export const SET_INITIAL_APP_SYNC_LIST = 'SET_INITIAL_APP_SYNC_LIST';
export const REMOVE_INITIAL_APP_SYNC_ITEM = 'REMOVE_INITIAL_APP_SYNC_ITEM';
export const CLEAR_INITIAL_APP_SYNC_LIST = 'CLEAR_INITIAL_APP_SYNC_LIST';
export const UPDATE_INITIAL_APP_SYNC_ITEM_STATUS = 'UPDATE_INITIAL_APP_SYNC_ITEM_STATUS';
export const SET_DOWNLOAD_SPEED = 'SET_DOWNLOAD_SPEED';

// SAP Job Mapping actions
export const SET_SAP_JOB_MAPPING = 'SET_SAP_JOB_MAPPING';

export const INSTALL_UPDATE = 'INSTALL_UPDATE';
export const INSTALL_ADD = 'INSTALL_ADD';

export const ADD_RECENT_PRODUCT = 'ADD_RECENT_PRODUCT';
export const REMOVE_RECENT_PRODUCT = 'REMOVE_RECENT_PRODUCT';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';

export const MANAGE_MEASUREMENT_POINT_LIST_UPDATE = 'MANAGE_MEASUREMENT_POINT_LIST_UPDATE';
export const SET_SELECTED_MEASUREMENT_POINT_LIST = 'SET_SELECTED_MEASUREMENT_POINT_LIST';
export const CLEAR_SELECT_MEASUREMENT_POINT_LIST = 'CLEAR_SELECT_MEASUREMENT_POINT_LIST';

export const FILTER_VISIBLE_JOBS = 'FILTER_VISIBLE_JOBS';

export const GET_JOB_COMMENTS = 'GET_JOB_COMMENTS';
export const MANAGE_JOB_COMMENTS_SUCCESS = 'MANAGE_JOB_COMMENTS_SUCCESS';
export const MANAGE_JOB_COMMENTS_FAILED = 'MANAGE_JOB_COMMENTS_FAILED';
export const MANAGE_JOB_COMMENTS_ADD = 'MANAGE_JOB_COMMENTS_ADD';
export const MANAGE_JOB_COMMENTS_UPDATE = 'MANAGE_JOB_COMMENTS_UPDATE';
export const MANAGE_JOB_COMMENTS_SELECT = 'MANAGE_JOB_COMMENTS_SELECT';
export const TOGGLE_MODAL_EDIT_JOB_COMMENT = 'TOGGLE_MODAL_EDIT_JOB_COMMENT';
export const MANAGE_JOB_COMMENTS_FILTER_VISIBLE = 'MANAGE_JOB_COMMENTS_FILTER_VISIBLE';
export const SET_TABLE_FILTER_MANAGE_JOB_COMMENTS = 'SET_TABLE_FILTER_MANAGE_JOB_COMMENTS';
export const MANAGE_JOB_COMMENT_CODES_SUCCESS = 'MANAGE_JOB_COMMENT_CODES_SUCCESS';
export const MANAGE_JOB_COMMENT_CODES_FAILED = 'MANAGE_JOB_COMMENT_CODES_FAILED';

export const FACILITY_UPDATE = 'FACILITY_UPDATE';
// export const TOGGLE_MODAL_EDIT_COMMENT =
//   'TOGGLE_MODAL_SEARCH_NEW_PRODUCTS';

// Work Orders
export const SHOW_WORK_ORDER = 'SHOW_WORK_ORDER';
export const HIDE_WORK_ORDER = 'HIDE_WORK_ORDER';
export const EDIT_WORKORDER_SUCCESS = 'EDIT_WORKORDER_SUCCESS';
export const EDIT_WORKORDER_FAILED = 'EDIT_WORKORDER_FAILED';
export const GET_WORKORDERS = 'GET_WORKORDERS';
export const LOAD_WORKORDERS_SUCCESS = 'LOAD_WORKORDERS_SUCCESS';
export const LOAD_SAP_WORKORDERS_SUCCESS = 'LOAD_SAP_WORKORDERS_SUCCESS';
export const GET_SAP_WORKORDERS = 'GET_SAP_WORKORDERS';
export const UNLINK_SAP_WORKORDER = 'UNLINK_SAP_WORKORDER';
export const DELETE_UNLINKED_SAP_WORKORER = 'DELETE_SAP_WORKORER';
export const DELETE_WORKORDER = 'DELETE_WORKORDER';
export const UPDATE_WORK_ORDERS_BULK = 'UPDATE_WORK_ORDERS_BULK';
export const SET_FORM_VALUES_WORK_ORDER = 'SET_FORM_VALUES_WORK_ORDER';
export const UPDATE_FORM_VALUES_WORK_ORDER = 'UPDATE_FORM_VALUES_WORK_ORDER';
export const UPDATE_INSTALL_BASES = 'UPDATE_INSTALL_BASES';
export const UPDATE_WORK_ORDER = 'UPDATE_WORK_ORDER';
export const TOGGLE_MODAL_CLOSING_NOTES = 'TOGGLE_MODAL_CLOSING_NOTES';
export const JOB_UPDATE_WORKORDERS = 'JOB_UPDATE_WORKORDERS';
export const JOB_UPDATE_WORKORDERS_SUCCESS = 'JOB_UPDATE_WORKORDERS_SUCCESS';
export const DELETE_JOB_WORKORDERS = 'DELETE_JOB_WORKORDERS';
export const ADD_INSTALL_BASES = 'ADD_INSTALL_BASES';
export const ADD_WORKORDER = 'ADD_WORKORDER';
export const ADD_WORKORDER_FAILED = 'ADD_WORKORDER_FAILED';
export const SAVE_WORKORDER_NOTES = 'SAVE_WORKORDER_NOTES';
export const REMOVE_WORKORDER = 'REMOVE_WORKORDER';
export const REMOVE_WORKORDER_FAILED = 'REMOVE_WORKORDER_FAILED';
export const TOGGLE_MODAL_ADD_REPAIR_WORKORDER = 'TOGGLE_MODAL_ADD_REPAIR_WORKORDER';
export const SET_SELECTED_WORKORDER_ID = 'SET_SELECTED_WORKORDER_ID';
export const CLEAR_SELECTED_WORKORDER_ID = 'CLEAR_SELECTED_WORKORDER_ID';
export const SET_FORM_VALUES_MANAGE_WORKORDER = 'SET_FORM_VALUES_MANAGE_WORKORDER';
export const UPDATE_FORM_VALUES_MANAGE_WORKORDER = 'UPDATE_FORM_VALUES_MANAGE_WORKORDER';
export const WORKORDER_UPDATE_SELECTION = 'WORKORDER_UPDATE_SELECTION';
export const WORKORDER_MANAGE_TOTAL_PAGES = 'WORKORDER_MANAGE_TOTAL_PAGES';
export const LOAD_WORKORDERS_FAILED = 'LOAD_WORKORDERS_FAILED';
export const LOAD_SAP_WORKORDERS_FAILED = 'LOAD_SAP_WORKORDERS_FAILED';
export const TOGGLE_MODAL_CONFIRM_SELECT_JOB = 'TOGGLE_MODAL_CONFIRM_SELECT_JOB';
export const TOGGLE_MODAL_COMMISSIONING_JOB_CLOSING = 'TOGGLE_MODAL_COMMISSIONING_JOB_CLOSING';
export const CLEAR_SELECTED_PART_ID = 'CLEAR_SELECTED_PART_ID';
export const TOGGLE_MODAL_EDIT_PART = 'TOGGLE_MODAL_EDIT_PART';
export const SET_FORM_VALUES_PART = 'SET_FORM_VALUES_PART';
export const UPDATE_FORM_VALUES_PART = 'UPDATE_FORM_VALUES_PART';
export const ADD_PART = 'ADD_PART';
export const SET_SELECTED_HISTORICAL_WORKORDER_ID = 'SET_SELECTED_HISTORICAL_WORKORDER_ID';
export const CLEAR_SELECTED_HISTORICAL_WORKORDER_ID = 'CLEAR_SELECTED_HISTORICAL_WORKORDER_ID';
export const GET_FACILITIES_SEARCH_SUCCESS = 'GET_FACILITIES_SEARCH_SUCCESS';
export const GET_FACILITIES_SEARCH_FAILED = 'GET_FACILITIES_SEARCH_FAILED';
export const SET_FORM_VALUES_FACILITIES_FACILITY_SEARCH = 'SET_FORM_VALUES_FACILITIES_FACILITY_SEARCH';
export const UPDATE_FORM_VALUES_FACILITIES_FACILITY_SEARCH = 'UPDATE_FORM_VALUES_FACILITIES_FACILITY_SEARCH';
export const SET_TABLE_FILTER_MANAGE_WORKORDER = 'SET_TABLE_FILTER_MANAGE_WORKORDER';

// Job Signatures
export const ADD_JOB_SIGNATURE = 'ADD_JOB_SIGNATURE';
export const UPDATE_JOB_SIGNATURE = 'UPDATE_JOB_SIGNATURE';
export const GET_JOB_SIGNATURE = 'GET_JOB_SIGNATURE';
export const GET_JOB_SIGNATURE_SUCCESS = 'GET_JOB_SIGNATURE_SUCCESS';
export const GET_JOB_SIGNATURE_FAILED = 'GET_JOB_SIGNATURE_FAILED';
export const TOGGLE_MODAL_JOB_SIGNATURE = 'TOGGLE_MODAL_JOB_SIGNATURE';
export const TOGGLE_MODAL_JOB_CLOSING_WITH_SIGNATURE = 'TOGGLE_MODAL_JOB_CLOSING_WITH_SIGNATURE';
export const TOGGLE_MODAL_IS_COLLECTING_SIGNATURES = 'TOGGLE_MODAL_IS_COLLECTING_SIGNATURES';

// Photos
export const GET_PHOTOS = 'GET_PHOTOS';
export const GET_PHOTOS_SUCCESS = 'GET_PHOTOS_SUCCESS';
export const GET_PHOTOS_FAILED = 'GET_PHOTOS_FAILED';
export const PUT_PHOTO_SUCCESS = 'PUT_PHOTO_SUCCESS';
export const PUT_PHOTO_FAILED = 'PUT_PHOTO_FAILED';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_FAILED = 'DELETE_PHOTO_FAILED';
export const ADD_NEW_PHOTO_SUCCESS = 'ADD_NEW_PHOTO_SUCCESS';
export const ADD_NEW_PHOTO_FAILED = 'ADD_NEW_PHOTO_FAILED';

// Reports
export const UPDATE_SUMMARY_SUCCESS = 'UPDATE_SUMMARY_SUCCESS';
export const UPDATE_SUMMARY_FAILED = 'UPDATE_SUMMARY_FAILED';
export const TOGGLE_MODAL_UPDATE_SUMMARY = 'TOGGLE_MODAL_UPDATE_SUMMARY';
export const UPDATE_FORM_VALUES_VERIFICATION_SUMMARY = 'UPDATE_FORM_VALUES_VERIFICATION_SUMMARY';
export const REPORT_MANAGE_GET_DEFAULT_SUCCESS = 'REPORT_MANAGE_GET_DEFAULT_SUCCESS';
export const REPORT_MANAGE_GET_DEFAULT_FAILED = 'REPORT_MANAGE_GET_DEFAULT_FAILED';

// Config
export const SET_IS_MOBILE = 'SET_IS_MOBILE';

// Damage codes
export const SAVE_DAMAGE_CODES_SUCCESS = 'SAVE_DAMAGE_CODES_SUCCESS';

// Codes
export const GET_CODES = 'GET_CODES';
export const LOAD_CODES_SUCCESS = 'LOAD_CODES_SUCCESS';
export const ADD_CODE_SUCCESS = 'ADD_CODE_SUCCESS';
export const EDIT_CODE_SUCCESS = 'EDIT_CODE_SUCCESS';
export const REMOVE_CODE_SUCCESS = 'REMOVE_CODE_SUCCESS';

// Countries
export const SET_COUNTRIES = 'SET_COUNTRIES';

// Contacts
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS';
