/*
 * Edit Job Comment Form
 */

import * as React from 'react';

import { AbstractControl, FieldConfig, FormGenerator, FormGroup, GroupProps } from 'react-reactive-form';
import { IjobComment, IdeficiencyCode, Iuser, Ioption } from '../../models';
import {
    addJobComment,
    deleteJobComment,
    setSelectedJobComment,
    toggleEditJobCommentModal,
    updateJobComment
} from '../../actions/manageJobCommentsActions';

import { Button, Col } from 'react-bootstrap';
import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps } from 'react-router';
import { constants } from '../../constants/constants';
import { initialJobComment } from '../../reducers/initialState';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';

interface Iprops {
    selectedJobComment: IjobComment;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    toggleEditJobCommentModal: typeof toggleEditJobCommentModal;
    user: Iuser;
    addJobComment: typeof addJobComment;
    updateJobComment: typeof updateJobComment;
    setSelectedJobComment: typeof setSelectedJobComment;
    deleteJobComment: typeof deleteJobComment;
    deficiencyCodesByID: { [key: string]: IdeficiencyCode };
}
interface Istate {
    fieldConfig: FieldConfig;
}

export class EditJobCommentForm extends React.Component<Iprops & RouteComponentProps<{}>, Istate> {
    private formGroup: FormGroup | undefined;
    private subscription: any;

    constructor(props: Iprops & RouteComponentProps<{}>) {
        super(props);
        this.state = {
            fieldConfig: FormUtil.translateForm(this.buildFieldConfig(this.props.selectedJobComment), this.props.t)
        };
    }

    componentDidMount() {
        if (!this.props.selectedJobComment) {
            console.error('[componentDidMount]: missing job');
            return;
        }
    }
    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    subscribeToValueChanges = () => {
        if (this.formGroup) {
            const codeForm = this.formGroup.get('codeID');
            this.subscription = codeForm.valueChanges.subscribe((code: Ioption) => {
                const { text } = this.formGroup?.value;
                this.setState({
                    fieldConfig: FormUtil.translateForm(
                        this.buildFieldConfig({
                            ...this.formGroup?.value,
                            text: `${text}${this.props.deficiencyCodesByID[code.value].description}`
                        }),
                        this.props.t
                    )
                });
            });
        }
    };

    buildFieldConfig = ({ text, isDeficiency, isInternal, codeID }: IjobComment = initialJobComment) => {
        const disabled = false;
        const { deficiencyCodesByID } = this.props;

        const selectedCode = codeID ? FormUtil.convertToSingleOption(deficiencyCodesByID[codeID]) : null;

        let controls = {
            codeID: {
                render: FormUtil.Select,
                meta: {
                    label: 'Standard Text',
                    colWidth: 12,
                    isMulti: false,
                    name: 'code',
                    required: false,
                    options: FormUtil.convertToOptions(this.props.deficiencyCodesByID)
                },
                formState: { value: selectedCode, disabled }
            },
            text: {
                render: FormUtil.RichTextEditor,
                meta: {
                    showNormalToolbar: false,
                    label: 'comment',
                    colWidth: 12,
                    componentClass: 'textarea',
                    type: 'input',
                    name: 'comment',
                    required: true,
                    rows: 10,
                    initialContent: text,
                    charLimit: 1000,
                    overrideRTEText: true
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: { value: text, disabled }
            },
            isDeficiency: {
                render: FormUtil.Toggle,
                meta: { label: 'labelIsDeficiency', colWidth: 12 },
                formState: { value: isDeficiency, disabled }
            },
            isInternal: {
                render: FormUtil.Toggle,
                meta: { label: 'labelIsInternal', colWidth: 12 },
                formState: { value: isInternal, disabled }
            }
        } as { [key: string]: GroupProps };

        return {
            controls
        } as FieldConfig;
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup === undefined) {
            return;
        }
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }

        if (this.props.selectedJobComment.id.length) {
            this.props.updateJobComment(this.formGroup.value);
        } else {
            this.props.addJobComment(this.formGroup.value);
        }
        this.props.toggleEditJobCommentModal();
        this.props.setSelectedJobComment(initialJobComment);
    };

    handleDelete = (e: React.MouseEvent<Button>) => {
        e.preventDefault();
        this.props.deleteJobComment(this.props.selectedJobComment);
        this.props.toggleEditJobCommentModal();
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form as FormGroup;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        this.subscribeToValueChanges();
    };
    render() {
        const { t } = this.props;
        const { selectedJobComment } = this.props;
        if (!selectedJobComment) {
            return null;
        }
        return (
            <div className="job-details-content beacon-form">
                <form onSubmit={this.handleSubmit} className="clearfix beacon-form">
                    <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={this.props.toggleEditJobCommentModal}
                        >
                            {t('cancel')}
                        </Button>
                        {this.props.selectedJobComment.id.length > 0 && (
                            <Button
                                disabled={this.props.loading}
                                bsStyle="default"
                                type="button"
                                className="margin-right-1"
                                onClick={this.handleDelete}
                            >
                                {t('delete')}
                            </Button>
                        )}
                        <Button bsStyle={this.props.colorButton} type="submit" disabled={this.props.loading}>
                            {t('save')}
                        </Button>
                    </Col>
                </form>
            </div>
        );
    }
}
