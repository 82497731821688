// store list of jobParts, partStock, installBaseParts, and parts

import { filter, find, keyBy, map, orderBy, pickBy } from 'lodash';
import { combineReducers } from 'redux';
import {
    ADD_PART,
    ADD_TO_CART_QUOTE_PARTS,
    CHECKOUT_QUOTE_PARTS_FAILED,
    CHECKOUT_QUOTE_PARTS_SUCCESS,
    GET_ALL_INSTALL_BASE_PARTS_SUCCESS,
    GET_ALL_PARTS_SUCCESS,
    SAVE_INSTALL_BASE_PART,
    DELETE_QUOTE_ITEM_FROM_QUOTE_CART,
    CLEAR_CART,
    JOB_UPDATE_WORKORDERS_SUCCESS
} from '../actions/actionTypes';
import { IinitialState } from '.';
import {
    IinstallBase,
    IinstallBasePart,
    IinstallBasePartPopulated,
    IjobPart,
    IjobPopulated,
    IjobWorkOrder,
    ImeasurementPointResult,
    Ioption,
    Ipart,
    IpartStock,
    IquotePartCart,
    IsuggestedPart,
    IsuggestedPartPopulated,
    IworkOrderPart
} from '../models';
import initialState, {
    initialInstallBasePart,
    initialJobPart,
    initialJobWorkOrder,
    initialPart,
    initialPartStock,
    initialQuotePartCart,
    initialSuggestedPart,
    initialWorkOrderPart
} from './initialState';
import { createSelector } from 'reselect';
import { createFormValuesWithName, createSelectedIDWithName } from './commonReducers';
import moment from 'moment';
import { selectSelectedJob } from './manageJobReducer';
import { selectWorkOrdersByID } from './workOrderReducer';
import { selectJobWorkOrders } from './commonSelectors';
import { jobStatusEnum } from '../models-enums';
const uuidv4 = require('uuid/v4');

/**
 *************************** NORMALIZERS
 */
export const cleanInstallBasePart = (installBasePart: any): IinstallBasePart => {
    return {
        ...initialInstallBasePart,
        ...pickBy(installBasePart, (property, key) => property !== null)
    };
};

export const cleanJobPart = (jobPart: any): IjobPart => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { part, ...clanedJobPart } = jobPart;
    return {
        ...initialJobPart,
        ...pickBy(clanedJobPart, (property, key) => property !== null)
    };
};

export const cleanPart = (part: any): Ipart => {
    return {
        ...initialPart,
        ...pickBy(part, (property, key) => property !== null)
    };
};
export const cleanWorkOrderPart = (part: any): IworkOrderPart => {
    return {
        ...initialWorkOrderPart,
        ...pickBy(part, (property, key) => property !== null)
    };
};
export const cleanCart = (cart: any): IquotePartCart => {
    return {
        ...initialQuotePartCart,
        ...pickBy(cart, (property, key) => property !== null)
    };
};

export const cleanPartStock = (partStock: any): IpartStock => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { part, user, ...clanedPartStock } = partStock;
    return {
        ...initialPartStock,
        ...pickBy(clanedPartStock, (property, key) => property !== null)
    };
};

export const cleanSuggestedPart = (suggestedPart: any): IsuggestedPart => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { part, ...cleandSuggestedPart } = suggestedPart;
    return {
        ...initialSuggestedPart,
        ...pickBy(cleandSuggestedPart, property => property !== null)
    };
};

/**
 *************************** SELECTORS
 */
const getJobPartsByID = (state: IinitialState) => state.parts.jobPartsByID;
const getStockPartsByID = (state: IinitialState) => state.parts.partStockByID;
const getSelectedJobID = (state: IinitialState) => state.manageJob.selectedJob.id;
const getInstallBasePartsByID = (state: IinitialState) => state.parts.installBasePartsByID;
const getUser = (state: IinitialState) => state.user;
export const getHistoricalResultID = (state: IinitialState) => state.measurementPointResults.historicalResultID;
export const getHistoricalWorkOrderID = (state: IinitialState) => state.workOrder.historicalWorkOrderID;
const getJobWorkOrders = (state: IinitialState) => state.manageJob.jobWorkOrdersByID;
export const getMeasurementPointResults = (state: IinitialState) =>
    state.measurementPointResults.measurementPointResultsByID;
const getSuggestedParts = (state: IinitialState) => state.parts.suggestedPartsByID;
const getParts = (state: IinitialState) => state.parts.partsByID;
const getInstallBaseProp = (state: IinitialState, props: { installBase: IinstallBase }) => props.installBase;
const getInstallBases = (state: IinitialState) => state.manageInventory.installBasesByID;
const getProducts = (state: IinitialState) => state.manageInventory.productsByID;
const getInstallBaseIDProp = (state: IinitialState, props: { installBaseID: string }) => props.installBaseID;
const getWorkOrderPartsByID = (state: IinitialState) => state.parts.workOrderPartsByID;
const getSelectedWorkOrderID = (state: IinitialState) => state.workOrder.selectedWorkOrderID;

export const selectInstallBaseName = createSelector(
    [getInstallBases, getProducts, getInstallBaseIDProp],
    (installBases, products, installBaseID): string => {
        const install = installBases[installBaseID];
        const product = products[install.productID];
        if (product) {
            const serialNumber = install.serialNumber ? `: ${install.serialNumber}` : '';
            return `${product.name}${serialNumber}`;
        } else {
            return '';
        }
    }
);

/**
 * select suggested parts for this installbase
 */
export const selectSuggestedPartsForTable = createSelector(
    [getSuggestedParts, getParts, getInstallBaseProp],
    (suggestedParts, parts, installBase): IsuggestedPartPopulated[] => {
        return Object.values(suggestedParts).reduce((collector: IsuggestedPartPopulated[], suggestedPart) => {
            if (suggestedPart.isDeleted === false && installBase.productID === suggestedPart.productID) {
                const populatedSuggestedPart = {
                    ...suggestedPart,
                    part: parts[suggestedPart.partID] || initialPart
                };
                return [...collector, populatedSuggestedPart];
            } else {
                return collector;
            }
        }, []);
    }
);

export const selectJobWorkOrderForHistoricalWorkOrder = createSelector(
    [getHistoricalWorkOrderID, getJobWorkOrders],
    (workOrderID, jobWorkOrders) => {
        return find(jobWorkOrders, { isDeleted: false, workOrderID }) || initialJobWorkOrder;
    }
);

export const selectJobPartsForSelectedJob = createSelector(
    [getJobPartsByID, getSelectedJobID],
    (jobPartsByID, selectedJobID) => {
        const partsForJob = filter(jobPartsByID, {
            isDeleted: false,
            jobID: selectedJobID
        });
        return keyBy(partsForJob, 'id');
    }
);
export const selectPartStocksForUser = createSelector([getStockPartsByID, getUser], (stockParts, user): {
    [key: string]: IpartStock;
} => {
    return keyBy(filter(stockParts, { isDeleted: false, userID: user.id }), 'id');
});

// Collect parts from jobParts and stockParts
export const selectPartOptions = createSelector(
    [selectJobPartsForSelectedJob, selectPartStocksForUser, getParts],
    (selectedJobParts, stockParts, partsByID): Ioption[] => {
        const jobPartOptions = map(selectedJobParts, jobPart => {
            const part = partsByID[jobPart.partID];
            return {
                value: jobPart.id,
                label: `(${jobPart.estimated}) ${part.number} - ${part.description}`,
                order: part.description
            };
        });

        const stockPartOptions = map(stockParts, stockPart => {
            const part = partsByID[stockPart.partID];
            return {
                value: stockPart.id,
                label: `(${stockPart.quantity}) ${part.number} - ${part.description} - [boot stock]`,
                order: part.description
            };
        });

        const alphabetizedJobParts = orderBy(jobPartOptions, 'order');
        const alphabetizedPartStock = orderBy(stockPartOptions, 'order');
        return [...alphabetizedJobParts, ...alphabetizedPartStock];
    }
);

/*
 *   Add All Parts is to only be used for Work Order Parts
 */
export const selectAllParts = createSelector(
    [
        getParts,
        getWorkOrderPartsByID,
        selectSelectedJob,
        selectWorkOrdersByID,
        selectJobWorkOrders,
        getSelectedWorkOrderID
    ],
    (
        partsByID,
        workOrderPartsByID,
        selectedJob,
        workOrdersByID,
        jobWorkOrdersByID,
        selectedWorkOrderID
    ): IinstallBasePart[] => {
        const workOrder = workOrdersByID[selectedWorkOrderID];

        let woParts: IinstallBasePart[] = [];

        const jobWorkOrder: IjobWorkOrder = filter(jobWorkOrdersByID, {
            jobID: selectedJob.id,
            workOrderID: selectedWorkOrderID
        })[0];

        // Work Order Parts need to be filtered by Work Order Id (i.e. only show WO Parts for the WO that is being worked on)
        // If the job has been closed/reopened, you can't use work order parts, only if the job is new

        if (jobWorkOrder && selectedJob.status === jobStatusEnum.new) {
            map(workOrderPartsByID, woPart => {
                const wo = workOrdersByID[woPart.workOrderID];

                if (wo) {
                    const part = partsByID[woPart.partID];
                    if (part) {
                        woParts.push({
                            id: uuidv4(),
                            installBaseID: workOrder.installBaseID,
                            jobID: selectedJob.id,
                            jobPartID: null,
                            jobPartQuantity: 0,
                            partStockID: null,
                            partStockQuantity: 0,
                            directPartID: null,
                            directPartQuantity: 0,
                            workOrderPartID: woPart.id,
                            workOrderPartQuantity: woPart.estimated,
                            isDeleted: false
                        });
                    }
                }
            });
        }

        return woParts;
    }
);
export const getSelectedInstallBaseIdProp = (state: IinitialState, props: { installBaseID: string }) =>
    props.installBaseID;

export const selectInstallBasePartsPopulated = createSelector(
    [getInstallBasePartsByID, getParts, getJobPartsByID, getStockPartsByID, getWorkOrderPartsByID],
    (installBaseParts, parts, jobParts, stockParts, workOrderParts): { [key: string]: IinstallBasePartPopulated } => {
        const filteredInstallBaseParts = filter(installBaseParts, {
            isDeleted: false
        });
        const populatedInstallBaseParts = map(filteredInstallBaseParts, installPart => {
            let partID = '';

            if (installPart.jobPartID) {
                const jobPart = jobParts[installPart.jobPartID];

                if (!jobPart) {
                    // console.error('[selectInstallBasePartsPopulated]: missing job part', installPart);
                    return { ...installPart, part: initialPart };
                }

                partID = jobPart.partID;
            } else if (installPart.partStockID) {
                const stockPart = stockParts[installPart.partStockID];

                if (!stockPart) {
                    // console.error('[selectInstallBasePartsPopulated]: missing stock part', installPart);
                    return { ...installPart, part: initialPart };
                }

                partID = stockPart.partID;
            } else if (installPart.workOrderPartID) {
                const woPart = workOrderParts[installPart.workOrderPartID];

                if (!woPart) {
                    return { ...installPart, part: initialPart };
                }

                partID = woPart.partID;
            } else if (installPart.directPartQuantity && installPart.directPartID) {
                // currently for AMERICAN EYES ONLY
                partID = installPart.directPartID;
            } else {
                // console.error('[selectInstallBasePartsPopulated]: missing part', installPart);
            }

            return { ...installPart, part: parts[partID] || initialPart };
        });

        return keyBy(populatedInstallBaseParts, 'id');
    }
);

export const selectAllInstallBasePartsForJob = (
    jobID: string,
    installBasePartsPopulated: {
        [key: string]: IinstallBasePartPopulated;
    },
    installBaseID: string,
    historicalWorkOrderID: string,
    historicalResultID: string,
    jobWorkOrder: IjobWorkOrder,
    measurementPointResults: {
        [key: string]: ImeasurementPointResult;
    }
) => {
    let selectedJobID = jobID;

    if (historicalResultID) {
        selectedJobID = measurementPointResults[historicalResultID].jobID;
    }
    if (historicalWorkOrderID) {
        selectedJobID = jobWorkOrder.jobID;
    }

    const installBasePartsFiltered = filter(installBasePartsPopulated, {
        installBaseID,
        jobID: selectedJobID,
        isDeleted: false
    });

    return orderBy(installBasePartsFiltered, res => moment.utc(res?.createDate).unix(), 'desc');
};

/**
 * select all the installBaseParts for job
 * when a historical measurmentPointResult or historicalWorkOrder is being viewed, use the related job
 */
export const selectAllInstallBasePartsForInstallBaseAndJob = createSelector(
    [
        getSelectedJobID,
        selectInstallBasePartsPopulated,
        getSelectedInstallBaseIdProp,
        getHistoricalWorkOrderID,
        getHistoricalResultID,
        selectJobWorkOrderForHistoricalWorkOrder,
        getMeasurementPointResults
    ],
    (
        jobID,
        installBasePartsPopulated,
        installBaseID,
        historicalWorkOrderID,
        historicalResultID,
        jobWorkOrder,
        measurementPointResults
    ): IinstallBasePartPopulated[] => {
        return selectAllInstallBasePartsForJob(
            jobID,
            installBasePartsPopulated,
            installBaseID,
            historicalWorkOrderID,
            historicalResultID,
            jobWorkOrder,
            measurementPointResults
        );
    }
);

/**
 *********************** REDUCERS
 */

function jobPartsByIDReducer(state: { [key: string]: IjobPart } = {}, action: any): { [key: string]: IjobPart } {
    switch (action.type) {
        case GET_ALL_PARTS_SUCCESS: {
            let { jobParts } = action?.payload;
            if (action?.payload && action?.payload.data && action?.payload.data.jobParts) {
                jobParts = action?.payload.data.jobParts;
            }

            if (Array.isArray(jobParts)) {
                const keyedNewJobParts = jobParts.reduce((collector: { [key: string]: IjobPart }, jobPart) => {
                    return {
                        ...collector,
                        [jobPart.id]: cleanJobPart(jobPart)
                    };
                }, {});
                return { ...state, ...keyedNewJobParts };
            } else {
                return state;
            }
        }
        default:
            return state;
    }
}

function partStockByIDReducer(state: { [key: string]: IpartStock } = {}, action: any): { [key: string]: IpartStock } {
    switch (action.type) {
        case GET_ALL_PARTS_SUCCESS: {
            let { stockParts } = action.payload;
            if (action?.payload && action?.payload.data && action?.payload.data.stockParts) {
                stockParts = action?.payload.data.stockParts;
            }

            if (Array.isArray(stockParts)) {
                const keyedNewstockParts = stockParts.reduce((collector: { [key: string]: IpartStock }, partStock) => {
                    return {
                        ...collector,
                        [partStock.id]: cleanPartStock(partStock)
                    };
                }, {});
                return { ...state, ...keyedNewstockParts };
            } else {
                return state;
            }
        }
        case GET_ALL_INSTALL_BASE_PARTS_SUCCESS: {
            const { stockParts } = action.payload;
            if (Array.isArray(stockParts)) {
                const keyedNewstockParts = stockParts.reduce((collector: { [key: string]: IpartStock }, partStock) => {
                    return {
                        ...collector,
                        [partStock.id]: cleanPartStock(partStock)
                    };
                }, {});
                return { ...state, ...keyedNewstockParts };
            } else {
                return state;
            }
        }
        default:
            return state;
    }
}

function workOrderPartsByIDReducer(
    state: { [key: string]: IworkOrderPart } = {},
    action: any
): { [key: string]: IworkOrderPart } {
    switch (action.type) {
        case GET_ALL_PARTS_SUCCESS: {
            let { workOrderParts } = action.payload;
            if (action?.payload && action?.payload.data && action?.payload.data.workOrderParts) {
                workOrderParts = action?.payload.data.workOrderParts;
            }
            if (Array.isArray(workOrderParts)) {
                const keyedNewWorkOrderParts = workOrderParts.reduce(
                    (collector: { [key: string]: IpartStock }, workOrderPart) => {
                        return {
                            ...collector,
                            [workOrderPart.id]: cleanWorkOrderPart(workOrderPart)
                        };
                    },
                    {}
                );
                return { ...state, ...keyedNewWorkOrderParts };
            } else {
                return state;
            }
        }
        case JOB_UPDATE_WORKORDERS_SUCCESS: {
            let newWorkOrderParts: IworkOrderPart[] = [];

            if (action.payload && action.payload.data) {
                const updatedJob: IjobPopulated = action.payload.data;
                if (updatedJob && updatedJob.jobWorkOrders && updatedJob.jobWorkOrders.length > 0) {
                    const jobWorkOrders = updatedJob.jobWorkOrders;
                    jobWorkOrders.forEach(jwo => {
                        if (jwo.workOrder && jwo.workOrder.parts && jwo.workOrder.parts.length > 0) {
                            newWorkOrderParts = [...newWorkOrderParts, ...jwo.workOrder.parts];
                        }
                    });
                }
            }

            if (newWorkOrderParts.length > 0) {
                return { ...state, ...keyBy(newWorkOrderParts, 'id') };
            }

            return state;
        }
        default:
            return state;
    }
}

function partsByIDReducer(state: { [key: string]: Ipart } = {}, action: any): { [key: string]: Ipart } {
    switch (action.type) {
        case GET_ALL_PARTS_SUCCESS: {
            let { parts } = action.payload;
            if (action?.payload && action?.payload.data && action?.payload.data.parts) {
                parts = action?.payload.data.parts;
            }
            if (Array.isArray(parts)) {
                const keyedNewparts = parts.reduce((collector: { [key: string]: Ipart }, part) => {
                    return {
                        ...collector,
                        [part.id]: cleanPart(part)
                    };
                }, {});
                return { ...state, ...keyedNewparts };
            } else {
                return state;
            }
        }
        case GET_ALL_INSTALL_BASE_PARTS_SUCCESS: {
            const { parts } = action.payload;
            if (Array.isArray(parts)) {
                const keyedNewparts = parts.reduce((collector: { [key: string]: Ipart }, part) => {
                    return {
                        ...collector,
                        [part.id]: cleanPart(part)
                    };
                }, {});
                return { ...state, ...keyedNewparts };
            } else {
                return state;
            }
        }
        case ADD_PART: {
            return { ...state, [action.part.id]: cleanPart(action.part) };
        }
        case JOB_UPDATE_WORKORDERS_SUCCESS: {
            let newParts: Ipart[] = [];

            if (action.payload && action.payload.data) {
                const updatedJob: IjobPopulated = action.payload.data;
                if (updatedJob && updatedJob.jobWorkOrders && updatedJob.jobWorkOrders.length > 0) {
                    const jobWorkOrders = updatedJob.jobWorkOrders;
                    jobWorkOrders.forEach(jwo => {
                        if (jwo.workOrder && jwo.workOrder.parts && jwo.workOrder.parts.length > 0) {
                            jwo.workOrder.parts.forEach((woPart: any) => {
                                if (woPart.part) {
                                    newParts = [...newParts, woPart.part];
                                }
                            });
                        }
                    });
                }
            }

            if (newParts.length > 0) {
                return { ...state, ...keyBy(newParts, 'id') };
            }

            return state;
        }
        default:
            return state;
    }
}

function installBasePartsByIDReducer(
    state: { [key: string]: IinstallBasePart } = {},
    action: any
): { [key: string]: IinstallBasePart } {
    switch (action.type) {
        case GET_ALL_PARTS_SUCCESS: {
            let { installBaseParts } = action.payload;
            if (action?.payload && action?.payload.data && action?.payload.data.installBaseParts) {
                installBaseParts = action?.payload.data.installBaseParts;
            }

            const keyedNewInstallBaseParts = keyBy(map(installBaseParts, cleanInstallBasePart), 'id');
            return { ...state, ...keyedNewInstallBaseParts };
        }

        case GET_ALL_INSTALL_BASE_PARTS_SUCCESS: {
            const { installBaseParts } = action.payload;

            const keyedNewInstallBaseParts = keyBy(map(installBaseParts, cleanInstallBasePart), 'id');
            return { ...state, ...keyedNewInstallBaseParts };
        }

        case SAVE_INSTALL_BASE_PART: {
            const { installBasePart } = action;
            if (installBasePart) {
                return { ...state, [installBasePart.id]: installBasePart };
            } else {
                return state;
            }
        }
        default:
            return state;
    }
}

function suggestedPartsByIDReducer(
    state: { [key: string]: IsuggestedPart } = initialState.parts.suggestedPartsByID,
    action: any
): { [key: string]: IsuggestedPart } {
    switch (action.type) {
        case GET_ALL_PARTS_SUCCESS: {
            let { suggestedParts } = action.payload;
            if (action?.payload && action?.payload.data && action?.payload.data.suggestedParts) {
                suggestedParts = action?.payload.data.suggestedParts;
            }
            if (Array.isArray(suggestedParts)) {
                const receivedSuggestedParts = suggestedParts.reduce(
                    (collector: { [key: string]: IsuggestedPart }, suggestedPart) => {
                        const cleanedSuggestedPart = cleanSuggestedPart(suggestedPart);
                        return {
                            ...collector,
                            [cleanedSuggestedPart.id]: cleanedSuggestedPart
                        };
                    },
                    []
                );
                return { ...state, ...receivedSuggestedParts };
            }

            return state;
        }
        default:
            return state;
    }
}

function quoteCartsByIdReducer(
    state: { [key: string]: IquotePartCart } = initialState.parts.quoteCartsByID,
    action: any
): { [key: string]: IquotePartCart } {
    switch (action.type) {
        case ADD_TO_CART_QUOTE_PARTS: {
            // check for existing cart by finding one that is not deleted and for the selected job
            // if one is not found, then create one
            // TODO we might remove the part here and store it in the part reducer
            const foundCart = find(state, {
                jobID: action.jobID,
                isDeleted: false
            });
            if (foundCart) {
                return {
                    ...state,
                    [foundCart.id]: {
                        ...foundCart,
                        quoteItems: [...foundCart.quoteItems, action.item]
                    }
                };
            }
            const newQuoteCart: IquotePartCart = {
                ...initialQuotePartCart,
                id: uuidv4(),
                jobID: action.jobID,
                quoteItems: [action.item]
            };
            return { ...state, [newQuoteCart.id]: newQuoteCart };
        }
        case CHECKOUT_QUOTE_PARTS_FAILED:
        case CHECKOUT_QUOTE_PARTS_SUCCESS: {
            return { ...state, [action.cart.id]: action.cart };
        }

        case GET_ALL_PARTS_SUCCESS: {
            let { carts } = action.payload;
            if (action?.payload && action?.payload.data && action?.payload.data.carts) {
                carts = action?.payload.data.carts;
            }
            const keyedNewcarts = carts.reduce((collector: { [key: string]: IquotePartCart }, cart: IquotePartCart) => {
                return {
                    ...collector,
                    [cart.id]: cleanCart(cart)
                };
            }, {});
            return { ...state, ...keyedNewcarts };
        }

        case DELETE_QUOTE_ITEM_FROM_QUOTE_CART: {
            const quoteCartId = action.quoteCartId;
            const quoteCartItemIdToDelete = action.item.id;
            const foundCart = find(state, {
                id: quoteCartId,
                isDeleted: false
            });

            if (foundCart) {
                return {
                    ...state,
                    [foundCart.id]: {
                        ...foundCart,
                        quoteItems: [...foundCart.quoteItems.filter(x => x.id !== quoteCartItemIdToDelete)]
                    }
                };
            }

            return state;
        }
        case CLEAR_CART: {
            return {};
        }
        default:
            return state;
    }
}

const partsReducer = combineReducers({
    selectedInstallBasePartID: createSelectedIDWithName('INSTALL_BASE_PART_ID'),
    partFormValues: createFormValuesWithName('PART'),
    installBasePartsByID: installBasePartsByIDReducer,
    jobPartsByID: jobPartsByIDReducer,
    partsByID: partsByIDReducer,
    partStockByID: partStockByIDReducer,
    suggestedPartsByID: suggestedPartsByIDReducer,
    quoteCartsByID: quoteCartsByIdReducer,
    workOrderPartsByID: workOrderPartsByIDReducer
});

export default partsReducer;
