/* eslint-disable @typescript-eslint/no-non-null-assertion */
import moment from 'moment';
import * as localForage from 'localforage';
import { AxiosRequestConfig } from 'axios';
import { TFunction } from 'i18next';
import { map, omit } from 'lodash';
import { toastr } from 'react-redux-toastr';

import * as types from './actionTypes';
import API from '../constants/apiEndpoints';
import { FormUtil } from '../components/common/FormUtil';
import {
    IWorkOrder,
    IjobWorkOrder,
    ThunkResult,
    ItableFiltersParams,
    WOClosingNote,
    IjobSignature,
    IinstallBasePopulated,
    IinstallBase
} from '../models';
import { constants } from '../constants/constants';
import { bulkAddJobWorkOrdersHelper, completeJobWithFinalCheck } from './manageJobActions';
import {
    jobSourceEnum,
    jobTypesIdEnum,
    SignatureTypeEnum,
    workOrderPrioritiesEnum,
    workOrderStatusEnum,
    workOrderTypesEnum,
    workOrderVendorsEnum
} from '../models-enums';
import { initialFacility, initialWorkOrder } from '../reducers/initialState';
import { selectSelectedInstallBaseIDs } from '../reducers/commonReducers';

const uuidv4 = require('uuid/v4');

export const saveWorkOrder = (workOrder: Partial<IWorkOrder>): ThunkResult<any> => {
    return dispatch => {
        const url = `${API.workOrder.single}/${workOrder.id}`;
        const axiosOptions = {
            url,
            method: 'put',
            data: workOrder
        };
        dispatch({
            type: types.UPDATE_WORK_ORDER,
            workOrder,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'complete work order' },
                    rollback: {
                        type: types.UPDATE_WORK_ORDER,
                        workOrder
                    }
                }
            }
        });
    };
};

export const updateWorkOrders = (workOrders: IWorkOrder[], ...cleanProperties: string[]): ThunkResult<any> => {
    return dispatch => {
        // we can send slimmed down work order objects
        // const completedWorkOrdersForServer = map(completedWorkOrders, wo => {
        //     return {id: wo.id, closingNotes: wo.closingNotes}
        // })

        const cleanedWorkOrders = map(workOrders, wo => {
            return omit(wo, [...cleanProperties, 'facility']);
        });

        const axiosOptions = {
            url: API.workOrder.updateWorkOrders,
            method: 'put',
            data: cleanedWorkOrders
        };
        dispatch({
            type: types.UPDATE_WORK_ORDERS_BULK,
            workOrders,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'complete work orders' },
                    rollback: {
                        type: types.UPDATE_WORK_ORDERS_BULK,
                        workOrders
                    }
                }
            }
        });
    };
};

export const populateWOWithFacilityID = (
    wo: IWorkOrder,
    installBasesByID: { [key: string]: IinstallBase }
): IWorkOrder => {
    return {
        ...wo,
        facility: {
            ...initialFacility,
            id: installBasesByID[wo.installBaseID].facilityID
        }
    };
};

/*
 * Bulk Close Work Orders
 */
export const completeWorkOrdersHelper = (workOrders: IWorkOrder[], ...cleanProperties: string[]): ThunkResult<any> => {
    return dispatch => {
        const completedWorkOrders = map(workOrders, wo => {
            return {
                ...wo,
                status: wo.status === workOrderStatusEnum.notApplicable ? wo.status : workOrderStatusEnum.complete,
                updateDate: moment.utc().toISOString()
            };
        });
        dispatch(updateWorkOrders(completedWorkOrders, ...cleanProperties));
    };
};

export const addWorkOrder = (workOrder: IWorkOrder): ThunkResult<any> => dispatch => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: FormUtil.toFormData(workOrder),
        headers: { 'content-type': 'multipart/form-data' },
        url: API.POST.workOrder.add
    };
    dispatch({
        type: types.ADD_WORKORDER,
        workOrder,
        meta: {
            offline: {
                effect: { axiosOptions, message: 'add work order' },
                rollback: {
                    type: types.UPDATE_WORK_ORDER,
                    workOrder: { ...workOrder, isDeleted: true }
                }
            }
        }
    });
};

export const saveWorkOrderClosingNotes = (
    workOrder: IWorkOrder,
    newNote: WOClosingNote
): ThunkResult<any> => dispatch => {
    const cleanedWorkOrder = omit(workOrder, ['activeClosingNotes']) as IWorkOrder;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: newNote,
        url: API.workOrder.saveNotes
    };
    const updatedWorkOrder: IWorkOrder = {
        ...cleanedWorkOrder,
        activeClosingNotes: null,
        closingNotesList: [...(cleanedWorkOrder.closingNotesList || []), newNote]
    };

    dispatch({
        type: types.UPDATE_WORK_ORDER,
        workOrder: updatedWorkOrder,
        meta: {
            offline: {
                effect: { axiosOptions, message: 'add work order notes' },
                rollback: {
                    type: types.UPDATE_WORK_ORDER,
                    workOrder: updatedWorkOrder
                }
            }
        }
    });
};

/*
 * bulkCreateWorkOrders
 * create work orders for all the selected install bases
 */
export const bulkCreateWorkOrders = (jobID: string, t: TFunction, installBaseID?: string): ThunkResult<any> => (
    dispatch,
    getState
) => {
    let selectedInstallBaseIDs = selectSelectedInstallBaseIDs(getState());
    if (selectedInstallBaseIDs.length === 0 && installBaseID) {
        selectedInstallBaseIDs = [installBaseID];
    }
    let jobWorkOrderCollection: IjobWorkOrder[] = [];

    let type = workOrderTypesEnum.repair;
    const { jobTypeID } = getState().manageJob.selectedJob;

    if (jobTypeID === jobTypesIdEnum.warrantyBM) {
        type = workOrderTypesEnum.warranty;
    } else if (jobTypeID === jobTypesIdEnum.servicePlan) {
        type = workOrderTypesEnum.servicePlan;
    } else if (jobTypeID === jobTypesIdEnum.commissioning) {
        type = workOrderTypesEnum.commissioning;
    }

    selectedInstallBaseIDs.forEach(installBaseIDb => {
        const workOrderID = uuidv4();
        const newWorkOrder: IWorkOrder = {
            ...initialWorkOrder,
            id: workOrderID,
            installBaseID: installBaseIDb,
            priority: workOrderPrioritiesEnum.highRisk,
            type,
            vendor: workOrderVendorsEnum.beacon,
            dueDate: moment
                .utc()
                .startOf('day')
                .format(constants.momentSQLFormat)
        };
        jobWorkOrderCollection = [
            ...jobWorkOrderCollection,
            {
                id: uuidv4(),
                jobID,
                workOrderID,
                isDeleted: false
            }
        ];
        dispatch(addWorkOrder(newWorkOrder));
    });
    dispatch(bulkAddJobWorkOrdersHelper(jobWorkOrderCollection, jobID));
};

export const submitJobSignature = (
    t: TFunction,
    jobID: string,
    Signature: Blob,
    uri: string,
    type: number,
    SignedBy: string,
    finalize = false,
    installBasesPopulated?: IinstallBasePopulated[],
    signedByPosition?: string
): ThunkResult<any> => (dispatch, getState) => {
    const signatureID = uuidv4();
    const { showSignatureModal } = getState().manageInventory;
    const user = getState().user;
    const { isCollectingSignatures, isJobClosingWithSignature } = getState().workOrder;
    // TODO: check if parts check is needed
    const SignatureType = SignatureTypeEnum[type];
    const newSignature: IjobSignature = {
        id: signatureID,
        jobID,
        type,
        signedBy: SignedBy,
        signedByPosition: signedByPosition !== undefined ? signedByPosition! : user.position
    };
    const headers = { 'content-type': 'multipart/form-data' };
    const formData = FormUtil.toFormData({
        id: signatureID,
        jobID,
        Signature,
        SignatureType,
        SignedBy,
        SignedDate: moment().toISOString(),
        SignedByPosition: signedByPosition !== undefined ? signedByPosition! : user.position,
        Email: user.email
    });
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: formData,
        headers,
        url: API.signature.savesignature
    };

    dispatch({
        type: types.ADD_JOB_SIGNATURE,
        signature: newSignature,
        meta: {
            offline: {
                effect: { axiosOptions, message: 'add job signature' },
                rollback: {
                    type: types.ADD_JOB_SIGNATURE,
                    signature: newSignature
                }
            }
        }
    });

    localForage.setItem(signatureID, uri);

    if (isJobClosingWithSignature && finalize) {
        dispatch(completeJobWithFinalCheck());
        dispatch({ type: types.TOGGLE_MODAL_JOB_CLOSING_WITH_SIGNATURE });
    }

    if (finalize) {
        switch (true) {
            case showSignatureModal:
                dispatch({ type: types.TOGGLE_MODAL_SIGNATURE_PAD });
                break;
            case Boolean(installBasesPopulated):
                dispatch({ type: types.TOGGLE_MODAL_SIGNATURE_PAD });
                break;
            case type === SignatureTypeEnum.Hospital:
                dispatch({ type: types.TOGGLE_MODAL_VERIFICATION_SIGNATURE_PAD });
                break;
            default:
                dispatch({ type: types.TOGGLE_MODAL_JOB_SIGNATURE });
                break;
        }
    }

    if (isCollectingSignatures) {
        dispatch({ type: types.TOGGLE_MODAL_IS_COLLECTING_SIGNATURES });
    }
};

export const getJobSignatures = (jobID: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        const url = API.GET.signature;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                jobID
            },
            url
        };

        dispatch({
            type: types.GET_JOB_SIGNATURE,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'Get Job Signatures' },
                    commit: {
                        type: types.GET_JOB_SIGNATURE_SUCCESS
                    }
                }
            }
        });
    };
};

/*
 * Get Work Orders
 * when getting work orders for Beacon, do not send a facility,
 * the facility name can be included in the string search
 */

export const getWorkOrders = (facilityID: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        const pagingType = 'None';
        const url = API.workOrder.fseSearch;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                pagingType,
                facilityID,
                vendor: workOrderVendorsEnum.beacon,
                populateFacility: false
            },
            url
        };

        dispatch({
            type: types.GET_WORKORDERS,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'Get Work Orders' },
                    commit: {
                        type: types.LOAD_WORKORDERS_SUCCESS,
                        facilityID
                    }
                }
            }
        });
    };
};

export const getSAPWorkOrders = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        const { user } = getState();

        // If the user doesn't have these 2 security functions, they can't call this endpoint
        if (
            !constants.hasSecurityFunction(user, constants.securityFunctions.ViewInventory.id) &&
            !constants.hasSecurityFunction(user, constants.securityFunctions.ManageInventory.id)
        ) {
            console.error('User is missing security functions to get SAP Work Orders.');
            return dispatch({
                type: types.GET_SAP_WORKORDERS
            });
        }

        const pagingType = 'None';
        const url = API.workOrder.sapWorkOrders;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                pagingType,
                vendor: workOrderVendorsEnum.beacon
            },
            url
        };

        dispatch({
            type: types.GET_SAP_WORKORDERS,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'Get SAP Work Orders' },
                    commit: {
                        type: types.LOAD_SAP_WORKORDERS_SUCCESS
                    }
                }
            }
        });
    };
};

export const deleteWorkOrder = (t: TFunction): ThunkResult<any> => (dispatch, getState) => {
    const toastrConfirmOptions = {
        onOk: () => {
            const id = getState().workOrder.selectedWorkOrderID;
            const axiosOptions: AxiosRequestConfig = {
                method: 'delete',
                url: `${API.workOrder.single}/${id}`
            };
            dispatch({
                type: types.DELETE_WORKORDER,
                isDeleted: true,
                workOrderID: id,
                meta: {
                    offline: {
                        effect: { axiosOptions, message: 'delete work order' },
                        rollback: {
                            type: types.DELETE_WORKORDER,
                            isDeleted: false,
                            workOrderID: id
                        }
                    }
                }
            });
        },
        onCancel: () => {
            return;
        },
        okText: t('common:delete'),
        cancelText: t('common:cancel')
    };
    toastr.confirm(t('toastMessage:deleteWoConfirm'), toastrConfirmOptions);
};

export const unlinkSAPWorkOrder = (id: string, workOrder: IWorkOrder) => ({
    type: types.UNLINK_SAP_WORKORDER,
    id,
    workOrder
});

export const toggleWorkOrderCloseModal = () => ({
    type: types.TOGGLE_MODAL_CLOSING_NOTES
});

export const setWorkOrderFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_MANAGE_WORKORDER,
    formValues
});

export const updateWorkOrderFormValue = (formValues: { [key: string]: any }) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_WORKORDER,
    formValues
});
export const setSelectedWorkOrderID = (id: string) => ({
    type: types.SET_SELECTED_WORKORDER_ID,
    id
});
export const clearSelectedWorkOrderID = () => ({
    type: types.CLEAR_SELECTED_WORKORDER_ID
});

export const clearSelectedJob = () => ({
    type: types.CLEAR_SELECTED_JOB
});

export const toggleAddRepairWorkOrderModal = () => ({
    type: types.TOGGLE_MODAL_ADD_REPAIR_WORKORDER
});

export const toggleAddHoursModal = () => ({
    type: types.TOGGLE_MODAL_ADD_HOURS
});

export const toggleJobSignatureModal = () => ({
    type: types.TOGGLE_MODAL_JOB_SIGNATURE
});

export const updateWorkOrder = (workOrder: Partial<IWorkOrder>): ThunkResult<any> => dispatch => {
    dispatch({ type: types.UPDATE_WORK_ORDER, workOrder });
};

export const toggleConfirmSelectJobModal = () => ({
    type: types.TOGGLE_MODAL_CONFIRM_SELECT_JOB
});

export const toggleIsJobClosingWithSignature = () => ({
    type: types.TOGGLE_MODAL_JOB_CLOSING_WITH_SIGNATURE
});

export const toggleIsCollectingSignatures = () => ({
    type: types.TOGGLE_MODAL_IS_COLLECTING_SIGNATURES
});

export const setSelectedHistoricalWorkOrderID = (id: string) => ({
    type: types.SET_SELECTED_HISTORICAL_WORKORDER_ID,
    id
});

export const clearSelectedHistoricalWorkOrderID = () => ({
    type: types.CLEAR_SELECTED_HISTORICAL_WORKORDER_ID
});

export const toggleEditPartModalForm = () => ({
    type: types.TOGGLE_MODAL_EDIT_PART
});

export const updateWorkOrderSelection = (selection: string[]) => ({
    type: types.WORKORDER_UPDATE_SELECTION,
    payload: selection
});

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_WORKORDER,
    filters
});

export const updateVerificationSummaryFormValue = (formValues: { [key: string]: any }) => ({
    type: types.UPDATE_FORM_VALUES_VERIFICATION_SUMMARY,
    formValues
});
