import * as types from './actionTypes';

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Ibuilding, Ifloor, IinstallBase, Ilocation, Iroom, ItableFiltersParams, ThunkResult } from '../models';
import { filter, find, keyBy, map, orderBy } from 'lodash';
import { getInventory, saveInstallsHelper } from './manageInventoryActions';

import API from '../constants/apiEndpoints';
import { beginAjaxCall } from './ajaxStatusActions';
import { constants } from '../constants/constants';
import { msalFetch } from '../components/auth/Auth-Utils';
import { toastr } from 'react-redux-toastr';
import { measurementPointResultStatusTypesEnum, workOrderStatusEnum } from '../models-enums';
import { TFunction } from 'i18next';

const uuidv4 = require('uuid/v4');

export function getLocationsFacility(facilityID: string): ThunkResult<any> {
    return dispatch => {
        dispatch(beginAjaxCall());
        const url = `${API.GET.facility.getbyid}/${facilityID}`;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {},
            url
        };

        dispatch({
            type: types.GET_FACILIY_LOCATIONS,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'Get Facility Locations' },
                    commit: { type: types.GET_FACILITY_SUCCESS, facilityID }
                }
            }
        });
    };
}

const saveAnyLocationObjectHelper = (
    locationObject: Ilocation | Ibuilding | Ifloor | Iroom,
    facilityID: string
): ThunkResult<any> => {
    return (dispatch, getState) => {
        let url: string;
        if ('facilityID' in locationObject) {
            // BUILDING
            url = API.POST.building;
        } else if ('buildingID' in locationObject) {
            // FLOOR
            url = API.POST.floor;
        } else if ('floorID' in locationObject) {
            // LOCATION
            url = API.POST.location;
        } else {
            // ROOM
            url = API.POST.room;
        }
        const axiosOptions = {
            url,
            method: 'post',
            data: locationObject
        };
        const originalFacility = getState().facilities.facilitiesByID[facilityID];
        dispatch({
            type: types.LOCATION_ADD,
            locationObject,
            facilityID,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'add location' },
                    rollback: {
                        type: types.FACILITY_UPDATE,
                        facility: originalFacility
                    }
                }
            }
        });
    };
};

/*
 * save (add) a new building/floor/location/room
 * installBaseLocations is defined when saving locations from the editInstallForm
 */
export function saveAnyLocation(
    name: string,
    facilityID: string,
    installBaseLocations?: {
        buildingID?: string;
        floorID?: string;
        locationID?: string;
    }
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({ type: types.TOGGLE_MODAL_EDIT_LOCATION });
        let { buildingID, floorID, locationID } = getState().manageLocation.tableFilters;
        if (installBaseLocations) {
            buildingID = installBaseLocations.buildingID;
            floorID = installBaseLocations.floorID;
            locationID = installBaseLocations.locationID;
        }
        const newLocationObject = {
            id: uuidv4(),
            name,
            isDeleted: false
        };
        if (locationID) {
            dispatch(saveAnyLocationObjectHelper({ ...newLocationObject, locationID }, facilityID));
        } else if (floorID) {
            dispatch(saveAnyLocationObjectHelper({ ...newLocationObject, floorID, rooms: [] }, facilityID));
        } else if (buildingID) {
            dispatch(saveAnyLocationObjectHelper({ ...newLocationObject, buildingID, locations: [] }, facilityID));
        } else {
            dispatch(saveAnyLocationObjectHelper({ ...newLocationObject, facilityID, floors: [] }, facilityID));
        }
        return newLocationObject;
    };
}

/*
 * update (edit) a building/floor/location/room
 */
export function updateAnyLocation(
    locationObject: Ilocation | Ibuilding | Ifloor | Iroom,
    facilityID: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({ type: types.TOGGLE_MODAL_EDIT_LOCATION });
        let url: string;
        let lType: string;
        if ('facilityID' in locationObject) {
            url = `${API.PUT.building}/${locationObject.id}`;
            lType = 'Building';
        } else if ('buildingID' in locationObject) {
            url = `${API.PUT.floor}/${locationObject.id}`;
            lType = 'Floor';
        } else if ('floorID' in locationObject) {
            url = `${API.PUT.location}/${locationObject.id}`;
            lType = 'Location';
        } else {
            url = `${API.PUT.room}/${locationObject.id}`;
            lType = 'Room';
        }

        const axiosOptions: AxiosRequestConfig = {
            url,
            method: 'put',
            data: locationObject
        };
        const originalFacility = getState().facilities.facilitiesByID[facilityID];
        dispatch({
            type: types.LOCATION_UPDATE,
            lType,
            locationObject,
            facilityID,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'update location' },
                    rollback: {
                        type: types.FACILITY_UPDATE,
                        facility: originalFacility
                    }
                }
            }
        });
    };
}

/*
 * update (edit) a building/floor/location/room
 * TODO this is partially refactored to work offline, but we still need
 * to deleting a building should delete all children locations.
 * loop over all installBases and remove the appropriate locations.
 * BMA-600
 * For now we just show a toast to prevent deleting while offline
 * and make sure we get updated inventory from the API
 * we are depending on the API to remove deleted location attributes from installBases
 */
export function deleteAnyLocation(
    locationObject: Ilocation | Ibuilding | Ifloor | Iroom,
    facilityID: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        if (!getState().offline.online) {
            toastr.warning(
                'Please connect to the internet.',
                'Must be connected to the internet in order to delete a location.',
                constants.toastrWarning
            );
            return;
        }
        let url: string;
        let lType: string;
        if ('facilityID' in locationObject) {
            url = `${API.DELETE.building}/${locationObject.id}`;
            lType = 'Building';
        } else if ('buildingID' in locationObject) {
            url = `${API.DELETE.floor}/${locationObject.id}`;
            lType = 'Floor';
        } else if ('floorID' in locationObject) {
            url = `${API.DELETE.location}/${locationObject.id}`;
            lType = 'Location';
        } else {
            url = `${API.DELETE.room}/${locationObject.id}`;
            lType = 'Room';
        }

        const axiosOptions: AxiosRequestConfig = {
            method: 'delete',
            params: {}
        };

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (data.status !== 200) {
                    throw new Error('unexpected response while deleting');
                } else {
                    dispatch({
                        type: types.LOCATION_DELETE_SUCCESS,
                        lType,
                        locationObject,
                        facilityID
                    });
                    toastr.success('Success', `Deleted ${lType}.`, constants.toastrSuccess);
                    return getInventory(facilityID);
                }
            })
            .catch((error: any) => {
                dispatch({ type: types.LOCATION_DELETE_FAILED });
                constants.handleError(error, `delete ${lType}`);
                console.error(`[deleteAnyLocation]: delete ${lType}`, error);
            });
    };
}

const getInstallBasesToClone = (
    installBases: { [key: string]: IinstallBase },
    locationObjectType: 'buildingID' | 'floorID' | 'locationID' | 'roomID',
    locationObjectID: string
) => {
    return filter(
        installBases,
        install => install[locationObjectType] === locationObjectID && install.isDeleted === false
    );
};

/*
 * receive all installs, filter based on the location we are cloning
 */

const cloneRoom = (room: Iroom, locationID?: string) => {
    return {
        ...room,
        id: uuidv4(),
        locationID: locationID ? locationID : room.locationID,
        name: `${room.name} (cloned)`,
        originalID: room.id
    };
};

const cloneLocation = (location: Ilocation, floorID?: string) => {
    const id = uuidv4();
    const filteredRooms = location.rooms.filter(room => room.isDeleted === false);
    const rooms = filteredRooms.map(room => {
        return cloneRoom(room, id);
    });
    return {
        ...location,
        id,
        floorID: floorID ? floorID : location.floorID,
        name: `${location.name} (cloned)`,
        rooms,
        originalID: location.id
    };
};

const cloneFloor = (floor: Ifloor, buildingID?: string) => {
    const id = uuidv4();
    const filteredLocations = floor.locations.filter(location => location.isDeleted === false);
    const locations = filteredLocations.map(location => {
        return cloneLocation(location, id);
    });
    return {
        ...floor,
        id,
        buildingID: buildingID ? buildingID : floor.buildingID,
        name: `${floor.name} (cloned)`,
        locations,
        originalID: floor.id
    };
};

const cloneBuilding = (building: Ibuilding, facilityID?: string) => {
    const id = uuidv4();
    const filteredFloors = building.floors.filter(floor => floor.isDeleted === false);
    const floors = filteredFloors.map(floor => {
        return cloneFloor(floor, id);
    });
    return {
        ...building,
        id,
        facilityID: facilityID ? facilityID : building.facilityID,
        name: `${building.name} (cloned)`,
        floors,
        originalID: building.id
    };
};

/*
 * receive an array of installs, if the originalID matches then update the appropriate locationObjectID add a new ID. return the updated installs
 * originalID is the locationObject's original ID before being cloned.
 */
const updateInstallBases = (
    installBases: IinstallBase[],
    locationObjectType: 'buildingID' | 'floorID' | 'locationID' | 'roomID',
    locationObjectID: string,
    originalID: string
) => {
    return map(installBases, install => {
        const oldID = install[locationObjectType];

        const newInstall: IinstallBase = {
            ...install,
            id: uuidv4(),
            measurementPointListResultStatus: measurementPointResultStatusTypesEnum.resultStatusNotTested,
            latestMeasurementPointListResultID: '',
            latestAGSMeasurementPointListResultID: '',
            latestCommissioningMeasurementPointListResultID: '',
            latestVerificationMeasurementPointListResultID: '',
            latestAuditMeasurementPointListResultID: '',
            latestMeasurementPointListResultStatus: measurementPointResultStatusTypesEnum.resultStatusNotTested,
            workOrderResultStatus: workOrderStatusEnum.new
        };

        if (oldID && oldID.length && oldID === originalID) {
            return { ...newInstall, [locationObjectType]: locationObjectID };
        } else {
            return newInstall;
        }
    });
};

export const cloneAnyLocationObject = (
    locationObject: Ibuilding | Ifloor | Ilocation | Iroom,
    facilityID: string,
    t: TFunction
): ThunkResult<any> => {
    return (dispatch, getState) => {
        const installBases = getState().manageInventory.installBasesByID;
        const job = getState().manageJob.selectedJob;
        const { lastSync } = getState().syncStatus;

        if (locationObject.isDeleted === true) {
            throw new Error('Error, attempting to clone a deleted location.');
        }
        if ('facilityID' in locationObject) {
            // BUILDING
            const installsToClone = getInstallBasesToClone(installBases, 'buildingID', locationObject.id);
            const newBuilding = cloneBuilding(locationObject);
            dispatch(saveAnyLocationObjectHelper(newBuilding, facilityID)); // saves the building with floors, locations and rooms

            let newInstalls = updateInstallBases(installsToClone, 'buildingID', newBuilding.id, newBuilding.originalID); // update the installs with the new buildingID
            newBuilding.floors.forEach(floor => {
                if (floor.isDeleted === true) {
                    return;
                }
                newInstalls = updateInstallBases(newInstalls, 'floorID', floor.id, floor.originalID); // update the installs with the new floorID
                floor.locations.forEach(location => {
                    if (location.isDeleted === true) {
                        return;
                    }
                    newInstalls = updateInstallBases(newInstalls, 'locationID', location.id, location.originalID); // update the installs with the new locationIDs
                    location.rooms.forEach(room => {
                        if (room.isDeleted === true) {
                            return;
                        }
                        newInstalls = updateInstallBases(newInstalls, 'roomID', room.id, room.originalID); // update the installs with the new roomIDs
                    });
                });
            });
            if (newInstalls.length > 0) {
                dispatch(saveInstallsHelper(keyBy(newInstalls, 'id'), t, lastSync, undefined, job));
            }
        } else if ('buildingID' in locationObject) {
            // FLOOR
            const installsToClone = getInstallBasesToClone(installBases, 'floorID', locationObject.id);
            const newFloor = cloneFloor(locationObject);
            dispatch(saveAnyLocationObjectHelper(newFloor, facilityID)); // saves the floor with locations and rooms

            let newInstalls = updateInstallBases(installsToClone, 'floorID', newFloor.id, newFloor.originalID); // update the installs with the new floorID
            newFloor.locations.forEach(location => {
                if (location.isDeleted === true) {
                    return;
                }
                newInstalls = updateInstallBases(newInstalls, 'locationID', location.id, location.originalID); // update the installs with the new locationIDs
                location.rooms.forEach(room => {
                    if (room.isDeleted === true) {
                        return;
                    }
                    newInstalls = updateInstallBases(newInstalls, 'roomID', room.id, room.originalID); // update the installs with the new roomIDs
                });
            });
            if (newInstalls.length > 0) {
                dispatch(saveInstallsHelper(keyBy(newInstalls, 'id'), t, lastSync, undefined, job));
            }
        } else if ('floorID' in locationObject) {
            // LOCATION
            const installsToClone = getInstallBasesToClone(installBases, 'locationID', locationObject.id); // get all the installs with this locationID
            const newLocation = cloneLocation(locationObject); // clone the location as well as the rooms
            dispatch(saveAnyLocationObjectHelper(newLocation, facilityID)); // save the location with the rooms

            let newInstalls = updateInstallBases(installsToClone, 'locationID', newLocation.id, newLocation.originalID); // update the installs with the new locationID
            newLocation.rooms.forEach(room => {
                if (room.isDeleted === true) {
                    return;
                }
                newInstalls = updateInstallBases(newInstalls, 'roomID', room.id, room.originalID);
            }); // update the installs with the new roomIDs while keeping the right installs in the correct room
            if (newInstalls.length > 0) {
                dispatch(saveInstallsHelper(keyBy(newInstalls, 'id'), t, lastSync, undefined, job));
            }
        } else if ('locationID' in locationObject) {
            // ROOM
            const installsToClone = getInstallBasesToClone(installBases, 'roomID', locationObject.id);
            const newRoom = cloneRoom(locationObject);
            dispatch(saveAnyLocationObjectHelper(newRoom, facilityID)); // save the room
            const newInstalls = updateInstallBases(installsToClone, 'roomID', newRoom.id, newRoom.originalID); // update the installs with the new roomID
            if (newInstalls.length > 0) {
                dispatch(saveInstallsHelper(keyBy(newInstalls, 'id'), t, lastSync, undefined, job));
            }
        }
    };
};

export const toggleEditLocationModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_LOCATION
});

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_LOCATION,
    filters
});

/*
 * receive an array of locationObjects and filter out based on the selected filters and deleted items
 */
const filterLocationsHelper = (
    locations: Array<Ibuilding | Ifloor | Ilocation | Iroom>,
    tableFilters: ItableFiltersParams
) => {
    // const {} = tableFilters;
    return filter(locations, location => {
        let shouldInclude = true;
        if (location.isDeleted === true) {
            shouldInclude = false;
        }
        return shouldInclude;
    });
};

export const filterLocations = (facilityID: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        const { tableFilters } = getState().manageLocation;
        const { buildingID, locationID, floorID } = tableFilters;
        const { buildings } = getState().facilities.facilitiesByID[facilityID];
        let locations: Array<Ibuilding | Ifloor | Ilocation | Iroom> = [];
        if (buildingID && floorID && locationID) {
            // LOCATION
            const building = find(buildings, build => build.id === buildingID);
            if (building && building.floors.length) {
                const newFloor = find(building.floors, fl => fl.id === floorID);
                if (newFloor && newFloor.locations.length) {
                    const location = newFloor.locations.find(item => item.id === locationID);
                    if (location && location.rooms.length) {
                        locations = filterLocationsHelper(location.rooms, tableFilters);
                    }
                }
            }
        } else if (buildingID && floorID) {
            const building = find(buildings, build => build.id === buildingID);
            if (building && building.floors.length) {
                const newFloor = find(building.floors, fl => fl.id === floorID);
                if (newFloor && newFloor.locations.length) {
                    locations = filterLocationsHelper(newFloor.locations, tableFilters);
                }
            }
        } else if (buildingID) {
            const building = find(buildings, build => build.id === buildingID);
            if (building && building.floors.length) {
                locations = filterLocationsHelper(building.floors, tableFilters);
            }
        } else {
            locations = filterLocationsHelper(buildings, tableFilters);
        }

        dispatch({
            type: types.SET_VISIBLE_LOCATIONS,
            locations: orderBy(locations, 'name')
        });
    };
};
