import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';
import {
    createShowModalWithNamedType,
    initialSyncActiveReducer,
    offlineStatusOverrideReducer,
    offlineStatusByFacilityIdReducer,
    offlineStatusByJobIdReducer
} from './commonReducers';
import configReducer from './configReducer';
import { facilitiesReducer } from './facilitiesReducer';
import ajaxCallsInProgress from './ajaxStatusReducer';
import manageInventory from './manageInventoryReducer';
import manageJob from './manageJobReducer';
import manageJobCommentsReducer from './manageJobCommentsReducer';
import manageLocation from './manageLocationReducer';
import measurementPointLists from './measurementPointListsReducer';
import measurementPointResultsReducer from './measurementPointResultsReducer';
import parts from './partsReducer';
import photos from './photosReducer';
import simpleMeasurementPointResults from './simpleMeasurementPointResultsReducer';
import SyncStatusReducer from './syncStatusReducer';
import user from './userReducer';
import workOrder from './workOrderReducer';

import { AppState } from '@redux-offline/redux-offline/lib/types';
// eslint-disable-next-line import/no-unresolved
import { ToastrState } from '../typings/react-redux-toastr';
import { productInfoReducer } from './productInfoReducer';
import { cartReducerWithName } from './cartReducer';
import locationReducer from './locationReducer';
import manageCode from './manageCodeReducer';
import countriesReducer from './countriesReducer';
import contactsByFacilityReducer from './contactsReducer';
import syncManagerReducer from './syncManagerReducer';
import sapJobMappingReducer from './sapJobMappingReducer';

const rootReducer = combineReducers({
    contactsByFacility: contactsByFacilityReducer,
    countries: countriesReducer,
    location: locationReducer,
    syncManager: syncManagerReducer,
    ajaxCallsInProgress,
    appVersion: (state = {}) => state,
    config: configReducer,
    facilities: facilitiesReducer,
    initialSyncActive: initialSyncActiveReducer,
    manageCode,
    manageInventory,
    manageJob,
    manageJobComment: manageJobCommentsReducer,
    manageLocation,
    measurementPointLists,
    measurementPointResults: measurementPointResultsReducer,
    offlineStatusOverride: offlineStatusOverrideReducer,
    parts,
    photos,
    productInfo: productInfoReducer,
    showEditProfileModal: createShowModalWithNamedType('EDIT_PROFILE'),
    simpleMeasurementPointResults,
    syncStatus: SyncStatusReducer,
    toastr,
    user,
    workOrder,
    offlineStatusByFacilityID: offlineStatusByFacilityIdReducer,
    offlineStatusByJobID: offlineStatusByJobIdReducer,
    selectedCart: cartReducerWithName('QUOTE_PARTS'),
    sapJobMappingReducer
});

export type IinitialState = ReturnType<typeof rootReducer> & AppState & { toastr: ToastrState };

export default rootReducer;
