/*
 * Form render functions specific for Mobile and Measurement Points
 */

import * as React from 'react';

import { Col, ControlLabel, FormControl, FormGroup, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Select, { components } from 'react-select';

import { orderBy } from 'lodash';
import moment from 'moment';
import Datetime from 'react-datetime';
import { AbstractControl } from 'react-reactive-form';
import { MeasurementPointNoteButton } from '../measurementPointList/MeasurementPointNoteButton';
import { FormUtil } from './FormUtil';
import RichTextEditor from './RichTextEditor';

// add the bootstrap form-control class to the react-select select component
const ControlComponent = (props: any) => (
    <div>
        <components.Control {...props} className="form-control" />
    </div>
);

export const FormUtilMeasurementPoints = {
    text: (isMobile: boolean) => ({ handler, touched, meta, pristine, errors, submitted }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - optional' : '';
        return (
            <Col xs={meta.columnWidths.mainCol} className="mp-row">
                <FormGroup
                    validationState={FormUtil.getValidationState(pristine, errors, submitted)}
                    bsSize="sm"
                    style={meta.style}
                >
                    <Row className="vertical-align">
                        <Col
                            xs={meta.columnWidths.measurementPointCol}
                            style={{ paddingLeft: isMobile ? '0px' : undefined }}
                        >
                            <ControlLabel>
                                {meta.label}
                                <i className="required-label">{requiredLabel}</i>
                            </ControlLabel>
                            <FormControl
                                componentClass={meta.componentClass}
                                type={meta.type || 'text'}
                                rows={meta.rows}
                                autoFocus={meta.autoFocus}
                                name={meta.name || ''}
                                {...handler()}
                            />
                            <FormControl.Feedback />
                        </Col>
                        <Col className="mp-guide" xs={meta.columnWidths.guideCol}>
                            {meta.guideText}
                        </Col>
                    </Row>
                    {meta.helpText && (
                        <Row>
                            <Col
                                xs={meta.columnWidths.measurementPointCol}
                                style={{ paddingLeft: isMobile ? '0px' : undefined }}
                                className="mp-help"
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: meta.helpText
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                </FormGroup>
            </Col>
        );
    },

    select: (isMobile: boolean) => ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted,
        value
    }: AbstractControl) => {
        const selectClassName = meta.isMulti ? 'is-multi beacon-select' : 'beacon-select';
        const selectValidationClass = value && !pristine ? 'has-success' : '';
        const requiredLabel = meta.required === false ? ' - optional' : '';
        const selectOptions = meta.disableSort
            ? meta.options
            : orderBy(meta.options, option => option.label.toLowerCase());
        return (
            <Col xs={meta.columnWidths.mainCol} className="mp-row">
                <FormGroup validationState={FormUtil.getValidationState(pristine, errors, submitted)} bsSize="sm">
                    <Row className="vertical-align">
                        <Col
                            xs={meta.columnWidths.measurementPointCol}
                            style={{ paddingLeft: isMobile ? '0px' : undefined }}
                        >
                            <ControlLabel>
                                {meta.label}
                                <i className="required-label">{requiredLabel}</i>
                            </ControlLabel>
                            <Select
                                options={selectOptions}
                                className={`${selectClassName} ${selectValidationClass}`}
                                components={{ Control: ControlComponent }}
                                placeholder={meta.placeholder}
                                isMulti={meta.isMulti}
                                classNamePrefix="react-select"
                                name={meta.name || ''}
                                isClearable={typeof meta.isClearable !== 'undefined' ? meta.isClearable : false}
                                isDisabled={handler().disabled}
                                inputId={meta.id}
                                {...handler()}
                            />
                        </Col>
                        <Col className="mp-guide" xs={meta.columnWidths.guideCol}>
                            {meta.guideText}
                        </Col>
                    </Row>
                    {meta.helpText && (
                        <Row>
                            <Col
                                xs={meta.columnWidths.measurementPointCol}
                                style={{ paddingLeft: isMobile ? '0px' : undefined }}
                                className="mp-help"
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: meta.helpText
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                </FormGroup>
            </Col>
        );
    },
    MeasurementPointNote: (props: AbstractControl) => <MeasurementPointNoteButton {...props} />,
    group: ({ handler, meta }: any) => {
        return (
            <Col xs={meta.columnWidths.mainCol} className="mp-row">
                <Row>
                    <Col xs={12}>
                        <h4 className="mp-header">{meta.label}</h4>
                    </Col>
                </Row>
            </Col>
        );
    },
    passFail: (isMobile: boolean) => ({ handler, touched, meta, pristine, errors, submitted }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - optional' : '';

        return (
            <Col xs={meta.columnWidths.mainCol} className="mp-row">
                <FormGroup
                    validationState={FormUtil.getValidationState(pristine, errors, submitted)}
                    bsSize="sm"
                    style={meta.style}
                >
                    <Row className="vertical-align">
                        <Col xs={12} style={{ padding: isMobile ? '0px' : undefined }}>
                            <ControlLabel>
                                {meta.label}
                                <i className="required-label">{requiredLabel}</i>
                            </ControlLabel>
                            <ToggleButtonGroup
                                bsSize={isMobile ? 'sm' : undefined}
                                name="pass-fail"
                                {...handler()}
                                type="radio"
                                style={{ display: 'flex' }}
                            >
                                <ToggleButton
                                    bsSize={isMobile ? 'sm' : undefined}
                                    value={1}
                                    bsStyle="success"
                                    disabled={handler().disabled}
                                >
                                    Pass
                                </ToggleButton>
                                <ToggleButton
                                    bsSize={isMobile ? 'sm' : undefined}
                                    value={2}
                                    bsStyle="danger"
                                    disabled={handler().disabled}
                                >
                                    Fail
                                </ToggleButton>
                                <ToggleButton
                                    bsSize={isMobile ? 'sm' : undefined}
                                    value={3}
                                    bsStyle="primary"
                                    disabled={handler().disabled}
                                >
                                    N/A
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Col>
                        <Col className="mp-guide" xs={meta.columnWidths.guideCol}>
                            {meta.guideText}
                        </Col>
                    </Row>
                    {meta.helpText && (
                        <Row>
                            <Col
                                xs={meta.columnWidths.measurementPointCol}
                                style={{ paddingLeft: isMobile ? '0px' : undefined }}
                                className="mp-help"
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: meta.helpText
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                </FormGroup>
            </Col>
        );
    },
    numeric: (isMobile: boolean) => ({ handler, touched, meta, pristine, errors, submitted }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - optional' : '';
        return (
            <Col xs={meta.columnWidths.mainCol} className="mp-row">
                <FormGroup
                    validationState={FormUtil.getValidationState(pristine, errors, submitted)}
                    bsSize="sm"
                    style={meta.style}
                >
                    <Row className="vertical-align">
                        <Col
                            xs={meta.columnWidths.measurementPointCol}
                            style={{ paddingLeft: isMobile ? '0px' : undefined }}
                        >
                            <ControlLabel>
                                {meta.label}
                                <i className="required-label">{requiredLabel}</i>
                            </ControlLabel>
                            <FormControl
                                placeholder={meta.placeholder}
                                componentClass={meta.componentClass}
                                type="number"
                                rows={meta.rows}
                                autoFocus={meta.autoFocus}
                                name={meta.name || ''}
                                {...handler()}
                            />
                            <FormControl.Feedback />
                        </Col>
                        <Col className="mp-guide" xs={meta.columnWidths.guideCol}>
                            {meta.guideText}
                        </Col>
                    </Row>
                    {meta.helpText && (
                        <Row>
                            <Col
                                xs={meta.columnWidths.measurementPointCol}
                                style={{ paddingLeft: isMobile ? '0px' : undefined }}
                                className="mp-help"
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: meta.helpText
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                </FormGroup>
            </Col>
        );
    },
    date: (isMobile: boolean) => ({ handler, touched, meta, pristine, errors, submitted }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';

        const defaultValue = handler().value;
        const momentDate = !defaultValue || moment.isMoment(defaultValue) ? defaultValue : moment.utc(defaultValue);

        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup
                    validationState={FormUtil.getValidationState(pristine, errors, submitted)}
                    bsSize="sm"
                    className={`datetime-select ${meta.alignRight && 'alignRight'}`}
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <Datetime
                        timeFormat={false}
                        utc={true}
                        dateFormat={meta.dateFormat ? meta.dateFormat : 'DD-MMM-YY'}
                        isValidDate={meta.isValidDate}
                        inputProps={{
                            disabled: handler().disabled,
                            placeholder: meta.placeholder
                        }}
                        onChange={handler().onChange}
                        defaultValue={momentDate}
                    />
                    <FormControl.Feedback />
                </FormGroup>
            </Col>
        );
    },
    longText: (isMobile: boolean) => ({ handler, touched, meta, pristine, errors, submitted }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        return (
            <Row>
                <Col xs={12}>
                    <FormGroup validationState={FormUtil.getValidationState(pristine, errors, submitted)}>
                        <Col xs={6}>
                            <ControlLabel>
                                {meta.label}
                                <i className="required-label">{requiredLabel}</i>
                            </ControlLabel>
                            <div style={{ paddingBottom: '8px', display: 'flex' }}>
                                <input
                                    onChange={meta.showInReportHandler(meta.id)}
                                    checked={!meta.showInReport}
                                    style={{ margin: '0 8px 0 0' }}
                                    type="checkbox"
                                />{' '}
                                <label style={{ margin: 0 }}>Hide in Report</label>
                            </div>
                            <RichTextEditor
                                onChange={handler().onChange}
                                initialContent={meta.initialContent}
                                readOnly={handler().disabled}
                                showNormalToolbar={meta.showNormalToolbar}
                                charLimit={meta.charLimit}
                                overrideRTEText={meta.overrideRTEText}
                                resetOverrideRTEText={meta.resetOverrideRTEText}
                            />
                            <FormControl.Feedback style={{ top: '55px' }} />
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
        );
    },
    summaryPage: (isMobile: boolean) => ({ handler, touched, meta, pristine, errors, submitted }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        return (
            <Row>
                <Col xs={12}>
                    <FormGroup validationState={FormUtil.getValidationState(pristine, errors, submitted)}>
                        <Col xs={6}>
                            <ControlLabel>
                                {meta.label}
                                <i className="required-label">{requiredLabel}</i>
                            </ControlLabel>
                            <RichTextEditor
                                onChange={handler().onChange}
                                initialContent={meta.initialContent}
                                readOnly={handler().disabled}
                                showNormalToolbar={meta.showNormalToolbar}
                                charLimit={meta.charLimit}
                                overrideRTEText={meta.overrideRTEText}
                                resetOverrideRTEText={meta.resetOverrideRTEText}
                            />
                            <FormControl.Feedback style={{ top: '55px' }} />
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
        );
    }
};
