import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { filter, forEach, map, mapValues, orderBy } from 'lodash';
import moment from 'moment';
import { Button, Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import Datetime from 'react-datetime';
import FileIcon, { defaultStyles } from 'react-file-icon';
import { AbstractControl, FieldConfig, ValidationErrors, Validators } from 'react-reactive-form';
import Select, { components, createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import Toggle from 'react-toggle';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { constants } from '../../constants/constants';
import { isFloat, isInteger, isPositiveNumber } from '../../helpers/isStringNumber';
import { Ioption } from '../../models';
import RichTextEditor from './RichTextEditor';

// add the bootstrap form-control class to the react-select select component
const ControlComponent = (props: any) => (
    <div>
        <components.Control {...props} className="form-control" />
    </div>
);

export const FormUtil = {
    /*
     * utility to help transform JSON to query params
     */
    toUrlSearchParams: (paramCollection: { [key: string]: string[] | string }) => {
        const params = new URLSearchParams();
        forEach(paramCollection, (collection, key) => {
            if (Array.isArray(collection)) {
                forEach(collection, item => {
                    params.append(key, item);
                });
            } else {
                params.append(key, collection);
            }
        });
        return params;
    },
    patchControl: (
        formGroup: AbstractControl,
        key: string,
        value: any,
        options?: Ioption[] | any,
        disabled = false
    ) => {
        // const control = formGroup.get(key) as AbstractControlEdited; // can't figure out why this does not define next()
        const control = formGroup.get(key) as any;
        if (!control) {
            // if (process.env.NODE_ENV !== 'production') {
            //     console.error('[FormUtil (patchControl)]: missing control when trying to patch', key);
            // }

            return;
        }
        formGroup.patchValue({ [key]: value });
        if (options) {
            control.meta.options = options;
            control.statusChanges.next();
        }
    },
    validators: {
        requiredWithTrim: (m: any) => {
            if (m && m.value && typeof m.value === 'string' && m.value.trim().length > 0) {
                return null;
            } else if (m && m.value && typeof m.value === 'number') {
                // number
                return null;
            } else if (
                m &&
                m.value &&
                typeof m.value !== 'string' &&
                m.value.value &&
                typeof m.value.value === 'string' &&
                m.value.value.trim().length > 0
            ) {
                // option with string value
                return null;
            } else if (m && m.value && typeof m.value !== 'string' && typeof m.value.value === 'number') {
                // option with number value
                return null;
            } else if (m && m.value && moment.isMoment(m.value)) {
                // moment date
                return null;
            } else if (Array.isArray(m.value) && m.value.length > 0) {
                // multi select
                return null;
            } else {
                return { empty: { message: 'not long enough' } };
            }
        },
        isValidMoment: (m: any) => {
            if (m && !m.value) {
                return null;
            } else if (m && m.value && moment.isMoment(m.value)) {
                return null;
            } else if (
                m &&
                m.value &&
                moment.isMoment(m.value) === false &&
                (moment(m.value, 'DD-MMM-YY', true).isValid() ||
                    moment(m.value, 'YYYY-MM-DDTHH:mm:ss', true).isValid() ||
                    moment(m.value, moment.ISO_8601, true).isValid())
            ) {
                // This validation checks to see if it is one of the 3 expected formats.  1. the format from dateTime plugin, 2.
                //  format we receive from the API, 3. is what we save locally and send to the API
                // TODO this can be improved by using moment().format(constant.momentSQL) everywhere we have toISOString().
                return null;
            } else {
                return { isValidMoment: { message: 'not a valid date' } };
            }
        },
        isValidInteger: (m: any) => {
            if (m && !m.value) {
                return null;
            } else if (m && m.value && isInteger(m.value)) {
                return null;
            } else {
                return { isValidInteger: { message: 'not a valid integer' } };
            }
        },
        isValidFloat: (m: any) => {
            if (m && !m.value) {
                return null;
            } else if (m && m.value && isFloat(m.value)) {
                return null;
            } else {
                return { isValidInteger: { message: 'not a valid float' } };
            }
        },
        isValidPositiveInteger: (m: any) => {
            if (m && !m.value) {
                return null;
            } else if (m && m.value && isInteger(m.value) && isPositiveNumber(m.value)) {
                return null;
            } else {
                return { isValidInteger: { message: 'not a valid positive integer' } };
            }
        },
        requiredRichText: (m: any) => {
            if (m && m.value !== '<p><br></p>') {
                return null;
            } else {
                return { empty: { message: 'not long enough' } };
            }
        },
        maxLength: (maxChars: number) => (m: { value: string }) => {
            if (!m || !m.value) {
                return null;
            }

            const strippedHtml = m.value.replace(/<[^>]+>/g, '').trim(); // Only get the text inside the HTML tags
            if (strippedHtml.length <= maxChars) {
                return null;
            }

            return { empty: { message: 'too many characters' } };
        }
    },
    convertToOptions: (items: any, shouldIncludeDeleted = false): Ioption[] => {
        if (!items) {
            console.error('[FormUtil (convertToOptions)]: missing option items, returning empty array');
            return [];
        }
        const filteredItems = filter(items, item => {
            if (item.isDeleted === true && item.isDeleted !== shouldIncludeDeleted) {
                return false;
            }
            return true;
        });

        return map(filteredItems, (item: any) => {
            const lastOption = item.first && item.last ? item.first + ' ' + item.last : 'unknown';
            const engineerWithVanID = item.engineerVanID
                ? `${lastOption}: ${item.engineerVanID}`
                : `${lastOption} (No ID)`;
            const compoundOption =
                item.code && item.description
                    ? item.code + ' - ' + item.description
                    : item.description
                    ? item.description
                    : '';
            return {
                value: item.id || item.value || item.description,
                label: item.name || compoundOption || item.code || item.label || engineerWithVanID || lastOption
            };
        });
    },
    convertEnumToOptions: (items: any): Ioption[] => {
        // Helper
        const StringIsNumber = (value: string) => isNaN(Number(value)) === true;
        return Object.keys(items)
            .filter(StringIsNumber)
            .map((key: string) => {
                return { label: key, value: items[key] };
            });
    },
    convertToSingleOption: (item: any): Ioption | undefined => {
        if (item && item.id && item.id.length) {
            const lastOption = item.first && item.last ? item.first + ' ' + item.last : 'unknown';
            return {
                value: item.id || item.value,
                label: item.name || item.code || item.label || lastOption
            };
        } else {
            return undefined;
        }
    },
    getValidationState: (pristine: boolean, error: ValidationErrors, submitted: boolean) => {
        if (!pristine && error) {
            return 'error';
        } else if (!pristine && !error) {
            return 'success';
        } else if (pristine && error && submitted) {
            return 'error';
        } else {
            return null;
        }
    },
    /*
     * utility to help transform the data into multipart/form-data
     */
    toFormData: (formValue: any) => {
        const data = new FormData();
        Object.keys(formValue).forEach(key => {
            const value = formValue[key];
            if (moment.isMoment(value)) {
                data.append(key, value.utc().format(constants.momentSQLFormat));
            } else {
                data.append(key, value && value.value ? value.value : value);
            }
        });
        return data;
    },
    /*
     * getValues
     * a utility function to help convert form values to an object
     */
    getValues: (formValues: { [key: string]: any }) => {
        let values: {
            [key: string]: string | boolean | number | string[];
        } = {};
        forEach(formValues, (value, key) => {
            if (typeof value === 'object' && value !== null && 'value' in value) {
                values = { ...values, [key]: value.value };
            } else if (Array.isArray(value) && value.length) {
                values = { ...values, [key]: map(value, v => v.value) };
            } else if (moment.isMoment(value)) {
                values = {
                    ...values,
                    [key]: value.format(constants.momentSQLFormat)
                };
            } else {
                if (value === undefined) {
                    return; // don't add it if undefined
                }
                values = { ...values, [key]: value };
            }
        });
        return values;
    },
    Datetime: ({ handler, touched, meta, pristine, errors, submitted }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';

        const defaultValue = handler().value;
        const momentDate = !defaultValue || moment.isMoment(defaultValue) ? defaultValue : moment.utc(defaultValue);

        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup
                    validationState={FormUtil.getValidationState(pristine, errors, submitted)}
                    bsSize="sm"
                    className={`datetime-select ${meta.alignRight && 'alignRight'}`}
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <Datetime
                        timeFormat={false}
                        utc={true}
                        dateFormat={meta.dateFormat ? meta.dateFormat : 'DD-MMM-YY'}
                        isValidDate={meta.isValidDate}
                        inputProps={{
                            disabled: handler().disabled,
                            placeholder: meta.placeholder
                        }}
                        onChange={handler().onChange}
                        defaultValue={momentDate}
                    />
                    <FormControl.Feedback />
                </FormGroup>
            </Col>
        );
    },
    AsyncSelect: ({ handler, touched, meta, pristine, errors, submitted, value }: AbstractControl) => {
        const selectClassName = meta.isMulti ? 'is-multi beacon-select' : 'beacon-select';
        const selectValidationClass = value && !pristine ? 'has-success' : '';
        const requiredLabel = meta.required === false ? ' - Optional' : '*';

        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup validationState={FormUtil.getValidationState(pristine, errors, submitted)} bsSize="sm">
                    <ControlLabel style={{ textTransform: 'capitalize' }}>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <AsyncSelect
                        loadOptions={meta.loadOptions}
                        className={`${selectClassName} ${selectValidationClass}`}
                        components={{ Control: ControlComponent }}
                        placeholder={meta.placeholder}
                        defaultOptions
                        cacheOptions
                        isMulti={meta.isMulti}
                        classNamePrefix="react-select"
                        name={meta.name || ''}
                        isDisabled={handler().disabled}
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    RichTextEditor: ({ handler, touched, meta, pristine, errors, submitted }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup
                    bsSize="sm"
                    style={meta.style}
                    validationState={FormUtil.getValidationState(pristine, errors, submitted)}
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <RichTextEditor
                        showNormalToolbar={meta.showNormalToolbar}
                        onChange={handler().onChange}
                        initialContent={meta.initialContent}
                        readOnly={handler().disabled}
                        charLimit={meta.charLimit}
                        overrideRTEText={meta.overrideRTEText}
                    />
                    <FormControl.Feedback />
                </FormGroup>
            </Col>
        );
    },
    TextInput: ({ handler, touched, meta, pristine, errors, submitted }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - optional' : '*';
        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup
                    validationState={FormUtil.getValidationState(pristine, errors, submitted)}
                    bsSize="sm"
                    style={meta.style}
                >
                    <ControlLabel srOnly={meta.hideLabel}>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <FormControl
                        placeholder={meta.placeholder}
                        componentClass={meta.componentClass}
                        type={meta.type || 'text'}
                        rows={meta.rows}
                        autoFocus={meta.autoFocus}
                        name={meta.name || ''}
                        maxLength={meta.maxLength}
                        {...handler()}
                    />
                    <FormControl.Feedback />
                </FormGroup>
            </Col>
        );
    },
    Toggle: ({ handler, touched, meta, pristine, errors, submitted, value }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - optional' : '';
        return (
            <Col xs={meta.colWidth} style={{ ...meta.style }} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup validationState={FormUtil.getValidationState(pristine, errors, submitted)} bsSize="sm">
                    <label className="control-label">
                        <Toggle
                            icons={false}
                            checked={value}
                            {...handler()}
                            className="beacon-toggle"
                            name={meta.name || ''}
                        />
                        <span className="react-toggle-label">
                            {meta.label}
                            <i className="required-label">{requiredLabel}</i>
                        </span>
                    </label>
                </FormGroup>
            </Col>
        );
    },
    TextInputWithoutValidation: ({ handler, meta }: AbstractControl) => (
        <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
            <FormGroup bsSize="sm">
                <ControlLabel>{meta.label}</ControlLabel>
                <FormControl
                    placeholder={meta.placeholder}
                    componentClass={meta.componentClass}
                    name={meta.name || ''}
                    {...handler()}
                />
            </FormGroup>
        </Col>
    ),
    Select: ({ handler, touched, meta, pristine, errors, submitted, value }: AbstractControl) => {
        const selectClassName = meta.isMulti ? 'is-multi beacon-select' : 'beacon-select';
        const selectValidationClass = value && !pristine ? 'has-success' : '';
        const requiredLabel = meta.required === false ? ' - optional' : '*';
        const selectOptions = meta.disableSort
            ? meta.options
            : orderBy(meta.options, option => option.label.toLowerCase());
        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup validationState={FormUtil.getValidationState(pristine, errors, submitted)} bsSize="sm">
                    <ControlLabel style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <Select
                        options={selectOptions}
                        className={`${selectClassName} ${selectValidationClass}`}
                        components={{ Control: ControlComponent }}
                        placeholder={meta.placeholder}
                        isMulti={meta.isMulti}
                        classNamePrefix="react-select"
                        name={meta.name || ''}
                        isClearable={typeof meta.isClearable !== 'undefined' ? meta.isClearable : false}
                        isDisabled={handler().disabled}
                        filterOption={createFilter({ ignoreAccents: false })}
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    CreatableSelect: ({ handler, touched, meta, pristine, errors, submitted, value }: AbstractControl) => {
        const selectClassName = meta.isMulti ? 'is-multi beacon-select' : 'beacon-select';
        const selectValidationClass = value && !pristine ? 'has-success' : '';
        const requiredLabel = meta.required === false ? ' - optional' : '';
        const selectOptions = meta.disableSort
            ? meta.options
            : orderBy(meta.options, option => option.label.toLowerCase());
        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup validationState={FormUtil.getValidationState(pristine, errors, submitted)} bsSize="sm">
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <CreatableSelect
                        options={selectOptions}
                        className={`${selectClassName} ${selectValidationClass}`}
                        components={{ Control: ControlComponent }}
                        placeholder={meta.placeholder}
                        isMulti={meta.isMulti}
                        classNamePrefix="react-select"
                        onCreateOption={meta.handleCreate}
                        isClearable={typeof meta.isClearable !== 'undefined' ? meta.isClearable : false}
                        name={meta.name || ''}
                        isDisabled={handler().disabled}
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    AsyncCreatableSelect: ({ handler, touched, meta, pristine, errors, submitted, value }: AbstractControl) => {
        const selectClassName = meta.isMulti ? 'is-multi beacon-select' : 'beacon-select';
        const selectValidationClass = value && !pristine ? 'has-success' : '';
        const requiredLabel = meta.required === false ? ' - Optional' : '';

        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup validationState={FormUtil.getValidationState(pristine, errors, submitted)} bsSize="sm">
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <AsyncCreatableSelect
                        loadOptions={meta.loadOptions}
                        className={`${selectClassName} ${selectValidationClass}`}
                        components={{ Control: ControlComponent }}
                        placeholder={meta.placeholder}
                        isMulti={meta.isMulti}
                        classNamePrefix="react-select"
                        onCreateOption={meta.handleCreate}
                        isValidNewOption={meta.isValidNewOption}
                        isClearable={typeof meta.isClearable !== 'undefined' ? meta.isClearable : false}
                        name={meta.name || ''}
                        isDisabled={handler().disabled}
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    SelectWithoutValidation: ({ handler, meta }: AbstractControl) => {
        const selectClassName = meta.isMulti ? 'is-multi beacon-select' : 'beacon-select';
        const selectOptions = meta.disableSort
            ? meta.options
            : orderBy(meta.options, option => option.label.toLowerCase());
        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge} className={meta.className || ''}>
                <FormGroup bsSize="sm">
                    <ControlLabel>{meta.label}</ControlLabel>
                    <Select
                        options={selectOptions}
                        className={selectClassName}
                        components={{ Control: ControlComponent }}
                        placeholder={meta.placeholder}
                        isMulti={meta.isMulti}
                        classNamePrefix="react-select"
                        isClearable={typeof meta.isClearable !== 'undefined' ? meta.isClearable : false}
                        name={meta.name || ''}
                        isDisabled={handler().disabled || meta.disabled}
                        filterOption={createFilter({ ignoreAccents: false })}
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    SelectWithoutValidationLeftLabel: ({ handler, meta }: AbstractControl) => {
        const selectClassName = meta.isMulti ? 'is-multi beacon-select' : 'beacon-select';
        const selectOptions = meta.disableSort
            ? meta.options
            : orderBy(meta.options, option => option.label.toLowerCase());
        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge} className={meta.className || ''}>
                <FormGroup bsSize="sm">
                    <Col xs={2}>
                        <ControlLabel>{meta.label}</ControlLabel>
                    </Col>
                    <Col xs={10}>
                        <Select
                            options={selectOptions}
                            className={selectClassName}
                            components={{ Control: ControlComponent }}
                            placeholder={meta.placeholder}
                            isMulti={meta.isMulti}
                            classNamePrefix="react-select"
                            isClearable={typeof meta.isClearable !== 'undefined' ? meta.isClearable : false}
                            name={meta.name || ''}
                            isDisabled={handler().disabled}
                            filterOption={createFilter({
                                ignoreAccents: false
                            })}
                            {...handler()}
                        />
                    </Col>
                </FormGroup>
            </Col>
        );
    },
    SelectWithButton: ({ handler, touched, meta, pristine, errors, submitted, value }: AbstractControl) => {
        const selectClassName = meta.isMulti ? 'is-multi beacon-select' : 'beacon-select';
        const selectValidationClass = value && !pristine ? 'has-success' : '';
        const requiredLabel = meta.required === false ? ' - optional' : '';
        const selectOptions = meta.disableSort
            ? meta.options
            : orderBy(meta.options, option => option.label.toLowerCase());
        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup validationState={FormUtil.getValidationState(pristine, errors, submitted)} bsSize="sm">
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <Button bsStyle="link" className="pull-right right-side" onClick={meta.buttonAction}>
                        {meta.buttonName}
                    </Button>
                    <Select
                        options={selectOptions}
                        className={`${selectClassName} ${selectValidationClass}`}
                        components={{ Control: ControlComponent }}
                        placeholder={meta.placeholder}
                        isMulti={meta.isMulti}
                        classNamePrefix="react-select"
                        name={meta.name || ''}
                        isDisabled={handler().disabled}
                        filterOption={createFilter({ ignoreAccents: false })}
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    Button: ({ handler, meta }: AbstractControl) => {
        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <Button bsStyle={meta.style} className={meta.className} onClick={meta.buttonAction}>
                    {meta.buttonName}
                </Button>
            </Col>
        );
    },
    translateForm: (config: FieldConfig, t: TFunction) => {
        const newControls = mapValues(config.controls, (field: AbstractControl) => {
            if (field.meta && field.meta.label && !field.meta.skipTranslateLabel) {
                let newMeta: any = {
                    ...field.meta,
                    label: t(field.meta.label)
                };
                if (field.meta.buttonName) {
                    newMeta = {
                        ...newMeta,
                        buttonName: t(field.meta.buttonName)
                    };
                }
                if (field.meta.placeholder) {
                    newMeta = {
                        ...newMeta,
                        placeholder: t(field.meta.placeholder)
                    };
                }
                // we need this to translate the options for the security functions
                if (field.meta.shouldTranslate && field.meta.options && field.meta.options.length) {
                    const newOptions = map(field.meta.options, option => ({
                        value: option.value,
                        label: t(option.label)
                    }));
                    newMeta = { ...newMeta, options: newOptions };
                }
                return { ...field, meta: newMeta };
            }
            return field;
        });
        return { controls: newControls };
    },
    FileInput: ({ handler, touched, meta, pristine, errors, submitted, value }: AbstractControl) => {
        const requiredLabel = meta.required === false && !meta.uploadButton ? ' - Optional' : '';
        const extension =
            (meta.fileName && meta.fileName.split('.').pop()) || (meta.imageUrl && meta.imageUrl.split('.').pop());
        const btnClassName = handler().disabled ? 'btn btn-default disabled' : 'btn btn-default';
        const label =
            meta.imageUrl && meta.otherLabels && meta.otherLabels.labelReplace
                ? meta.otherLabels.labelReplace
                : meta.label;
        return (
            <Col xs={meta.colWidth}>
                {meta.hasPreview && meta.imageUrl && (
                    <img
                        alt="Uploaded Img"
                        src={meta.imageUrl}
                        style={{
                            maxWidth: '100%',
                            marginTop: '15px',
                            marginBottom: '15px'
                        }}
                    />
                )}
                <FormGroup
                    validationState={FormUtil.getValidationState(pristine, errors, submitted)}
                    bsSize="sm"
                    style={meta.style}
                >
                    <ControlLabel htmlFor="fileUpload" style={{ cursor: 'pointer' }}>
                        <span className={btnClassName}>
                            {meta.icon && <FontAwesomeIcon icon={['far', meta.icon]} />}
                            {label}
                            <i className="required-label">{requiredLabel}</i>
                        </span>
                        <FormControl
                            accept={meta.accept || 'image/*'}
                            id={'fileUpload'}
                            placeholder={meta.placeholder}
                            componentClass={meta.componentClass}
                            type={meta.type || 'file'}
                            rows={meta.rows}
                            autoFocus={meta.autoFocus}
                            name={meta.name || ''}
                            {...handler()}
                            onChange={(e: any) => {
                                const fileInput = e.target;
                                let fileName = '';
                                if (fileInput.files.length > 0) {
                                    meta.onChange(fileInput.files[0]);
                                    fileName = fileInput.files[0].name;
                                }
                                e.target.filename = fileName;
                            }}
                            style={{ display: 'none' }}
                        />
                    </ControlLabel>
                </FormGroup>
                {!meta.hasPreview && !meta.imageUrl && !meta.uploadButton && (
                    <FileIcon extension={extension} {...defaultStyles[extension]} size={64} />
                )}

                {!!meta.hasDownload && meta.imageUrl && meta.imageUrl.length !== 0 && (
                    <Row>
                        <Col xs={7}>
                            <Button bsStyle="default" href={meta.imageUrl} target="_blank">
                                {meta.otherLabels.labelDownload}
                            </Button>
                        </Col>
                        <Col xs={3}>
                            <FileIcon extension={extension} {...defaultStyles[extension]} size={64} />
                        </Col>
                    </Row>
                )}
            </Col>
        );
    },
    TextLabel: ({ handler, meta }: any) => {
        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup bsSize="sm" style={{ marginTop: '15px' }}>
                    <ControlLabel
                        style={{
                            fontWeight: 'bold',
                            borderBottom: meta.value ? '' : '1px solid #333',
                            wordBreak: meta.url ? 'break-all' : 'unset'
                        }}
                    >
                        {meta.label}
                    </ControlLabel>
                    <h5 className="queue-form-label">{handler().value}</h5>
                </FormGroup>
            </Col>
        );
    },
    TextLabelStatic: ({ meta }: any) => {
        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup bsSize="sm" style={{ marginTop: '15px' }}>
                    <ControlLabel
                        style={{
                            fontWeight: 'bold',
                            borderBottom: meta.value ? '' : '1px solid #333'
                        }}
                    >
                        {meta.label}
                    </ControlLabel>
                    <h5
                        className="queue-form-label"
                        style={{
                            display: meta.value !== undefined ? '' : 'none'
                        }}
                    >
                        {meta.value}
                    </h5>
                </FormGroup>
            </Col>
        );
    },
    NumericInput: ({ handler, touched, meta, pristine, errors, submitted }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - optional' : '*';
        return (
            <Col xs={meta.colWidth} md={meta.colWidthMedium} lg={meta.colWidthLarge}>
                <FormGroup
                    validationState={FormUtil.getValidationState(pristine, errors, submitted)}
                    bsSize="sm"
                    style={meta.style}
                >
                    <ControlLabel style={{ textTransform: 'capitalize' }}>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <FormControl
                        placeholder={meta.placeholder}
                        componentClass={meta.componentClass}
                        type="number"
                        rows={meta.rows}
                        autoFocus={meta.autoFocus}
                        name={meta.name || ''}
                        maxLength={meta.maxLength}
                        {...handler()}
                    />
                    <FormControl.Feedback />
                </FormGroup>
            </Col>
        );
    }
};

// reusable user form elements
export const userBaseConfigControls = {
    first: {
        options: {
            validators: [FormUtil.validators.requiredWithTrim]
        },
        render: FormUtil.TextInput,
        meta: { label: 'user:first', colWidth: 6, type: 'text', name: 'first' }
    },
    last: {
        options: {
            validators: [FormUtil.validators.requiredWithTrim]
        },
        render: FormUtil.TextInput,
        meta: { label: 'user:last', colWidth: 6, type: 'text', name: 'last' }
    },
    email: {
        options: {
            validators: [
                FormUtil.validators.requiredWithTrim,
                Validators.pattern(
                    /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
                )
            ]
        },
        render: FormUtil.TextInput,
        meta: {
            label: 'user:email',
            colWidth: 12,
            type: 'text',
            name: 'email'
        }
    },
    phone: {
        options: {
            validators: [
                FormUtil.validators.requiredWithTrim,
                Validators.pattern(
                    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
                )
            ]
        },
        render: FormUtil.TextInput,
        meta: {
            label: 'user:phone',
            colWidth: 6,
            type: 'tel',
            placeholder: '***-***-****',
            name: 'phone'
        }
    },
    position: {
        options: {
            validators: [FormUtil.validators.requiredWithTrim]
        },
        render: FormUtil.TextInput,
        meta: {
            label: 'user:position',
            colWidth: 6,
            type: 'text',
            name: 'position'
        }
    }
};
