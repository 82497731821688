/*
 * Edit alert Form
 * Add and Edit alerts
 *
 */

import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup, Validators } from 'react-reactive-form';
import { debounce } from 'lodash';
import { FormUtil } from '../../common/FormUtil';
import { IjobHour, Ijob, IlaborRate, INonProductiveHour } from '../../../models';
import { IpartFormValues } from '../../../modelsForms';
import { TFunction } from 'i18next';
import { constants } from '../../../constants/constants';
import { initialJobHour, initialJob } from '../../../reducers/initialState';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import ReactTable from 'react-table';
import { TableUtil } from '../../common/TableUtil';
import { saveNonProductiveHours } from '../../../actions/userActions';

const uuidv4 = require('uuid/v4');

interface Iprops {
    loading: boolean;
    colorButton: string;
    updateFormValue: (formValue: { [key: string]: any }) => any;
    setFormValues: (formValues: { [key: string]: any }) => any;
    t: TFunction;
    formValues: IpartFormValues;
    toggleModal: () => void;
    selectedJobHour: IjobHour;
    laborRates: IlaborRate[];
    disabled?: boolean;
    secondModal?: boolean;
    selectedJob: Ijob;
    currentUserId: string;
    onClose?: () => void;
    tableData: INonProductiveHour[];
    saveNonProductiveHours: typeof saveNonProductiveHours;
}

interface State {
    fieldConfig: FieldConfig;
    columns: any;
}

class AddNPHModalForm extends React.Component<Iprops, State> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateFormValueDebounced: any;
    static defaultProps = {
        selectedJobHour: initialJobHour,
        colorButton: constants.colors[`blueButton`],
        selectedJob: initialJob
    };
    constructor(props: Iprops) {
        super(props);

        this.state = {
            fieldConfig: this.buildFieldConfig(),
            columns: TableUtil.translateHeaders(
                [
                    {
                        Header: 'jobHoursForm.nphLaborRateHeader',
                        accessor: ({ number, description }: IjobHour) => {
                            return `${number} - ${description}`;
                        },
                        width: 260,
                        id: 'laborRate'
                    },
                    {
                        Header: 'jobHoursForm.hoursHeader',
                        accessor: 'quantity',
                        width: 80
                    },
                    {
                        Header: 'jobHoursForm.dateHeader',
                        id: 'date',
                        width: 100,
                        accessor: ({ date }: IjobHour) => {
                            return date
                                ? moment
                                      .utc(date)
                                      .local(true)
                                      .format(constants.momentDisplayFormat)
                                : 'n/a';
                        }
                    }
                ],
                this.props.t
            )
        };
        this.updateFormValueDebounced = debounce(this.props.updateFormValue, 300);
    }

    // since the modal is rendered in this component, this only runs when the ManageInventory view mounts
    componentDidMount() {
        this.setState({
            fieldConfig: this.buildFieldConfig()
        });
    }

    componentDidUpdate(prevProps: Iprops) {
        if (this.props.selectedJobHour !== prevProps.selectedJobHour) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.props.setFormValues({});
    }

    /*
     * formValuesToSelectedItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToSelectedItem = () => {
        const formValues = FormUtil.getValues(this.formGroup.value);
        const laborRateNum = this.props.laborRates.filter(rate => rate.description === formValues.description);

        return {
            ...formValues,
            userId: this.props.currentUserId,
            number: laborRateNum[0].code
        };
    };

    buildFieldConfig = () => {
        const disabled = this.props.disabled === true;
        const laborRatesOptions = FormUtil.convertToOptions([...this.props.laborRates]);
        const fieldConfigControls = {
            quantity: {
                render: FormUtil.NumericInput,
                meta: {
                    label: 'jobHoursForm.hoursHeader',
                    colWidth: 6,
                    name: 'quantity'
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: { value: 1, disabled }
            },
            date: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'jobHoursForm.dateLabel',
                    colWidth: 6,
                    name: 'date'
                },
                formState: {
                    value: moment.utc(),
                    disabled
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment, FormUtil.validators.requiredWithTrim]
                }
            },
            description: {
                render: FormUtil.Select,
                meta: {
                    label: 'jobHoursForm.nphLaborRateHeader',
                    options: laborRatesOptions,
                    colWidth: 12,
                    type: 'text',
                    placeholder: 'common:searchPlaceholder',
                    isClearable: true
                },
                options: {
                    validators: [Validators.required]
                },
                formState: { value: null, disabled }
            }
        };
        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }
        const cleanValues: any = this.formValuesToSelectedItem();

        const newProductiveHour: INonProductiveHour = {
            id: uuidv4(),
            ...cleanValues
        };

        this.props.saveNonProductiveHours(newProductiveHour);
        this.setState({ fieldConfig: this.buildFieldConfig() });
    };

    handleDone = () => {
        // If onClose is defined, override close state. Otherwise, set selectedJobHourID to empty string
        if (this.props.onClose) {
            this.props.onClose();
            return;
        }
        this.props.toggleModal();
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
    };

    cleanForm = () => {
        this.formGroup.reset();
    };

    render() {
        const { t } = this.props;
        const tableClassName = `beacon-table -highlight no-hover ${constants.getTileByURL(window.location.pathname)}`;
        const tableData = this.props.tableData;

        return (
            <form onSubmit={this.handleSubmit} className="clearfix beacon-form">
                <FormGenerator onUnmount={this.cleanForm} onMount={this.setForm} fieldConfig={this.state.fieldConfig} />

                <Row>
                    <Col xs={12}>
                        <div style={{ marginTop: '20px' }}>
                            <ReactTable
                                data={tableData}
                                columns={this.state.columns}
                                showPageSizeOptions={false}
                                showPagination={true}
                                className={tableClassName}
                                sortable={true}
                                multiSort={false}
                                noDataText={t('addHoursTableNoDataText')}
                                resizable={false}
                            />
                        </div>
                    </Col>
                </Row>

                <Col xs={12} className="form-buttons text-right">
                    <Button bsStyle="default" type="button" className="pull-left" onClick={this.handleDone}>
                        {t('common:doneButton')}
                    </Button>
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        style={{
                            marginLeft: '20px',
                            display: this.props.selectedJobHour.id.length ? '' : 'none'
                        }}
                        onClick={() => {
                            if (this.props.onClose) {
                                this.props.onClose();
                            }
                        }}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button bsStyle={this.props.colorButton} type="submit">
                        {t('jobHoursForm.saveSendToOfficeButton')}
                    </Button>
                </Col>
            </form>
        );
    }
}

export default AddNPHModalForm;
