import React, { useEffect, useMemo } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IinitialState } from '../../../reducers';
import { connect, useSelector } from 'react-redux';
import { Banner } from '../../common/Banner';
import { emptyTile } from '../../../reducers/initialState';
import { ItableFiltersReducer, IWorkOrder, Itile } from '../../../models';
import { constants, sapBusinessIndicators } from '../../../constants/constants';
import { RouteComponentProps } from 'react-router-dom';
import { filter } from 'lodash';
import selectTableHOC, { SelectTableAdditionalProps } from 'react-table/lib/hoc/selectTable';
import ReactTable, { RowInfo, SortingRule } from 'react-table';
import { Button, Col, Row } from 'react-bootstrap';
import { setTableFilter, updateInstallBaseSelection } from '../../../actions/manageInventoryActions';
import { useDispatch } from 'react-redux';
import { getSAPWorkOrders, toggleConfirmSelectJobModal } from '../../../actions/workOrderActions';
import { getSAPWorkOrders as selectSAPWorkOrders } from '../../../reducers/workOrderReducer';
import ConfirmSelectJobModal from '../ConfirmSelectJobModal';
import { SearchSAPWorkOrderTableContainer } from '../../manageInventory/SearchSAPWorkOrderTableContainer';
import { getFacilityIDsFromSelection, getManageInventorySelection } from '../../../reducers/commonReducers';
import moment from 'moment';
import { jobTypesIdEnumInverse } from '../../../models-enums';
import EditJobModal from '../../manageJob/EditJobModal';
import { getIsMobile } from '../../../reducers/configReducer';

interface Iprops extends RouteComponentProps<any> {
    showNotePadModal: boolean;
    showEditProductModal: boolean;
    showEditInstallModal: boolean;
    showSignatureModal: boolean;
    loading: boolean;
    selection: string[];
    facilityIDs: string[];
}
interface RowInfoWO extends RowInfo {
    original: IWorkOrder;
}

interface IdispatchProps {
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    updateInstallBaseSelection: typeof updateInstallBaseSelection;
    toggleConfirmSelectJobModal: typeof toggleConfirmSelectJobModal;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    columns: any[];
    selectAll: boolean;
}
const SelectTable = selectTableHOC(ReactTable);

const Container: React.FC<Iprops & IdispatchProps & WithTranslation & SelectTableAdditionalProps> = props => {
    const { t, facilityIDs } = props;
    const dispatch = useDispatch();
    const [state, setState] = React.useState<Istate>({
        selectedRow: {},
        currentTile: emptyTile,
        columns: [],
        selectAll: false
    });
    const isMobile = useSelector(getIsMobile);
    const isUnassignedWorkOrder = props.location.pathname !== '/sap' ? true : false;
    const selectedJob = useSelector((state: IinitialState) => state.manageJob.selectedJob);
    const installBasesByIDs = useSelector((state: IinitialState) => state.manageInventory.installBasesByID);
    const sapWorkOrderSelector = useSelector(selectSAPWorkOrders);
    const [workOrdersSameSAPType, setWorkOrdersSameSAPType] = React.useState<boolean>(false);
    const sapWorkOrders: IWorkOrder[] = useMemo(() => {
        return filter(Object.values(sapWorkOrderSelector), wo => {
            if (wo.isDeleted) {
                return false;
            }

            // Check if we need to filter SAP Work Orders by Facility ID
            if (!isUnassignedWorkOrder && selectedJob && selectedJob.id !== '') {
                // Facility is optional, so double check is needed
                if (wo.facility) {
                    if (wo.facility.id !== selectedJob.facilityID) {
                        return false;
                    }
                } else {
                    // Facility object was not populated on the work order, so find the install base
                    // NOTE: this might cause issues because installBasesByIDs is populated via install bases from opened jobs
                    const installBase = installBasesByIDs[wo.installBaseID];
                    if (installBase && installBase.facilityID !== selectedJob.facilityID) {
                        return false;
                    }
                }
            }

            return true;
        });
    }, [sapWorkOrderSelector]);

    const getJobTypeDisplayName = (wo: IWorkOrder) => {
        // Check if the work order is a virtual product
        let isVirtual = false;
        if (wo && wo.product && wo.product.subcategoryID === constants.virtualProductSubcategoryID) isVirtual = true;

        let jobTypeName = '';
        if (wo.suggestedJobTypeID) {
            jobTypeName = jobTypesIdEnumInverse[wo.suggestedJobTypeID];
        } else if (wo.sapBusinessIndicator === sapBusinessIndicators.fixedPrice) {
            jobTypeName = 'fixedPrice';
        } else if (wo.sapBusinessIndicator === sapBusinessIndicators.servicePlan && !isVirtual) {
            jobTypeName = 'servicePlan';
        } else if (wo.sapBusinessIndicator === sapBusinessIndicators.servicePlan && isVirtual) {
            jobTypeName = 'codeInspection';
        } else if (wo.sapBusinessIndicator === sapBusinessIndicators.mf) {
            jobTypeName = 'MMG';
        } else if (wo.sapBusinessIndicator === sapBusinessIndicators.fm) {
            jobTypeName = 'servicePlan';
        }
        return t(`nsJob:${jobTypeName}`);
    };

    const setColumns = () => {
        if (isMobile) {
            setState({
                ...state,
                columns: [
                    {
                        Header: t('common:facility'),
                        id: 'facility',
                        accessor: 'facility.name',
                        minWidth: 230,
                        Cell: (props: any) => (
                            <div className="mngJob-header-column">
                                <p className="mngJob-table-mobile-header">FACILITY</p>{' '}
                                <p className="mngJob-table-value first-row">{props.value}</p>
                            </div>
                        )
                    },
                    {
                        Header: t('jobType'),
                        id: 'jobType',
                        accessor: (wo: IWorkOrder) => {
                            return getJobTypeDisplayName(wo);
                        },
                        minWidth: 150,
                        Cell: (props: any) => (
                            <div className="mngJob-header-column">
                                <p className="mngJob-table-mobile-header">JOB TYPE</p>{' '}
                                <p className="mngJob-table-value">{props.value}</p>
                            </div>
                        )
                    },
                    {
                        Header: t('nsPart:asset'),
                        id: 'asset',
                        accessor: ({ productName, asset }: IWorkOrder) => {
                            return asset ? `${productName}; S/N: ${asset}` : productName;
                        },
                        minWidth: 230,
                        Cell: (props: any) => (
                            <div className="mngJob-header-column">
                                <p className="mngJob-table-mobile-header">ASSET</p>{' '}
                                <p className="mngJob-table-value">{props.value}</p>
                            </div>
                        )
                    },

                    {
                        Header: t('manageWorkOrder:description'),
                        id: 'description',
                        accessor: 'activityDescription',
                        minWidth: 230,
                        Cell: (props: any) => (
                            <div className="mngJob-header-column">
                                <p className="mngJob-table-mobile-header">DESCRIPTION</p>{' '}
                                <p className="mngJob-table-value">{props.value}</p>
                            </div>
                        )
                    },
                    {
                        Header: t('estimatedHours'),
                        id: 'estimatedHours',
                        accessor: 'plannedHours',
                        minWidth: 150,
                        Cell: (props: any) => (
                            <div className="mngJob-header-column">
                                <p className="mngJob-table-mobile-header">ESTIMATED HOURS</p>{' '}
                                <p className="mngJob-table-value">{props.value}</p>
                            </div>
                        )
                    },
                    {
                        Header: t('manageWorkOrder:dueDate'),
                        id: 'dueDate',
                        accessor: ({ dueDate }: IWorkOrder) => {
                            return dueDate
                                ? moment
                                      .utc(dueDate)
                                      .local(true)
                                      .format('DD-MMM-YYYY')
                                : 'n/a';
                        },
                        minWidth: 200,
                        Cell: (props: any) => (
                            <div className="mngJob-header-column">
                                <p className="mngJob-table-mobile-header">DUE DATE</p>{' '}
                                <p className="mngJob-table-value">{props.value}</p>
                            </div>
                        )
                    },
                    {
                        Header: t('sapWoNum'),
                        id: 'number',
                        accessor: 'number',
                        minWidth: 150,
                        Cell: (props: any) => (
                            <div className="mngJob-header-column">
                                <p className="mngJob-table-mobile-header">SAP WO#</p>{' '}
                                <p className="mngJob-table-value">{props.value}</p>
                            </div>
                        )
                    }
                ]
            });
        } else {
            setState({
                ...state,
                columns: [
                    {
                        Header: t('common:facility'),
                        id: 'facility',
                        accessor: 'facility.name',
                        minWidth: 230
                    },
                    {
                        Header: t('jobType'),
                        id: 'jobType',
                        accessor: (wo: IWorkOrder) => {
                            return getJobTypeDisplayName(wo);
                        },
                        minWidth: 90
                    },
                    {
                        Header: t('nsPart:asset'),
                        id: 'asset',
                        accessor: ({ productName, asset }: IWorkOrder) => {
                            return asset ? `${productName}; S/N: ${asset}` : productName;
                        },
                        minWidth: 250
                    },

                    {
                        Header: t('manageWorkOrder:description'),
                        id: 'description',
                        accessor: 'activityDescription',
                        minWidth: 250
                    },
                    {
                        Header: t('estimatedHours'),
                        id: 'estimatedHours',
                        accessor: 'plannedHours',
                        minWidth: 110
                    },
                    {
                        Header: t('nsJob:startDate'),
                        id: 'dueDate',
                        accessor: ({ dueDate }: IWorkOrder) => {
                            return dueDate
                                ? moment
                                      .utc(dueDate)
                                      .local(true)
                                      .format('DD-MMM-YYYY')
                                : 'n/a';
                        },
                        minWidth: 100
                    },
                    {
                        Header: t('sapWoNum'),
                        id: 'number',
                        accessor: 'number',
                        minWidth: 100
                    }
                ]
            });
        }
    };

    React.useEffect(() => {
        setColumns();
    }, [isMobile]);

    let checkboxTable: any;
    let tableClassName = `beacon-table -highlight ${state.currentTile.color}`;
    tableClassName = tableClassName + ' adding-workorder sap-wo-table';

    const isSelected = (key: string) => {
        return props.selection.includes(`select-${key}`);
    };

    const onPageChange = (page: number) => {
        props.setTableFilter({ page });
    };

    const onPageSizeChange = (rows: number) => {
        props.setTableFilter({ rows, page: 0 });
    };

    const onSortedChanged = (newSorted: SortingRule[], column: any, shiftKey: boolean) => {
        props.setTableFilter({ sorted: newSorted });
        setState({ ...state, selectedRow: {} });
    };

    const toggleSelection = (key: string, shift: boolean, row: any) => {
        let selection = [...props.selection];
        const keyIndex = selection.indexOf(key);

        if (keyIndex >= 0) {
            selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
        } else {
            selection.push(key);
        }
        props.updateInstallBaseSelection(selection);
    };

    const resetSelection = () => {
        if (props.selection.length > 0) {
            setState({ ...state, selectAll: false });
            props.updateInstallBaseSelection([]);
        }
    };

    React.useEffect(() => {
        const { selection } = props;
        if (selection.length === 0) {
            setWorkOrdersSameSAPType(false);
        } else if (selection.length === 1) {
            setWorkOrdersSameSAPType(true);
        } else {
            // You cannot add multiple work orders to a job if they have different suggested job types
            const allEqual = (arr: any[]) => arr.every(val => val === arr[0]);
            const wos = selection.map((item: string) => {
                const woID = item.split('select-')[1];
                const wo = sapWorkOrders.find(x => x.id === woID);
                return wo?.suggestedJobTypeID;
            });

            setWorkOrdersSameSAPType(allEqual(wos));
        }
    }, [props.selection]);

    const canAddSelectedWorkOrders = () => {
        if (props.selection.length === 0) {
            return false;
        }

        if (facilityIDs.length > 1) {
            return false;
        }

        if (!workOrdersSameSAPType) {
            return false;
        }

        return true;
    };

    /*
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    const getTrProps = (finalState: any, rowInfo?: RowInfoWO, column?: undefined, instance?: any) => {
        if (!rowInfo) {
            return {};
        }

        const isOverdue = rowInfo.original.dueDate && moment(rowInfo.original.dueDate) < moment();
        return {
            style: isOverdue ? { background: constants.colors.redTr } : undefined,
            onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                toggleSelection(`select-${rowInfo.original.id}`, false, null);
            }
        };
    };

    const toggleAll = () => {
        const { keyField = 'id' } = props;
        const selection: string[] = [];
        const selectAll = !state.selectAll;
        if (selectAll && keyField !== undefined) {
            const wrappedInstance = checkboxTable.getWrappedInstance();
            const currentRecords: any[] = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(`select-${item._original[keyField]}`);
            });
        }

        setState({ ...state, selectAll });
        props.updateInstallBaseSelection(selection);
    };

    useEffect(() => {
        setColumns();
        dispatch(getSAPWorkOrders());
        resetSelection();
    }, []);

    const facilities: string[] = useMemo(
        () =>
            sapWorkOrders
                .reduce((carry: string[], entity: IWorkOrder) => {
                    const name: string | undefined = entity.facility?.name;
                    if (!name || name === undefined) {
                        return carry;
                    }

                    if (!entity.id) {
                        return carry;
                    }
                    carry.push(name);
                    return carry;
                }, [])
                .filter((value, index, self) => self.indexOf(value) === index),
        [sapWorkOrders]
    );

    const tableData: IWorkOrder[] = useMemo(() => {
        let wos = sapWorkOrders;

        const { search, startDate, jobType, endDate, facility } = props.tableFilters;
        if (search) {
            wos = wos.filter(wo => {
                const term: string = search.toLocaleLowerCase();
                return (
                    wo?.facility?.name?.toLocaleLowerCase()?.includes(term) ||
                    wo?.number?.toLocaleLowerCase()?.includes(term) ||
                    wo?.activityDescription?.toLocaleLowerCase()?.includes(term)
                );
            });
        }
        if (startDate) {
            wos = wos.filter(wo => moment.utc(wo.dueDate).isSameOrAfter(moment.utc(startDate)));
        }
        if (endDate) {
            wos = wos.filter(wo => moment.utc(wo.dueDate).isSameOrBefore(moment.utc(endDate)));
        }
        if (jobType && jobType.value) {
            wos = wos.filter(wo => wo.suggestedJobTypeID === jobType.value);
        }
        if (facility && facility.value) {
            wos = wos.filter(wo => wo.facility?.name === facility.value);
        }

        return wos;
    }, [sapWorkOrders, props.tableFilters]);

    React.useEffect(() => {
        setColumns();
    }, [tableData]);

    return (
        <div className="manage-inventory" data-empty={tableData.length === 0}>
            <Banner
                title={'Unassigned Sap Work Orders'}
                img={state.currentTile.srcBanner}
                color={state.currentTile.color}
            />
            <SearchSAPWorkOrderTableContainer
                colorButton={constants.colors[`${state.currentTile.color}Button` as keyof typeof constants.colors]}
                history={props.history}
                location={props.location}
                match={props.match}
                resetSelection={resetSelection}
                isUnassignedWorkorder={true}
                multipleFacilitiesSelected={facilityIDs.length > 1}
                facilities={facilities}
                addButtonText={t('addSapWo')}
            />

            {isMobile && (
                <div className="mobile-sap-header">
                    <Button
                        onClick={props.toggleConfirmSelectJobModal}
                        disabled={canAddSelectedWorkOrders() === false}
                        title={props.selection.length > 0 ? 'add' : 'must select at least one'}
                    >
                        {t('addSapWo')}
                    </Button>

                    <Button
                        onClick={resetSelection}
                        disabled={props.selection.length === 0}
                        title={props.selection.length > 0 ? 'reset' : 'must select at least one'}
                    >
                        {props.t('resetSelectionButton')}
                    </Button>
                </div>
            )}
            <Row>
                <Col xs={12}>
                    <SelectTable
                        data={tableData}
                        columns={state.columns}
                        showPagination={tableData.length >= 9}
                        defaultPageSize={props.tableFilters.rows || constants.tablePageSizeDefault}
                        onPageSizeChange={onPageSizeChange}
                        showPageSizeOptions={true}
                        pageSizeOptions={constants.tablePageSizeOptions}
                        className={tableClassName}
                        getTrProps={getTrProps}
                        previousText={t('common:previous')}
                        nextText={t('common:next')}
                        onSortedChange={onSortedChanged}
                        sortable={true}
                        multiSort={false}
                        resizable={false}
                        onPageChange={onPageChange}
                        page={props.tableFilters.page}
                        toggleSelection={toggleSelection}
                        selectAll={state.selectAll}
                        selectType="checkbox"
                        toggleAll={toggleAll}
                        isSelected={isSelected}
                        ref={ref => (checkboxTable = ref)}
                        keyField="id"
                    />
                </Col>

                <ConfirmSelectJobModal
                    facilityID={facilityIDs[0]}
                    history={props.history}
                    location={props.location}
                    match={props.match}
                    forSAP={true}
                    newJobOption={true}
                />
                <EditJobModal
                    colorButton={constants.colors.blue}
                    fromWO={true}
                    secondModal={true}
                    history={props.history}
                />
            </Row>
        </div>
    );
};

export default withTranslation('manageInventory')(
    connect(
        (state: IinitialState) => {
            const { manageInventory } = state;
            return {
                selection: getManageInventorySelection(state),
                tableFilters: manageInventory.tableFilters,
                facilityIDs: getFacilityIDsFromSelection(state)
            };
        },
        {
            setTableFilter,
            updateInstallBaseSelection,
            toggleConfirmSelectJobModal
        }
    )(Container)
);
