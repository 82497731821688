import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ThunkResult } from '../models';
import API from '../constants/apiEndpoints';
import { msalFetch } from '../components/auth/Auth-Utils';
import * as types from './actionTypes';
import { constants } from '../constants/constants';

export const getCodes = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        const url = API.code.search;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { page: 1, pagingType: 'None' },
            url
        };

        dispatch({
            type: types.GET_CODES,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'Get Codes' },
                    commit: { type: types.LOAD_CODES_SUCCESS }
                }
            }
        });

        // return msalFetch(url, axiosOptions)
        //     .then((data: AxiosResponse<any>) => {
        //         if (!data.data) {
        //             throw new Error('missing data');
        //         } else {
        //             dispatch({
        //                 type: types.LOAD_CODES_SUCCESS,
        //                 payload: data.data.result
        //             });

        //             return data;
        //         }
        //     })
        //     .catch((error: any) => {
        //         dispatch({ type: types.LOAD_CODES_SUCCESS });
        //         constants.handleError(error, 'get codes');
        //     });
    };
};
