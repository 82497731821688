const root = process.env.REACT_APP_SERVER_DOMAIN;
console.info(`ENV: ${process.env.NODE_ENV} SERVER_DOMAIN: ${root}/ Version: ${process.env.REACT_APP_VERSION}`);

const API = {
    POST: {
        building: `${root}/building`,
        commissioningForm: `${root}/commissioningForm/add`,
        // customer: `${root}/customer`,
        facility: `${root}/facility/add`,
        floor: `${root}/floor`,
        location: `${root}/location`,
        measurementPoint: {
            addResults: `${root}/MeasurementPoint/AddJobMeasurementPointListResultsList`,
            saveSimpleResults: `${root}/MeasurementPoint/SaveSimpleMeasurementPointListResult`,
            getMeasurementPointListResultsForJobs: `${root}/MeasurementPoint/GetMeasurementPointListResultsForJobs`
        },
        photo: {
            add: `${root}/photo/add`
        },
        room: `${root}/room`,
        user: {
            login: `${root}/user/login`,
            updateprofile: `${root}/user/updateprofile`,
            saveNonProductiveHours: `${root}/user/saveNonProductiveHours`
        },
        job: {
            update: `${root}/job/update`,
            saveSignature: `${root}/job/saveSignature`,
            closeJob: `${root}/job/CloseJob`,
            create: `${root}/job/CreateJob`
        },
        jobComment: {
            add: `${root}/jobcomment/add`
        },
        workOrder: {
            add: `${root}/workOrder/add`
        },
        contact: {
            add: `${root}/contact/add`,
            update: `${root}/contact/updatecontactandfacilities`,
            delete: (facilityContactID: string) => `${root}/contact/${facilityContactID}/deletefacility`
        }
    },
    PUT: {
        building: `${root}/building`,
        floor: `${root}/floor`,
        location: `${root}/location`,
        room: `${root}/room`,
        job: `${root}/job`
    },
    DELETE: {
        building: `${root}/building/deletebuilding`,
        floor: `${root}/floor/deletefloor`,
        location: `${root}/location/deletelocation`,
        room: `${root}/room/deleteroom`,
        job: {
            deleteJobWorkOrder: `${root}/job/DeleteJobWorkOrder`
        },
        jobComment: {
            delete: `${root}/jobcomment/DeleteJobComment`
        }
    },
    GET: {
        sapJobMapping: {
            getAll: `${root}/SAPJobMapping/GetAll`
        },
        building: {
            getall: `${root}/building/getall`
        },
        facility: {
            getbyid: `${root}/facility`,
            getbycustomer: `${root}/facility/getbycustomer`,
            search: `${root}/facility/search`,
            searchByCountries: `${root}/facility/SearchByCountries`
        },
        floor: {
            getall: `${root}/floor/getall`
        },
        location: {
            getall: `${root}/location/getall`
        },
        measurementPoint: {
            getall: `${root}/measurementpoint/GetAllMeasurementPointLists`,
            getJobSimpleMPLResults: `${root}/MeasurementPoint/GetSimpleMeasurementPointListResults`,
            getFacilityMeasurementPointListResults: `${root}/MeasurementPoint/GetFacilityMeasurementPointListResults`,
            getMeasurementPointListResultHistory: `${root}/MeasurementPoint/GetMeasurementPointListResultHistory`
        },
        report: {
            defaults: `${root}/report/defaults`,
            preview: `${root}/report/run`
        },
        room: {
            getall: `${root}/room/getall`
        },
        signature: `${root}/signature`,
        user: {
            getfseusers: `${root}/user/getfseusers`,
            getNonProductiveHours: `${root}/user/getNonProductiveHours`,
            hoursCheck: `${root}/user/hourscheck`
        }
    },
    inventory: {
        updateproduct: `${root}/inventory/updateproduct`,
        addproduct: `${root}/inventory/addproduct`,
        quote: `${root}/inventory/beaconquote`,
        update: `${root}/inventory/update`,
        updateInstalls: `${root}/inventory/updateInstalls`,
        addinstall: `${root}/inventory/add`,
        deleteInstall: `${root}/inventory/delete`,
        installContact: `${root}/inventory/beaconContact`,
        getByFacility: `${root}/inventory/getbyfacility`,
        search: `${root}/inventory/search`, // unused
        getproductinfo: `${root}/inventory/getproductinfo`,
        products: `${root}/inventory/products?pagingType=None`,
        getallinstalls: `${root}/inventory/getallinstalls`,
        getallinstallsforjob: `${root}/inventory/getallinstallsforjob`,
        getNextSerialNumber: `${root}/inventory/getnextserialnumber`
    },
    contact: {
        getByFacility: `${root}/contact/getbyfacility`,
        findUserOrContactByEmail: `${root}/contact/finduserorcontactbyemail`
    },
    code: {
        getAll: `${root}/code/getall`,
        search: `${root}/code/search`
    },
    jobComment: {
        search: `${root}/jobcomment/search`,
        single: `${root}/jobcomment`
    },
    saveAppLog: `${root}/update/saveapplog`,
    workOrder: {
        single: `${root}/workorder`,
        complete: `${root}/workorder/complete`,
        updateWorkOrders: `${root}/workorder/updateworkorders`,
        search: `${root}/workorder/search`,
        fseSearch: `${root}/workorder/fsesearch`,
        saveNotes: `${root}/workorder/savenote`,
        sapWorkOrders: `${root}/workorder/getsapworkorders`,
        unlink: `${root}/workorder/unlinkworkorders`
    },
    job: {
        single: `${root}/job`,
        search: `${root}/job/search`,
        getAll: `${root}/job/getall`,
        getassigned: `${root}/job/getassigned`,
        openJob: `${root}/job/openJob`,
        getByFacility: `${root}/job/getbyfacility`,
        addWorkOrders: `${root}/job/addworkorders`,
        saveHours: `${root}/job/savehours`,
        validateParts: `${root}/job/validateparts`,
        saveQuoteCart: `${root}/job/saveQuoteCart`,
        getinstallbases: `${root}/job/getinstallbases`,
        addinstallbases: `${root}/job/addinstallbases`,
        deleteInstallBases: `${root}/job/deleteinstallbase`,
        getNextAutoNumber: `${root}/job/getnextautonumber`
    },
    part: {
        search: `${root}/part/search`,
        getAllParts: `${root}/part/getAllParts`,
        jobParts: `${root}/part/getJobParts`,
        getMyStock: `${root}/part/getMyStock`,
        saveInstallBasePart: `${root}/part/SaveInstallBasePart`,
        GetAllInstallBasePartsForFacility: `${root}/part/GetAllInstallBasePartsForFacility`
    },
    photo: {
        single: `${root}/photo`,
        search: `${root}/photo/search`,
        getJobPhotos: `${root}/photo/getJobPhotos`,
        getByProduct: `${root}/photo/getByProduct`,
        deletephoto: `${root}/photo/deletephoto?photoID={id}`,
        getByInstallBaseID: `${root}/photo/GetByInstallBase`
    },
    signature: {
        savesignature: `${root}/signature/savesignature`
    },
    update: {
        getLatest: `${root}/update/getLatest`
    },
    country: {
        getAll: `${root}/country/getAll`
    }
};

export default API;
