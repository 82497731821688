import { WithTranslation, withTranslation } from 'react-i18next';
import {
    setHoursFormValues,
    updateHoursFormValue,
    saveHour,
    setSelectedJobHourID,
    deleteSAPJobWorkOrder,
    unlinkWorkOrders
} from '../../actions/manageJobActions';

import AddHoursModalForm from './AddHoursModalForm';
import { IinitialState } from '../../reducers';
import { WithCommonModal } from '../common/WithCommonModal';
import { connect } from 'react-redux';
import { toggleAddHoursModal } from '../../actions/manageInventoryActions';
import { initialJobHour } from '../../reducers/initialState';
import { selectIsLoading } from '../../reducers/commonReducers';
import { constants } from '../../constants/constants';
import { IjobWorkOrder, IlaborRate, IWorkOrder } from '../../models';
import { jobTypesIdEnum } from '../../models-enums';
import { selectFseUserOptions } from '../../reducers/manageJobReducer';
import { filter } from 'lodash';

interface Iprops {
    show?: boolean;
    rates?: IlaborRate[];
    isUnproductiveJob?: boolean;
    colorButton: string;
    modalClass?: string;
    shouldHideDelete?: boolean;
    secondModal?: boolean;
    disabled?: boolean;
    wideModal?: boolean;
    onClose?: () => void;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const { selectedJob } = state.manageJob;
    const productiveType = 'productive';
    const { laborRates, ukCountryID, usCountryID } = constants;
    let selectedJobHour = initialJobHour;

    // Safe check job hours so we don't get gray screen
    if (selectedJob && selectedJob.jobHours) {
        const foundHours = selectedJob.jobHours.find(item => item.id === state.manageJob.selectedJobHourID);
        if (foundHours) {
            selectedJobHour = foundHours;
        }
    }
    const title = ownProps.t('jobHoursForm.addJobHoursModalTitle');
    let userLocation = state.user.countryID === usCountryID ? 'us' : state.user.countryID === ukCountryID ? 'uk' : 'ca';

    // If the user is UK, they get the BPCS hour codes. But if they have an SAP plant ID, then they should get SAP hour codes
    if (userLocation === 'uk' && state.user.sapPlant && state.user.sapPlant !== '') {
        userLocation = 'us';
    }

    const laborRatesOpts: IlaborRate[] =
        ownProps.rates !== undefined
            ? ownProps.rates
            : laborRates.filter(rate => {
                  return rate.countryType === userLocation && rate.type === productiveType;
              });
    const fseOptions = selectFseUserOptions(state);
    const currentUserID = state.user.id;
    const fseUsers = state.manageJob.fseUsers;

    let selectedWorkOrder: IWorkOrder | undefined = undefined;
    let selectedJobWorkOrder: IjobWorkOrder | undefined = undefined;
    if (
        selectedJob.jobTypeID === jobTypesIdEnum.inspection ||
        selectedJob.jobTypeID === jobTypesIdEnum.agsRebalancing
    ) {
        const woInUse = filter(state.manageJob.jobWorkOrdersByID, {
            jobID: selectedJob.id,
            isDeleted: false
        });

        if (woInUse.length > 0) {
            selectedJobWorkOrder = woInUse[0];
            selectedWorkOrder = state.workOrder.workOrdersByID[selectedJobWorkOrder.workOrderID];
        }
    }

    return {
        className: 'hours-modal',
        loading: selectIsLoading(state),
        show: ownProps.show !== undefined ? ownProps.show : state.manageInventory.showAddHoursModal,
        onClose: ownProps.onClose,
        formValues: {}, // TODO need to refactor this form to save the selectedItem in redux
        laborRates: laborRatesOpts,
        selectedJob,
        selectedJobHour,
        userLocation,
        fseOptions,
        fseUsers,
        currentUserID,
        title,
        selectedWorkOrder,
        selectedJobWorkOrder,
        isUnproductiveJob: ownProps.isUnproductiveJob
    };
};

export default withTranslation('nsJob')(
    connect(mapStateToProps, {
        toggleModal: toggleAddHoursModal,
        updateFormValue: updateHoursFormValue,
        setFormValues: setHoursFormValues,
        saveHour,
        setSelectedJobHourID,
        onHide: toggleAddHoursModal,
        deleteSAPJobWorkOrder,
        unlinkWorkOrders
    })(WithCommonModal(AddHoursModalForm))
);
