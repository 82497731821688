import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../constants/apiEndpoints';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { msalFetch } from '../components/auth/Auth-Utils';
import { IcountryBE, IfacilityContact, ThunkResult } from '../models';
import * as types from './actionTypes';
import { constants } from '../constants/constants';
import { isAPIRequestInQueue } from '../reducers/commonReducers';

export const getContactsByFacility = (facilityID: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        if (isAPIRequestInQueue(getState(), types.GET_CONTRACTS_SUCCESS)) {
            return;
        }

        const url = API.contact.getByFacility;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { id: facilityID },
            url
        };
        dispatch({
            type: types.GET_CONTRACTS_SUCCESS,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'Get Contacts for Facility' },
                    commit: {
                        type: types.SET_CONTACT_FOR_FACILITY,
                        id: facilityID
                    }
                }
            }
        });
    };
};

export const setContactForFacility = (facilityId: string, contacts: IfacilityContact[]) => ({
    type: types.SET_CONTACT_FOR_FACILITY,
    id: facilityId,
    contacts
});
