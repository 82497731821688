/*
 * Edit alert Form
 * Add and Edit alerts
 *
 */

import * as React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup, Validators } from 'react-reactive-form';
import { debounce, find } from 'lodash';

import { FormUtil } from '../common/FormUtil';
import { IjobHour, Ijob, IlaborRate, Ioption, Iuser, IWorkOrder, IjobWorkOrder } from '../../models';
import { IpartFormValues } from '../../modelsForms';
import { TFunction } from 'i18next';
import { constants } from '../../constants/constants';
import { initialJobHour, initialJob } from '../../reducers/initialState';
import { toastr } from 'react-redux-toastr';
import { AddHoursTableContainer } from './AddHoursTableContainer';
import { saveHour, setSelectedJobHourID, unlinkWorkOrders } from '../../actions/manageJobActions';
import moment from 'moment';
import { jobTypesIdEnum } from '../../models-enums';

const uuidv4 = require('uuid/v4');

interface Iprops {
    loading: boolean;
    colorButton: string;
    updateFormValue: (formValue: { [key: string]: any }) => any;
    setFormValues: (formValues: { [key: string]: any }) => any;
    t: TFunction;
    formValues: IpartFormValues;
    toggleModal: () => void;
    selectedJobHour: IjobHour;
    laborRates: IlaborRate[];
    disabled?: boolean;
    secondModal?: boolean;
    selectedJob: Ijob;
    userLocation: string;
    fseOptions: Ioption[];
    fseUsers: Iuser[];
    currentUserID: string;
    saveHour: typeof saveHour;
    setSelectedJobHourID: typeof setSelectedJobHourID;
    onClose?: () => void;
    selectedWorkOrder: IWorkOrder | undefined;
    selectedJobWorkOrder: IjobWorkOrder | undefined;
    // deleteSAPJobWorkOrder: typeof deleteSAPJobWorkOrder;
    unlinkWorkOrders: typeof unlinkWorkOrders;
    isUnproductiveJob?: boolean;
}

interface State {
    fieldConfig: FieldConfig;
    showSAPWONum: boolean;
}

class EditHoursModalForm extends React.Component<Iprops, State> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateFormValueDebounced: any;
    static defaultProps = {
        selectedJobHour: initialJobHour,
        colorButton: constants.colors[`blueButton`],
        selectedJob: initialJob
    };
    constructor(props: Iprops) {
        super(props);
        const showAddSAP =
            props.selectedJob.jobTypeID === jobTypesIdEnum.inspection ||
            props.selectedJob.jobTypeID === jobTypesIdEnum.agsRebalancing;

        this.state = {
            fieldConfig: this.buildFieldConfig(),
            showSAPWONum: showAddSAP
        };
        this.updateFormValueDebounced = debounce(this.props.updateFormValue, 300);
    }

    // since the modal is rendered in this component, this only runs when the ManageInventory view mounts
    componentDidMount() {
        this.setState({
            fieldConfig: this.buildFieldConfig()
        });
    }

    componentDidUpdate(prevProps: Iprops) {
        if (this.props.selectedJobHour !== prevProps.selectedJobHour) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.props.setFormValues({});
    }

    /*
     * formValuesToSelectedItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToSelectedItem = () => {
        const formValues = FormUtil.getValues(this.formGroup.value);
        const laborRateNum = this.props.laborRates.filter(rate => rate.description === formValues.description);
        const fseUser = this.props.fseUsers.filter(user => user.id === formValues.engineerVanID);

        return {
            ...formValues,
            engineerVanID: fseUser[0].engineerVanID ?? '',
            sapEngineerID: fseUser[0].sapEngineerID ?? '',
            number: laborRateNum[0].code
        };
    };

    buildFieldConfig = (defaultValues: IjobHour = this.props.selectedJobHour) => {
        const disabled = this.props.disabled === true;
        const convertToSelectValue = find(this.props.fseOptions, {
            value: this.props.currentUserID
        });

        const laborRatesOptions = FormUtil.convertToOptions([...this.props.laborRates]);
        const selectedLaborRateOption = find(laborRatesOptions, {
            value: defaultValues.description
        });

        const fieldConfigControls = {
            quantity: {
                render: FormUtil.NumericInput,
                meta: {
                    label: 'jobHoursForm.hoursLabel',
                    colWidth: 6,
                    name: 'quantity'
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: { value: defaultValues.quantity, disabled }
            },
            date: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'jobHoursForm.dateLabel',
                    colWidth: 6,
                    name: 'date'
                },
                formState: {
                    value: moment.isMoment(defaultValues.date) ? defaultValues.date : moment.utc(),
                    disabled
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment, FormUtil.validators.requiredWithTrim]
                }
            },
            description: {
                render: FormUtil.Select,
                meta: {
                    label: 'jobHoursForm.laborRatesLabel',
                    options: laborRatesOptions,
                    colWidth: 6,
                    type: 'text',
                    placeholder: 'common:searchPlaceholder',
                    isClearable: true
                },
                options: {
                    validators: [Validators.required]
                },
                formState: { value: selectedLaborRateOption, disabled }
            },
            engineerVanID: {
                render: FormUtil.Select,
                meta: {
                    options: this.props.fseOptions,
                    label: 'jobHoursForm.engineerVanIdLabel',
                    name: 'engineerVanID',
                    colWidth: 6
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: convertToSelectValue,
                    disabled
                }
            }
        };
        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }
        const cleanValues: any = this.formValuesToSelectedItem();

        if (cleanValues.number === 'ZAM015') {
            const quantity = Number(cleanValues.quantity);
            if (quantity > 10000) {
                toastr.error('Error', 'Aditional Expenses cannot exceed 10,000.', constants.toastrError);
                return;
            }
        } else {
            const quantity = Number(cleanValues.quantity);
            if (quantity > 99) {
                toastr.error('Error', 'Hours cannot exceed 99.', constants.toastrError);
                return;
            }
        }

        if (this.props.selectedJobHour.id.length) {
            // EDIT

            this.props.saveHour({ ...this.props.selectedJobHour, ...cleanValues }, this.props.selectedJob.id);
            this.props.setSelectedJobHourID('');
        } else {
            // NEW
            const newJobHour: IjobHour = {
                ...initialJobHour,
                id: uuidv4(),
                jobID: this.props.selectedJob.id,
                ...cleanValues
            };

            this.props.saveHour(newJobHour, this.props.selectedJob.id);
            this.props.setSelectedJobHourID('');
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    };

    handleDone = () => {
        // If onClose is defined, override close state. Otherwise, set selectedJobHourID to empty string
        if (this.props.onClose) {
            this.props.onClose();
            this.props.setSelectedJobHourID('');
            return;
        }
        this.props.toggleModal();
        this.props.setSelectedJobHourID('');
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
    };

    cleanForm = () => {
        this.formGroup.reset();
    };

    clearSelectedJobSAPWorkOrder = () => {
        if (this.props.selectedJobWorkOrder !== undefined) {
            // If we see that any hours have been saved to the api, show a confirmation dialog (this might require more complex logic later on)
            if (this.props.selectedJob.jobHours.length > 0) {
                const toastrConfirmOptions = {
                    onOk: () => {
                        // You shouldn't get this toast if selectedJobWorkOrder is undefined, but TS is complaining
                        if (this.props.selectedJobWorkOrder !== undefined) {
                            this.props.unlinkWorkOrders(this.props.t, [this.props.selectedJobWorkOrder]);
                        }
                    },
                    onCancel: () => null,
                    okText: 'Go Ahead',
                    cancelText: 'Cancel'
                };
                toastr.confirm(this.props.t('removeSAPWorkOrderWarning'), toastrConfirmOptions);
            } else {
                this.props.unlinkWorkOrders(this.props.t, [this.props.selectedJobWorkOrder]);
            }
        } else {
            toastr.error('Could not find the job work order', '', constants.toastrError);
        }
    };

    render() {
        const { t } = this.props;

        return (
            <form onSubmit={this.handleSubmit} className="clearfix beacon-form">
                <FormGenerator onUnmount={this.cleanForm} onMount={this.setForm} fieldConfig={this.state.fieldConfig} />

                {this.state.showSAPWONum && this.props.selectedWorkOrder !== undefined && (
                    <>
                        <Row>
                            <Col xs={12}>
                                <p
                                    style={{
                                        marginLeft: '20px'
                                    }}
                                >
                                    {t('selectedWorkOrder')} {this.props.selectedWorkOrder.number}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    bsStyle="default"
                                    type="button"
                                    style={{
                                        marginLeft: '30px'
                                    }}
                                    onClick={this.clearSelectedJobSAPWorkOrder}
                                >
                                    {t('clear')}
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}

                <Row>
                    <Col xs={12}>
                        <AddHoursTableContainer
                            colorButton={this.props.colorButton}
                            currentTile={constants.getTileByURL(window.location.pathname)}
                        />
                    </Col>
                </Row>

                <Col xs={12} className="form-buttons text-right">
                    <Button bsStyle="default" type="button" className="pull-left" onClick={this.handleDone}>
                        {t('common:doneButton')}
                    </Button>
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        style={{
                            marginLeft: '20px',
                            display: this.props.selectedJobHour.id.length ? '' : 'none'
                        }}
                        onClick={() => {
                            if (this.props.onClose) {
                                this.props.onClose();
                            }
                            this.props.setSelectedJobHourID('');
                        }}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button bsStyle={this.props.colorButton} type="submit">
                        {t('jobHoursForm.saveSendToOfficeButton')}
                    </Button>
                </Col>
            </form>
        );
    }
}

export default EditHoursModalForm;
