import * as types from './actionTypes';

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Ijob, IjobComment, ItableFiltersParams, ThunkResult } from '../models';

import API from '../constants/apiEndpoints';
import { IjobCommentFormValues } from '../modelsForms';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { appSyncItemNames, constants } from '../constants/constants';
import moment from 'moment';
import { msalFetch } from '../components/auth/Auth-Utils';
import { FormUtil } from '../components/common/FormUtil';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';
import { AppSyncItemStatus } from '../models-enums';

const uuidv4 = require('uuid/v4');

export function getJobComments(selectedJob: Ijob): ThunkResult<any> {
    return dispatch => {
        const url = API.jobComment.search;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { pagingType: 'none', facilityID: selectedJob.facilityID, jobID: selectedJob.id },
            url
        };

        dispatch({
            type: types.GET_JOB_COMMENTS,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'Get job comments' },
                    commit: {
                        type: types.MANAGE_JOB_COMMENTS_SUCCESS
                    }
                }
            }
        });
    };
}

export const receiveUpdatedJobComments = (jobComments: IjobComment[]): ThunkResult<any> => {
    return dispatch => {
        jobComments.forEach(jobComment => {
            dispatch({
                type: types.MANAGE_JOB_COMMENTS_UPDATE,
                jobComment
            });
        });
    };
};

export function addJobComment({ text, isDeficiency, isInternal, codeID }: IjobCommentFormValues): ThunkResult<any> {
    return (dispatch, getState) => {
        const { selectedJob } = getState().manageJob;
        const { id } = getState().user;
        const jobComment = {
            id: uuidv4(),
            text,
            isDeficiency,
            isInternal,
            jobID: selectedJob.id,
            userID: id,
            isDeleted: false,
            createDate: moment().toISOString()
        } as IjobComment;
        const axiosOptions = {
            url: `${API.POST.jobComment.add}`,
            method: 'post',
            data: jobComment
        };
        dispatch({
            type: types.MANAGE_JOB_COMMENTS_ADD,
            jobComment,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'add job comment' },
                    rollback: {
                        type: types.MANAGE_JOB_COMMENTS_UPDATE,
                        jobComment: { ...jobComment, isDeleted: true }
                    }
                }
            }
        });
    };
}

export function deleteJobComment(jobComment: IjobComment): ThunkResult<any> {
    return (dispatch, getState) => {
        const axiosOptions = {
            url: `${API.DELETE.jobComment.delete}/${jobComment.id}`,
            method: 'delete'
        };

        jobComment.isDeleted = true;

        dispatch({
            type: types.MANAGE_JOB_COMMENTS_UPDATE,
            jobComment: jobComment,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'update job comment' },
                    rollback: {
                        type: types.MANAGE_JOB_COMMENTS_UPDATE,
                        jobComment: { ...jobComment, isDeleted: false }
                    }
                }
            }
        });
    };
}

export function updateJobComment({ text, isDeficiency, isInternal, codeID }: IjobCommentFormValues): ThunkResult<any> {
    return (dispatch, getState) => {
        const originalComment = getState().manageJobComment.selectedJobComment;
        const data: IjobComment = {
            ...originalComment,
            text,
            isDeficiency,
            isInternal,
            codeID: isDeficiency && codeID && codeID.value ? codeID.value : null
        };
        const axiosOptions = {
            url: `${API.jobComment.single}/update/${originalComment.id}`,
            method: 'put',
            data
        };
        dispatch({
            type: types.MANAGE_JOB_COMMENTS_UPDATE,
            jobComment: data,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'update job comment' },
                    rollback: {
                        type: types.MANAGE_JOB_COMMENTS_UPDATE,
                        jobComment: originalComment
                    }
                }
            }
        });
    };
}

export function getJobCommentCodes(showError: boolean = true): ThunkResult<any> {
    return dispatch => {
        dispatch({
            type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
            name: appSyncItemNames.JobCommentCodes,
            status: AppSyncItemStatus.inProgress
        });
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { pagingType: 'none' }
        };

        const url = API.code.getAll;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.JobCommentCodes,
                        status: AppSyncItemStatus.failed
                    });
                    throw new Error('unable to get job comment codes');
                } else {
                    const codes = data.data;
                    dispatch({
                        type: types.MANAGE_JOB_COMMENT_CODES_SUCCESS,
                        codes
                    });

                    dispatch({ type: types.REMOVE_INITIAL_APP_SYNC_ITEM, name: appSyncItemNames.JobCommentCodes });
                }
            })
            .catch((error: any) => {
                dispatch({ type: types.MANAGE_JOB_COMMENT_CODES_FAILED });
                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.JobCommentCodes,
                    status: AppSyncItemStatus.failed
                });
                if (showError) {
                    constants.handleError(error, 'get job comment codes');
                }

                console.error('[getJobCommentCodes]:', error);
                throw error;
            });
    };
}

export const toggleEditJobCommentModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_JOB_COMMENT
});

export const toggleModalVerificationSignaturePad = () => ({
    type: types.TOGGLE_MODAL_VERIFICATION_SIGNATURE_PAD
});

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_JOB_COMMENTS,
    filters
});

export const setSelectedJobComment = (jobComment: IjobComment) => ({
    type: types.MANAGE_JOB_COMMENTS_SELECT,
    jobComment
});

export function submitSignature(
    jobID: string,
    HospitalSignature: Blob,
    PrintedHospitalName: string,
    t: TFunction
): ThunkResult<any> {
    //  This potential re-usable function would be a good use-case for hooks.
    return (dispatch, getState) => {
        if (!getState().offline.online) {
            toastr.warning(
                t('toastMessage:offline'),
                t('toastMessage:completeJobOnlineWarning'),
                constants.toastrWarning
            );
            return;
        }
        dispatch(beginAjaxCall());
        const headers = { 'content-type': 'multipart/form-data' };
        const formData = FormUtil.toFormData({
            jobID,
            HospitalSignature,
            PrintedHospitalName
        });
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: formData,
            headers
        };

        const url = API.POST.job.saveSignature;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch(endAjaxCall());
                const job = data.data;
                dispatch(toggleModalVerificationSignaturePad());
                dispatch({ type: types.JOB_UPDATE, job });
                toastr.success('Success', 'Saved Signature', constants.toastrSuccess);
            })
            .catch((error: any) => {
                dispatch(endAjaxCall());
                constants.handleError(error, 'save signature');
                console.error('[submitSignature]:', error);
            });
    };
}
