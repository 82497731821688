import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { IinitialState } from '../../../reducers';
import Select from 'react-select';
import {
    IdamageCode,
    IinstallBase,
    Ijob,
    ImainFailureCodes,
    Ioption,
    IreasonFailureCodes,
    IsubFailureCodes
} from '../../../models';
import { constants } from '../../../constants/constants';
import { saveDamageCode } from '../../../actions/measurementPointResultsActions';
import { getDamageCodes } from '../../../reducers/manageInventoryReducer';
import { forEach } from 'lodash';
import { jobStatusEnum } from '../../../models-enums';

interface Iprops {
    installBase: IinstallBase;
    selectedJob: Ijob;
}

/*
 *   Damage Codes Flow:
 *   1. Object Part should be given to use (this is currently todo, so it's hardcoded for now)
 *   2. Use the Object Part to filter the Main Failure Codes, the user must select one
 *   3. Use the Main Failure Code to filter the Sub Failure Codes, the user must select one
 */

interface Istate {
    objectPartGroup: string;

    mainFailureCodes: Ioption[];
    selectedMainFailureCode: Ioption;

    subFailureCodes: Ioption[];
    selectedSubFailureCode: Ioption;

    reasonFailureCodes: Ioption[];
    selectedReasonFailureCode: Ioption;

    failedPartNumber: string;

    rootCause: string;
}

const SAPDamageCodes: React.FC<Iprops & WithTranslation> = props => {
    const disaptch = useDispatch();
    const damageCodes = useSelector(getDamageCodes);

    const [state, setState] = React.useState<Istate>({
        objectPartGroup: props.installBase.objectPartGroup,

        mainFailureCodes: [],
        selectedMainFailureCode: { label: '', value: '' } as Ioption,

        subFailureCodes: [],
        selectedSubFailureCode: { label: '', value: '' } as Ioption,

        reasonFailureCodes: [],
        selectedReasonFailureCode: { label: '', value: '' } as Ioption,

        failedPartNumber: '',
        rootCause: ''
    });

    const canEditDamageCodes = () => {
        if (props.selectedJob.status === jobStatusEnum.new) {
            return true;
        }

        return false;
    };

    // Convert array of main failure codes to array of options filtered by object part group (selectedPGC)
    const getMainFailureCodes = (selectedPGC: string) => {
        let mainFailureCodes: Ioption[] = [];
        let mainFailureCodeLabel = '';
        let subFailureCodeLabel: string | undefined = '';
        let reasonFailureCodeLabel: string | undefined = '';

        constants.mainFailureCodes.forEach((mainFailureCode: ImainFailureCodes) => {
            if (mainFailureCode.PGC === selectedPGC) {
                mainFailureCodeLabel = mainFailureCode.MainCodeDescription;

                mainFailureCodes.push({
                    label: mainFailureCode.MainCodeDescription,
                    value: mainFailureCode.MainCode
                } as Ioption);
            }
        });

        let damageCode: IdamageCode | undefined;

        // Check if there are any damage codes already saved for this install base and this job
        forEach(Object.keys(damageCodes), (key: string, index: number) => {
            if (key === props.installBase.id) {
                if (Object.values(damageCodes)[index].jobID === props.selectedJob.id) {
                    damageCode = Object.values(damageCodes)[index];
                }
            }
        });

        if (damageCode !== undefined) {
            // The codes in the JSON files are actually numbers, so just compare with == instead of ===
            // TODO either fix the json file, or update the model to have the codes as numbers
            subFailureCodeLabel = constants.subFailureCodes.find(x => x.SubFailureCode === damageCode?.damageCodeText)
                ?.SubCodeDescription;

            reasonFailureCodeLabel = constants.reasonFailureCodes.find(
                x => x.ReasonFailureCode === damageCode?.causeCode
            )?.ReasonFailureCodeDescription;

            setState({
                ...state,
                objectPartGroup: selectedPGC,
                mainFailureCodes: mainFailureCodes,
                //Populate damage code form with saved values
                selectedMainFailureCode: { label: mainFailureCodeLabel, value: damageCode.objectPartCode },
                selectedSubFailureCode: {
                    label: subFailureCodeLabel ? subFailureCodeLabel : '',
                    value: damageCode.damageCodeText
                },
                selectedReasonFailureCode: {
                    label: reasonFailureCodeLabel ? reasonFailureCodeLabel : '',
                    value: damageCode.causeCode
                },
                failedPartNumber: damageCode.failedPartNumber,
                rootCause: damageCode.rootCause
            });
        } else {
            setState({ ...state, objectPartGroup: selectedPGC, mainFailureCodes: mainFailureCodes });
        }
    };

    const getSubFailureCodes = (value: string, label: string) => {
        let subFailureCodes: Ioption[] = [];

        constants.subFailureCodes.forEach((subFailureCode: IsubFailureCodes) => {
            if (subFailureCode.MainFailureCode === value) {
                subFailureCodes.push({
                    label: subFailureCode.SubCodeDescription,
                    value: subFailureCode.SubFailureCode
                } as Ioption);
            }
        });

        setState({
            ...state,
            selectedMainFailureCode: { label: label, value: value },
            subFailureCodes: subFailureCodes,
            selectedSubFailureCode: { label: '', value: '' } as Ioption
        });
    };

    const getReasonFailureCodes = (value: string, label: string) => {
        let reasonFailureCodes: Ioption[] = [];
        constants.reasonFailureCodes.forEach((reasonFailureCode: IreasonFailureCodes) => {
            if (reasonFailureCode.SubFailureCode === value) {
                reasonFailureCodes.push({
                    label: reasonFailureCode.ReasonFailureCodeDescription,
                    value: reasonFailureCode.ReasonFailureCode
                } as Ioption);
            }
        });

        setState({
            ...state,
            reasonFailureCodes: reasonFailureCodes,
            selectedSubFailureCode: { label: label, value: value }
        });
    };

    React.useEffect(() => {
        getMainFailureCodes(props.installBase.objectPartGroup);
    }, [props.installBase.id]);

    React.useEffect(() => {
        const damageCodes: IdamageCode = {
            objectPartGroup: state.objectPartGroup,
            objectPartCode: state.selectedMainFailureCode.value,
            damageCodeText: `${state.selectedSubFailureCode.value}`,
            causeCode: `${state.selectedReasonFailureCode.value}`,
            failedPartNumber: state.failedPartNumber,
            rootCause: state.rootCause,
            jobID: props.selectedJob.id,
            installBaseID: props.installBase.id
        };

        disaptch(saveDamageCode(damageCodes, props.installBase.id));
    }, [
        state.selectedReasonFailureCode,
        state.selectedMainFailureCode,
        state.selectedSubFailureCode,
        state.failedPartNumber,
        state.rootCause
    ]);

    const objectPartCodeChange = (value: any) => {
        if (value) {
            getSubFailureCodes(value.value, value.label);
        }
    };

    const subFailurePartCodeChange = (value: any) => {
        if (value) {
            getReasonFailureCodes(value.value, value.label);
        }
    };

    const reasonCodeChange = (value: any) => {
        if (value) {
            setState({ ...state, selectedReasonFailureCode: { label: value.label, value: value.value } });
        }
    };

    const failedPartNumberChanged = (value: any) => {
        if (value) {
            setState({ ...state, failedPartNumber: value.target.value });
        }
    };

    const rootCauseValueChanged = (value: any) => {
        if (value) {
            setState({ ...state, rootCause: value.target.value });
        }
    };

    return (
        <div className="damage-code-form">
            <label htmlFor="objectPartGroup">Object Part Group</label>
            <input
                value={state.objectPartGroup}
                disabled={state.objectPartGroup === '' ? false : true}
                className="form-control"
                name="objectPartGroup"
            />

            <label htmlFor="object-part-code">Object Part Code</label>
            <Select
                className="beacon-select"
                name="object-part-code"
                onChange={objectPartCodeChange}
                options={state.mainFailureCodes}
                value={state.selectedMainFailureCode}
                isDisabled={canEditDamageCodes() === false}
            />

            <label htmlFor="damage-code">Damage Code</label>
            <Select
                className="beacon-select"
                name="damage-code"
                value={state.selectedSubFailureCode}
                onChange={subFailurePartCodeChange}
                isDisabled={
                    canEditDamageCodes() === false ? true : state.selectedMainFailureCode.value === '' ? true : false
                }
                options={state.subFailureCodes}
            />

            <label htmlFor="cause-code">Cause Code</label>
            <Select
                className="beacon-select"
                name="cause-code"
                isDisabled={
                    canEditDamageCodes() === false ? true : state.selectedSubFailureCode.value === '' ? true : false
                }
                value={state.selectedReasonFailureCode}
                options={state.reasonFailureCodes}
                onChange={reasonCodeChange}
            />

            <label htmlFor="failed-part-number">Failed Part Number</label>
            <input
                value={state.failedPartNumber}
                onChange={failedPartNumberChanged}
                className="form-control"
                name="failed-part-number"
                disabled={canEditDamageCodes() === false}
                maxLength={40}
            />

            <label htmlFor="failed-part-number">Root Cause</label>
            <input
                value={state.rootCause}
                onChange={rootCauseValueChanged}
                className="form-control"
                name="root-cause-number"
                disabled={canEditDamageCodes() === false}
                maxLength={40}
            />
        </div>
    );
};

export default withTranslation('manageInventory')(
    connect(
        (state: IinitialState) => {
            const {} = state;
            return {
                // variables
            };
        },
        {
            // functions
        }
    )(SAPDamageCodes)
);
