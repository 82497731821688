/*
 * EditJobForm
 */

import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup, GroupProps } from 'react-reactive-form';
import { IWorkOrder, Ifacility, Ijob, Ioption, IsapJobMapping } from '../../models';
import { debounce, find } from 'lodash';
import {
    deleteJob,
    saveJob,
    toggleSearchFacilityModal,
    updateJob,
    addWorkOrdersToJob,
    setSelectedJob,
    openJob
} from '../../actions/manageJobActions';
import { jobTypesIdEnum, jobTypesIdEnumInverse } from '../../models-enums';
import { FormUtil } from '../common/FormUtil';
import { IeditJobFormValues } from '../../modelsForms';
import { TFunction } from 'i18next';
import { constants, jobTypeOptions } from '../../constants/constants';
import { initialJob } from '../../reducers/initialState';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import CommonMobileModal, { MobileButtonGroup } from '../common/CommonMobileModal';
import CommonModal from '../common/CommonModal';
import { addCommissioningInstallBasesToJob } from '../../actions/manageInventoryActions';
import { toggleConfirmSelectJobModal } from '../../actions/workOrderActions';
const uuidv4 = require('uuid/v4');

interface Iprops {
    selectedItem: Ijob;
    userID: string;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    selectedFacilityID?: string;
    getFacilitiesByCustomer: (value: string) => Promise<void>;
    facilityOptions: Ioption[];
    updateJob: typeof updateJob;
    saveJob: typeof saveJob;
    toggleSearchFacilityModal: typeof toggleSearchFacilityModal;
    toggleModal: () => void;
    updateFormValue: (formValue: { [key: string]: any }) => void;
    setFormValues: (formValues: { [key: string]: any }) => void;
    formValues: Partial<IeditJobFormValues>;
    facilitiesByID: { [key: string]: Ifacility };
    searchedFacilities: { [key: string]: Ifacility };
    deleteJob: typeof deleteJob;
    online: boolean;
    isMobile: boolean;
    title: string;
    show: boolean;
    className?: string;
    secondModal?: boolean;
    selectedFacilityOption: string;
    nextJobNumber: string;
    fromSAPWO?: { facility: string; jobTypeID?: jobTypesIdEnum };
    sapWOs?: IWorkOrder[];
    addWorkOrdersToJob: typeof addWorkOrdersToJob;
    addCommissioningInstallBasesToJob: typeof addCommissioningInstallBasesToJob;
    setSelectedJob: typeof setSelectedJob;
    history?: any; //todo
    toggleConfirmSelectJobModal: typeof toggleConfirmSelectJobModal;
    openJob: typeof openJob;
    sapJobMappings: { [key: string]: IsapJobMapping };
}

interface Istate {
    fieldConfig: FieldConfig;
}

class EditJobForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateFormValueDebounced: any;
    static defaultProps = {
        selectedItem: initialJob
    };

    constructor(props: Iprops) {
        super(props);
        this.updateFormValueDebounced = debounce(this.props.updateFormValue, constants.formDebounceTime);
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
    }

    componentDidMount() {
        this.setState({
            fieldConfig: this.buildFieldConfig(this.itemToFormValues())
        });
    }

    componentDidUpdate(prevProps: Iprops) {
        if (
            prevProps.fromSAPWO?.facility !== this.props.fromSAPWO?.facility ||
            prevProps.fromSAPWO?.jobTypeID !== this.props.fromSAPWO?.jobTypeID
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig(this.itemToFormValues()) });
        }
        if (prevProps.selectedFacilityID !== this.props.selectedFacilityID) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }

        if (JSON.stringify(prevProps.facilityOptions) !== JSON.stringify(this.props.facilityOptions)) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }

    componentWillUnmount() {
        this.props.setFormValues({});
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (): IeditJobFormValues => {
        let { facilityID, jobTypeID, startDate, endDate, jobNumber } = this.props.selectedItem;

        // Changed from hardcoded
        if (!startDate) {
            startDate = moment.utc().format('DD-MMM-YY');
        }

        if (!endDate) {
            endDate = moment.utc().format('DD-MMM-YY');
        }

        if (this.props.selectedFacilityID) {
            facilityID = this.props.selectedFacilityID;
        }

        const loadJobNumber = jobNumber || this.props.nextJobNumber;

        const selectedFacility = FormUtil.convertToSingleOption(
            find(this.props.searchedFacilities, facility => facility.id === facilityID)
        ) || { value: '', label: '' };

        const selectedJobType = {
            value: jobTypeID,
            label: this.props.t(jobTypesIdEnumInverse[jobTypeID as keyof typeof jobTypesIdEnumInverse])
        } || { value: '', label: '' };

        return {
            ...initialJob,
            facilityID: selectedFacility,
            jobTypeID: selectedJobType,
            jobNumber: loadJobNumber,
            startDate: moment.utc().format('DD-MMM-YY'), // on mobile we only create new for today
            endDate: moment.utc().format('DD-MMM-YY'), // on mobile we only create new for today
            userID: this.props.userID // on mobile we assing this to the current FSE user
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToItem = (): Ijob => {
        const formValues = this.formGroup.value;
        const startDate = moment.isMoment(formValues.startDate)
            ? formValues.startDate.format()
            : moment.utc(formValues.startDate).format();
        const endDate = moment.isMoment(formValues.endDate)
            ? formValues.endDate.format()
            : moment.utc(formValues.endDate).format();

        const processedFormValues = FormUtil.getValues(this.formGroup.value);
        let jobTypeID = this.props.selectedItem.jobTypeID;

        const index = Object.keys(processedFormValues).indexOf('jobTypeID');
        // This is wack, but if we are coming from unassigned sap work order screen, and the work order has a suggested job type
        // We lock the job type to that suggested job type, and for some reason jobTypeID is not in the processedFormValues
        // So I'm looking in the fieldConfig instead, this should be looked at closer so we're not checking 2 places
        if (index > -1) {
            let jobTypeIDValue = Object.values(processedFormValues)[index];
            if (Array.isArray(jobTypeIDValue)) {
                jobTypeIDValue = jobTypeIDValue[0];
            }

            jobTypeID = jobTypeIDValue as jobTypesIdEnum;
        } else {
            const JobTypeIndex = Object.keys(this.state.fieldConfig.controls).indexOf('jobTypeID');
            if (this.state.fieldConfig.controls && JobTypeIndex > -1) {
                const jobTypeObject = Object.values(this.state.fieldConfig.controls)[JobTypeIndex];
                if (
                    jobTypeObject &&
                    jobTypeObject.formState &&
                    jobTypeObject.formState.value &&
                    jobTypeObject.formState.value.length > 0
                ) {
                    jobTypeID = jobTypeObject.formState.value[0].value;
                }
            }
        }

        return {
            ...this.props.selectedItem,
            ...processedFormValues,
            startDate,
            endDate,
            assignedUserID: this.props.userID,
            jobTypeID,
            id: ''
        };
    };

    buildFieldConfig = (defaultValues: Partial<IeditJobFormValues> = this.props.formValues) => {
        const { facilityOptions } = this.props;
        const disabled = false;
        let { startDate, endDate } = defaultValues;

        // if the dueDate is not a moment and there is no id, we are creating an untouched new work order and default to a month from today
        if (typeof defaultValues.endDate === 'undefined') {
            endDate = moment.utc(defaultValues.endDate).add(1, 'month');
        }

        const facilityValue = this.props.fromSAPWO?.facility
            ? this.props.fromSAPWO.facility
            : this.props.selectedFacilityID;

        const selectedFacilityOption: Ioption | undefined = facilityOptions.find(
            (facilityOption: Ioption) => facilityOption.value === facilityValue
        );

        const jobTypeOptionsTranslated = jobTypeOptions.map(option => {
            return {
                ...option,
                label: this.props.t(`nsJob:${option.label}`)
            };
        });

        let sapJobTypeOptions: Ioption[] | undefined;
        // There are cases based on the SAPBusinessIndicator where we are only showing certain job types, and they also want it to default to a certain one.
        let defaultSAPJobType: Ioption | undefined = undefined;

        // Did we come from the unassigned sap work orders screen?
        if (this.props.fromSAPWO) {
            // Check if the selected work order(s) have a suggested job type, if so, then that's the only job type they are allowed to use
            if (this.props.fromSAPWO.jobTypeID !== undefined && this.props.fromSAPWO.jobTypeID !== null) {
                sapJobTypeOptions = jobTypeOptionsTranslated.filter(t => t.value === this.props.fromSAPWO?.jobTypeID);
            } else {
                // There was no suggested job type set, so we need to check the business indicator on the work order
                const sapBusinessIndicator = this.props.sapWOs?.[0]?.sapBusinessIndicator;
                const product = this.props.sapWOs?.[0]?.product;
                const sapPlant = this.props.sapWOs?.[0]?.sapPlant;

                if (
                    sapBusinessIndicator !== undefined &&
                    sapBusinessIndicator !== null &&
                    sapPlant !== undefined &&
                    sapPlant !== null &&
                    product !== undefined &&
                    product !== null
                ) {
                    const forVirtualProduct =
                        product && product.subcategoryID === constants.virtualProductSubcategoryID ? true : false;
                    let sapJobTypes: IsapJobMapping[] = [];
                    const sapMaterialNumberExist =
                        Object.values(this.props.sapJobMappings).filter(
                            x =>
                                x.sapBusinessIndicator === sapBusinessIndicator &&
                                x.serviceCenter === sapPlant &&
                                x.isVirtual === forVirtualProduct &&
                                x.sapMaterialNumber === product.sapMaterialNumber
                        ).length > 0;

                    if (sapMaterialNumberExist) {
                        sapJobTypes = Object.values(this.props.sapJobMappings).filter(
                            x =>
                                x.sapBusinessIndicator === sapBusinessIndicator &&
                                x.serviceCenter === sapPlant &&
                                x.isVirtual === forVirtualProduct &&
                                x.sapMaterialNumber === product.sapMaterialNumber
                        );
                    } else {
                        sapJobTypes = Object.values(this.props.sapJobMappings).filter(
                            x =>
                                x.sapBusinessIndicator === sapBusinessIndicator &&
                                x.serviceCenter === sapPlant &&
                                x.isVirtual === forVirtualProduct
                        );
                    }

                    const jobTypeIDs = sapJobTypes.map(x => x.jobTypeID);

                    // Filter the job types to show in the drop down
                    sapJobTypeOptions = jobTypeOptionsTranslated.filter(t => jobTypeIDs.includes(t.value));

                    // Check if we need to default to a certain job type
                    sapJobTypes.forEach(x => {
                        if (x.isDefault) {
                            defaultSAPJobType = jobTypeOptionsTranslated.find(t => t.value === x.jobTypeID);
                        }
                    });
                }
            }
        }

        // If coming from SAP, check if we have a suggested job type from the work order, or if we are going off a business indicator
        // this.props.fromSAPWO.jobTypeID will be set if it's a suggested job type, it will be undefined if it's a business indicator
        let selectedJobType = this.props.fromSAPWO
            ? sapJobTypeOptions?.filter(x => x.value === this.props.fromSAPWO?.jobTypeID)
            : undefined;

        // Overrid the starting value if we have a defaultSAPJobType
        if (defaultSAPJobType !== undefined) {
            selectedJobType = [defaultSAPJobType];
        }

        // Field config to configure form
        const fieldConfigControls = {
            facilityID: {
                render: FormUtil.Select,
                meta: {
                    options: facilityOptions,
                    label: 'common:facility',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'facility'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value:
                        selectedFacilityOption && selectedFacilityOption.value
                            ? selectedFacilityOption
                            : defaultValues.facilityID,
                    disabled
                }
            },
            jobNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'nsJob:jobNumber',
                    colWidth: 12,
                    type: 'input',
                    name: 'jobnumber',
                    required: false
                },
                formState: { value: this.props.nextJobNumber, disabled }
            },
            jobTypeID: {
                render: FormUtil.Select,
                meta: {
                    options: this.props.fromSAPWO ? sapJobTypeOptions : jobTypeOptionsTranslated,
                    label: 'nsJob:jobForm.type',
                    colWidth: 12,
                    placeholder: 'nsJob:jobForm.typeSearchPlaceholder',
                    name: 'job-type',
                    shouldTranslate: true
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: selectedJobType,
                    disabled: this.props.fromSAPWO?.jobTypeID ? true : false
                }
            },
            startDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'nsJob:startDate',
                    colWidth: 12,
                    showTime: false,
                    name: 'start-date',
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim, FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: startDate ? startDate : moment(),
                    disabled
                }
            },
            endDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'nsJob:endDate',
                    colWidth: 12,
                    showTime: false,
                    name: 'end-date',
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim, FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: endDate,
                    disabled
                }
            }
        } as { [key: string]: GroupProps };
        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };
        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup.get(key).valueChanges.subscribe((value: any) => {
                    this.onValueChanges(value, key);
                });
            }
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'startDate': {
                const startDateMoment = moment.isMoment(value) ? value : moment(value);
                this.updateFormValueDebounced({
                    [key]: startDateMoment.toISOString()
                });
                this.checkIfStartDateBeforeEndDate({ [key]: value });
                break;
            }
            case 'endDate': {
                const endDateMoment = moment.isMoment(value) ? value : moment(value);
                this.updateFormValueDebounced({
                    [key]: endDateMoment.toISOString()
                });
                this.checkIfStartDateBeforeEndDate({ [key]: value });
                break;
            }
            case 'customerID':
                this.updateFormValueDebounced({
                    [key]: value,
                    facilityID: null
                });
                if (value && value.value) {
                    this.props.getFacilitiesByCustomer(value.value);
                }
                break;
            default:
                this.updateFormValueDebounced({ [key]: value });
                break;
        }
    };

    /*
     * Check if the date is in the past or if the start is before the end date
     */
    checkIfStartDateBeforeEndDate = ({ startDate, endDate }: any) => {
        if (startDate && moment.isMoment(startDate) && this.formGroup.value.endDate) {
            if (startDate.isAfter(this.formGroup.value.endDate)) {
                toastr.warning(this.props.t('jobForm.startDateWarning'), '', constants.toastrWarning);
                const startDateControl = this.formGroup.get('startDate');
                startDateControl.setErrors({ beforeStart: true });
            } else if (startDate.isBefore(moment(), 'day')) {
                toastr.warning(
                    this.props.t('common:warning'),
                    this.props.t('jobForm.pastDateWarning'),
                    constants.toastrWarning
                );
            } else {
                const startDateControl = this.formGroup.get('startDate');
                startDateControl.setErrors(null);
                const endDateControl = this.formGroup.get('endDate');
                endDateControl.setErrors(null);
            }
        } else if (endDate && moment.isMoment(endDate)) {
            if (endDate.isBefore(this.formGroup.value.startDate)) {
                toastr.warning(
                    this.props.t('common:warning'),
                    this.props.t('jobForm.startDateWarning'),
                    constants.toastrWarning
                );
                const endDateControl = this.formGroup.get('endDate');
                endDateControl.setErrors({ beforeStart: true });
            } else if (endDate.isBefore(moment(), 'day')) {
                toastr.warning(
                    this.props.t('common:warning'),
                    this.props.t('jobForm.pastDateWarning'),
                    constants.toastrWarning
                );
            } else {
                const endDateControl = this.formGroup.get('endDate');
                endDateControl.setErrors(null);
                const startDateControl = this.formGroup.get('startDate');
                startDateControl.setErrors(null);
            }
        } else {
            console.error('[checkIfStartDateBeforeEndDate]: missing start and end date');
        }
    };

    handleCancel = () => {
        this.props.toggleModal();
        this.props.setFormValues({});

        if (this.props.isMobile) this.props.toggleSearchFacilityModal();
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }

        const userJobs = this.formGroup.value.userJobs ? this.formGroup.value.userJobs.map((u: any) => u.value) : [];

        // on mobile we only save new jobs
        // As of Verification Report ^ this is not a given.
        const job = this.formValuesToItem();

        if (
            (job.jobTypeID === jobTypesIdEnum.inspection ||
                job.jobTypeID === jobTypesIdEnum.agsRebalancing ||
                job.jobTypeID === jobTypesIdEnum.audit ||
                job.jobTypeID === jobTypesIdEnum.verification) &&
            this.props.fromSAPWO &&
            this.props.sapWOs !== undefined &&
            this.props.sapWOs.length > 1
        ) {
            toastr.error(this.props.t('sapWOInspectionJobError'), '', constants.toastrError);
            return;
        }

        const id = uuidv4();
        this.props.saveJob(job, this.props.t, userJobs, id);

        if (this.props.fromSAPWO && this.props.sapWOs !== undefined) {
            // We need to wait for saveJob to finish before we can add anything to the job, so crappy timeout it is
            setTimeout(() => {
                const workOrderIds = this.props.sapWOs
                    ? this.props.sapWOs.map(wo => {
                          return wo.id;
                      })
                    : [];
                // Assign the WorkOrders to the Job, which creates JobWorkOrders
                this.props.addWorkOrdersToJob(workOrderIds, id, this.props.t, true);

                this.props.setSelectedJob({ ...job, id });
                this.props.toggleConfirmSelectJobModal();

                if (this.props.history) {
                    this.props.history.push('/devices');
                }
            }, 500);
            return;
        }
        // Reset the Job Form Values to empty so the next time this form is opened it is empty
        this.props.setFormValues({});
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    render() {
        const { t, isMobile, title, show } = this.props;
        const formClassName = `clearfix job-form beacon-form ${this.props.colorButton}`;

        if (isMobile) {
            return (
                <form onSubmit={this.handleSubmit} className={formClassName}>
                    <CommonMobileModal
                        className="job-edit"
                        show={show}
                        onHide={() => {
                            this.props.toggleModal();
                            this.props.setFormValues({});
                        }}
                        title={title}
                        container={document.getElementById('two-pane-layout')}
                        footer={
                            <MobileButtonGroup>
                                <Button bsStyle="default" type="button" onClick={this.handleCancel}>
                                    {t('cancel')}
                                </Button>
                                <Button bsStyle={this.props.colorButton} type="submit" disabled={this.props.loading}>
                                    {t('save')}
                                </Button>
                            </MobileButtonGroup>
                        }
                    >
                        <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                        <Col xs={12} className="form-buttons text-right"></Col>
                    </CommonMobileModal>
                </form>
            );
        }

        return (
            <CommonModal
                show={show}
                onHide={() => {
                    this.props.toggleModal();
                    this.props.setFormValues({});
                }}
                className={`${this.props.className}`}
                title={title}
            >
                <form onSubmit={this.handleSubmit} className={formClassName}>
                    <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                    <Col xs={12} className="form-buttons text-right">
                        <Button bsStyle="default" type="button" className="pull-left" onClick={this.handleCancel}>
                            {t('cancel')}
                        </Button>
                        <Button bsStyle="primary" type="submit" disabled={this.props.loading}>
                            {t('save')}
                        </Button>
                    </Col>
                </form>
            </CommonModal>
        );
    }
}
export default EditJobForm;
