/*
 * The Search Inventory Table Container
 */

import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getJobDependenciesHelper } from '../../actions/commonActions';
import { clearTableFilter, setFilterConfig, setTableFilter } from '../../actions/manageInventoryActions';
import { setSelectedCart } from '../../actions/partsActions';
import { toggleConfirmSelectJobModal } from '../../actions/workOrderActions';
import { IinstallBasePopulated } from '../../models';
import { IinitialState } from '../../reducers';
import { selectIsLoading, selectOfflineStatusForJob } from '../../reducers/commonReducers';
import { initialFacility, initialJob } from '../../reducers/initialState';
import { getIsWorkOrderTypeJob } from '../../reducers/manageJobReducer';
import SearchInventoryTableForm from './SearchInventoryTableForm';

interface Iprops extends RouteComponentProps<{}> {
    colorButton: string;
    installBasesPopulated: IinstallBasePopulated[]; // pass as a prop to improve memoization
    resetSelection: () => void;
}
export const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const selectedJob = state.manageJob.selectedJob || initialJob;
    const selectedFacility = state.facilities.facilitiesByID[selectedJob.facilityID] || initialFacility;
    const isWorkOrderTypeJob = getIsWorkOrderTypeJob(state);
    // TODO Refactor into selector
    const isAddWorkOrderMode =
        ownProps.location.pathname === '/commission' ||
        ownProps.location.pathname === '/repair' ||
        ownProps.location.pathname === '/warranty' ||
        ownProps.location.pathname === '/sap' ||
        ownProps.location.pathname === '/unassigned-sap-workorders';
    const isJobDownloaded = selectOfflineStatusForJob(state, {
        job: selectedJob
    });
    return {
        loading: selectIsLoading(state),
        handleSubmit: () => {
            return;
        },
        buildings: selectedFacility.buildings,
        selectedJob,
        tableFilters: state.manageInventory.tableFilters,
        productInfo: state.productInfo,
        showSearchButton: false,
        isWorkOrderTypeJob,
        isAddWorkOrderMode,
        isJobDownloaded,
        hasSelection: state.manageInventory.selection.length > 0
    };
};

export const SearchInventoryTableContainer = withTranslation('manageInventory')(
    connect(mapStateToProps, {
        setTableFilter,
        clearTableFilter,
        startAddWorkOrders: toggleConfirmSelectJobModal,
        getJobDependenciesHelper,
        setSelectedCart,
        setFilterConfig
    })(SearchInventoryTableForm)
);
