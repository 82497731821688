import * as types from '../actions/actionTypes';
import { Iuser } from '../models';
import initialState from './initialState';
import { map, pickBy } from 'lodash';

export default function user(state: Iuser = initialState.user, action: any) {
    switch (action.type) {
        case types.USER_LOGIN_SUCCESS: {
            const securityFunctions = map(action.user.securityFunctions, securityF => {
                return securityF.toUpperCase();
            });
            const cleanUser = pickBy(action.user, (property, key) => property !== null); // get rid of null values
            return {
                ...state,
                ...cleanUser,
                securityFunctions,
                isAuthenticated: true
            } as Iuser;
        }
        case types.AAD_LOGIN:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
                appVersion: process.env.REACT_APP_VERSION
            } as Iuser;
        case types.USER_LOGOUT_SESSION_ONLY:
            return { ...state, isAuthenticated: false, isLoggingOut: true };
        case types.USER_UPDATE_PROFILE_SUCCESS: {
            const pickedUser = pickBy(action.user, (property, key) => property !== null); // get rid of null values
            const securityFs = map(action.user.securityFunctions, securityF => {
                return securityF.toUpperCase();
            });
            return {
                ...state,
                ...pickedUser,
                securityFunctions: securityFs
            } as Iuser;
        }
        case types.SAVE_NON_PRODUCTIVE_HOUR: {
            let currentNPH = state.nonProductiveHours ? state.nonProductiveHours : [];

            return {
                ...state,
                nonProductiveHours: [...currentNPH, action.nonProductiveHour]
            };
        }
        case types.UPDATE_NON_PRODUCTIVE_HOURS: {
            return {
                ...state,
                nonProductiveHours: action.nonProductiveHours
            };
        }
        case types.SAVE_HOURS_CHECKED: {
            return {
                ...state,
                showRecordHours: action.show
            };
        }
        case types.RESET_USER_LOGOUT: {
            return {
                ...state,
                isLoggingOut: false
            };
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.user;
        default:
            return state;
    }
}
