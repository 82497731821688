import * as localForage from 'localforage';
import { filter, forEach, isEmpty } from 'lodash';
import { IinstallBase, Iproduct } from '../models';
import { store } from '../index';
import * as types from '../actions/actionTypes';
import moment from 'moment';

export class OfflineProductsProvider {
    private allProducts: { [key: string]: Iproduct } = {};
    private initialized = false;

    initAllProducts = () => {
        return localForage.getItem('med-gas-mobile_all-products').then(products => {
            if (!products) {
                document.dispatchEvent(new CustomEvent('missingPersistedProducts'));
                return Promise.reject({
                    message: 'Missing products.  Re-downloading products...'
                });
            }
            this.allProducts = products as { [key: string]: Iproduct };
            this.initialized = isEmpty(products) === false;
            return Promise.resolve(products as { [key: string]: Iproduct });
        });
    };
    public addNewProductsFromInstallBases = (installBases: IinstallBase[]) => {
        this.getAllProducts().then(products => {
            let allProductIDs: string[] = [];
            forEach(installBases, install => {
                if (allProductIDs.indexOf(install.productID) === -1) {
                    allProductIDs = [...allProductIDs, install.productID];
                }
            });
            const filteredProducts = filter(products, product => allProductIDs.indexOf(product.id) !== -1);

            if (filteredProducts.length) {
                store.dispatch({
                    type: types.GET_ALL_PRODUCTS_SUCCESS,
                    payload: filteredProducts,
                    updateDate: moment().unix()
                });
            }
        });
    };
    public setAllProducts = (products: { [key: string]: Iproduct }) => {
        this.allProducts = products;
        return localForage.setItem('med-gas-mobile_all-products', products);
    };
    getAllProducts = () => {
        if (this.initialized) {
            return Promise.resolve(this.allProducts);
        } else {
            return this.initAllProducts();
        }
    };
}
