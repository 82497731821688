import * as React from 'react';
import { Button, Col } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup, Validators } from 'react-reactive-form';
import { TFunction } from 'i18next';
import { addNewPhoto } from '../../actions/photosActions';
import { constants } from '../../constants/constants';
import { initialPhoto, initialPart } from '../../reducers/initialState';
import { FormUtil } from '../common/FormUtil';
import moment from 'moment';

interface Iprops {
    t: TFunction;
    loading: boolean;
    installBaseID: string;
    images: { id: string; url: string }[];
    toggleModal: () => void;
    addNewPhoto: typeof addNewPhoto;
}

interface State {
    fieldConfigs: { [id: string]: FieldConfig };
}

class NewPhotoForm extends React.Component<Iprops, State> {
    private formGroup: { [id: string]: FormGroup | any } = {};
    static defaultProps = {
        selectedPart: initialPart,
        colorButton: constants.colors[`blueButton`]
    };
    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfigs: this.buildFieldConfigs(props.images)
        };
    }

    buildFieldConfigs = (images: { id: string; url: string }[]): { [id: string]: FieldConfig } => {
        const controls: { [id: string]: FieldConfig } = {};
        images.map(image => (controls[image.id] = this.buildFieldConfig(image.url)));
        return controls;
    };

    buildFieldConfig = (url: string) => {
        const disabled = false;
        let fieldConfigControls = {
            // TODO We don't need this form element?
            file: {
                render: FormUtil.FileInput,
                meta: {
                    type: 'file',
                    label: 'alertForm.file',
                    colWidth: 12,
                    accept: 'image/png, image/jpeg',
                    name: 'alert-file',
                    required: false,
                    imageUrl: url,
                    hasPreview: true,
                    style: { display: 'none' }
                }
            },
            comments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'common:comments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 5,
                    name: 'comments',
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(1000)]
                },
                formState: { value: '', disabled }
            },
            isInReport: {
                render: FormUtil.Toggle,
                meta: { label: 'SHOW IN REPORT', colWidth: 12 },
                formState: { value: false, disabled }
            }
        };

        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        Object.keys(this.formGroup).map((key: string) => {
            const form = this.formGroup[key];
            const url = this.props.images.find(i => i.id === key)?.url || '';
            const payload = {
                ...initialPhoto,
                ...form.value,
                createDate: moment.now(),
                id: key,
                thumbnailUrl: url,
                lightboxUrl: url,
                installBaseID: this.props.installBaseID
            };
            this.props.addNewPhoto(payload);
        });
        this.props.toggleModal();
    };

    setForm = (form: FormGroup | FormArray, id: string) => {
        form.meta = {
            loading: this.props.loading
        };
        this.formGroup[id] = form;
    };

    render() {
        const { t, toggleModal } = this.props;
        return (
            <form className="beacon-form" onSubmit={this.handleSubmit}>
                {Object.keys(this.state.fieldConfigs).map(id => (
                    <FormGenerator
                        key={id}
                        onMount={f => this.setForm(f, id)}
                        fieldConfig={this.state.fieldConfigs[id]}
                    />
                ))}
                <Col xs={12} className="form-buttons text-right">
                    <Button bsStyle="default" type="button" className="pull-left" onClick={toggleModal}>
                        {t('cancel')}
                    </Button>
                    <Button bsStyle="primary" type="submit" disabled={this.props.loading}>
                        {t('add')}
                    </Button>
                </Col>
            </form>
        );
    }
}

export default NewPhotoForm;
